// react
import React, { useEffect, useState, useContext } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';
import productApi from '../../server/real/endpoints/products';
import { url } from '../../services/utils';

// blocks
import BlockLoader from '../blocks/BlockLoader';

// data stubs
import ProgramContext from '../../contexts/ProgramContext';

function ShopPageProduct(props) {
    const {
        productSlug, layout,
    } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const { program } = useContext(ProgramContext);

    // Load product.
    useEffect(() => {
        let canceled = false;

        setIsLoading(true);

        productApi.getProductBySlug(productSlug).then((product) => {
            if (canceled) {
                return;
            }

            setProduct(product.data);
            setIsLoading(false);
        });

        return () => {
            canceled = true;
        };
    }, [productSlug, setIsLoading]);

    if (isLoading) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Shop', url: url.catalog() },
        { title: product.productName, url: url.product(product) },
    ];

    const content = (
        <React.Fragment>
            <div className="block">
                <div className="container">
                    <Product product={product} layout={layout} />
                    <ProductTabs product={product} />
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Helmet>
                <script id="bynder-widgets-js" data-account-url="bynder.sbdinc.com" data-language="en" src="https://d8ejoa1fys2rk.cloudfront.net/bynder-embed/latest/bynder-embed.js" />
                <title>{`${product.productName} — ${program.fullName}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
};

export default ShopPageProduct;
