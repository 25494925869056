// react
import React, { useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// application
import ProductTabDescription from './ProductTabDescription';
import ProductTabVideos from './ProductTabVideos';
import ProductTabFeatures from './ProductTabFeatures';

function ProductTabs(props) {
    const { withSidebar, product } = props;
    const [currentTab, setCurrentTab] = useState(product && product.options && product.options.length < 1 ? 'Details' : 'description');

    const prop65Text = 'This Product contains chemicals know to the state of California to cause cancer and birth defects or other reproductive harm(Proposition 65)';

    const setTab = (newTab) => {
        setCurrentTab(newTab);
    };

    const classes = classNames('product-tabs', {
        'product-tabs--layout--sidebar': withSidebar,
    });

        const videoFeed = () => {
            if (product.resources && product.resources.brand && product.resources && product.resources.product) {
                return [...product.resources.brand, ...product.resources.product];
            }
            if (product.resources && product.resources.brand) {
                return [...product.resources.brand];
            }
            if (product.resources && product.resources.product) {
                return [...product.resources.product];
            }
            return null;
        };
        let tabs = [];
        if (videoFeed() && videoFeed().filter((video) => video.productResourceType === 'Video').length > 0) {
            tabs = [
                { key: 'description', title: <FormattedMessage id="tabs_description" defaultMessage="Description" />, content: <ProductTabDescription product={product} /> },
                { key: 'Details', title: <FormattedMessage id="tabs_details" defaultMessage="Details" />, content: <ProductTabFeatures product={product} /> },
                { key: 'Videos', title: <FormattedMessage id="tabs_videos" defaultMessage="videos" />, content: <ProductTabVideos product={product} /> },
            ];
        } else {
            tabs = [
                { key: 'description', title: <FormattedMessage id="tabs_description" defaultMessage="Description" />, content: <ProductTabDescription product={product} /> },
                { key: 'Details', title: <FormattedMessage id="tabs_details" defaultMessage="Details" />, content: <ProductTabFeatures product={product} /> },
            ];
        }

        if (product && product.options && product.options.length < 1) {
            tabs.shift();
        }

        const tabsButtons = tabs.map((tab) => {
            const classes = classNames('product-tabs__item', {
                'product-tabs__item--active': currentTab === tab.key,
            });

            return <button key={tab.key} type="button" onClick={() => setTab(tab.key)} className={classes}>{tab.title}</button>;
        });

        const tabsContent = tabs.map((tab) => {
            const classes = classNames('product-tabs__pane', {
                'product-tabs__pane--active': currentTab === tab.key,
            });

            return <div key={tab.key} className={classes}>{tab.content}</div>;
        });

        return (
            <div className={classes}>
                <div className="product-tabs__list">
                    {tabsButtons}
                </div>
                <div className="product-tabs__content">
                    {tabsContent}
                </div>
                {product && product.prop65 === 'Y' && (
                    <div className="mt-3 mb-3" style={{ textAlign: 'center' }}>
                        <img src="/images/avatars/warning.png" alt="warning" className="mb-1" height={70} />
                        <p style={{ color: '#BA0909' }}>{prop65Text}</p>
                    </div>
                )}
            </div>
        );
    }

ProductTabs.propTypes = {
    withSidebar: PropTypes.bool,
};

ProductTabs.defaultProps = {
    withSidebar: false,
};

export default ProductTabs;
