// react
import React from 'react';
import '../../scss/redirect.css';

function RawHTML(props) {
    const { html } = props;
    return (
        <div className="sun-editor-editable" dangerouslySetInnerHTML={{ __html: html }} />
    );
}

export default RawHTML;
