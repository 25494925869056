// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import departmentsAria from '../../services/departmentsArea';
import StroykaSlick from '../shared/StroykaSlick';

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    slides = [];

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { withDepartments, slides, speed } = this.props;
        const slickSettings = {
            dots: true,
            arrows: true,
            infinite: true,
            speed: 400,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: speed?.slideSpeed !== 0,
            autoplaySpeed: speed?.slideSpeed * 1000,
        };
        const blockClasses = classNames('block-slideshow block', {
            'block-slideshow--layout--full': !withDepartments,
            'block-slideshow--layout--with-departments': withDepartments,
        });

        const layoutClasses = classNames('col-12', {
            'col-lg-12': !withDepartments,
            'col-lg-9': withDepartments,
        });

        const slideComponents = slides.map((slide, index) => {
            const image = withDepartments ? slide.imageClassic : slide.imageFull;

            let actionButton;
            if (slide.action && slide.action.text && slide.action.link) {
                if (slide?.action?.link.includes('http')) {
                    actionButton = (
                        <div className="block-slideshow__slide-button">
                            <Link to={{ pathname: slide.action.link }} className="btn btn-primary btn-lg" target="_blank">
                                {slide.action.text}
                            </Link>
                        </div>
                    );
                } else {
                actionButton = (
                    <div className="block-slideshow__slide-button">
                        <Link to={slide.action.link} className="btn btn-primary btn-lg">
                            {slide.action.text}
                        </Link>
                    </div>
                );   
                }

            }
            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        onClick={() => {
                            if (slide.action.link) {
                                if (slide?.action?.link.includes('http')) {
                                    window.open(slide.action.link)
                                } else {
                                    window.location.href = slide.action.link
                                }
                            }
                        }}
                        style={{
                            backgroundImage: `url(${image})`,
                            cursor: 'pointer'
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.imageMobile})`,
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <div className="block-slideshow__slide-text" dangerouslySetInnerHTML={{ __html: slide.text }} />

                        {actionButton}
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>{slideComponents}</StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
