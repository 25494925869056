// react
import React, { useContext, useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ProgramContext from '../../contexts/ProgramContext';
import orderApi from '../../server/real/endpoints/orders';

function AccountPageOrderDetails(props) {
    const { program } = useContext(ProgramContext);
    const { match } = props;
    const { params } = match;
    const { orderId } = params;
    const [order, setOrder] = useState();

    useEffect(() => {
        let canceled = false;

        orderApi.getOrder(orderId).then((orderData) => {
            if (canceled) {
                return;
            }
            setOrder(orderData.data);
        });

        return () => {
            canceled = true;
        };
    }, []);

    if (!order) return <span />;
    const { shippingAddress } = order;
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${program.fullName}`}</title>
            </Helmet>

            <div className="card">
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/account/orders" className="btn btn-xs btn-secondary"><FormattedMessage id="account_orderback" defaultMessage="Back to list" /></Link>
                    </div>
                    <h5 className="order-header__title">
                        Order #
                        {order.orderNumber}
                    </h5>
                    <div className="order-header__subtitle">
                        <FormattedMessage id="account_orderplacedon" defaultMessage="Was placed on" />
                        {' '}
                        <mark className="order-header__date">
                            {new Date(order.date).toLocaleString('en-US', {
                                weekday: 'short', // long, short, narrow
                                day: 'numeric', // numeric, 2-digit
                                year: 'numeric', // numeric, 2-digit
                                month: 'long', // numeric, 2-digit, long, short, narrow
                            })}
                        </mark>
                        {' '}
                        <FormattedMessage id="account_ordercurrently" defaultMessage="and is currently" />
                        {' '}
                        <mark className="order-header__status">{order.status === 'Shipped' && order.items[0].tracking.trackingLink ? <a href={order.items[0].tracking.trackingLink} target="_blank" rel="noreferrer">{order.status}</a> : <React.Fragment>{order.status === 'API Error' ? 'Pending' : order.status}</React.Fragment>}</mark>
                        .
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="account_orderproduct" defaultMessage="Product" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="account_trackingnumber" defaultMessage="Tracking Number" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="account_shippingcarrier" defaultMessage="Shipping Carrier" />
                                    </th>
                                    {program && program.type === 'points' && (
                                        <th>
                                            <FormattedMessage id="account_ordertotal" defaultMessage="Total" />
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className="card-table__body card-table__body--merge-rows">
                                {order.items.map((item) => (
                                    <tr>
                                        <td>
                                            {item.product && item.product.productName}
                                            {' '}
                                            ×
                                            {' '}
                                            {item.quantity}
                                        </td>
                                        <td>
                                            {item?.tracking?.trackingNumber}
                                        </td>
                                        <td>
                                            {item?.tracking?.trackingCompany}
                                        </td>
                                        {program && program.type === 'points' && (
                                            <td>
                                                {item.total}
                                                {' '}
                                                Points
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                            {program && program.type === 'points' && (
                            <tfoot>
                                <tr>
                                    <th>
                                        <FormattedMessage id="account_ordertotal" defaultMessage="Total" />
                                    </th>
                                    <th>
                                        {' '}
                                    </th>
                                    <th>
                                        {' '}
                                    </th>
                                    {/* <th>
                                        {' '}
                                    </th> */}
                                    <td>
                                        {order.total}
                                        {' '}
                                        Points
                                    </td>
                                </tr>
                            </tfoot>
                            )}
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-12 col-12 px-2">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted"><FormattedMessage id="account_ordershippingaddress" defaultMessage="Shipping Address" /></div>
                            <div className="address-card__name">{shippingAddress.fullName}</div>
                            <div className="address-card__row">
                                {shippingAddress.address1}
                                <br />
                                {shippingAddress.address2}
                                <br />
                                {shippingAddress.address3}
                                <br />
                                {shippingAddress.address4}
                                <br />
                                {shippingAddress.stateCounty}
                                <br />
                                {shippingAddress.postalcode}
                                <br />
                                {shippingAddress.country}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title"><FormattedMessage id="account_addressphone" defaultMessage="Phone Number" /></div>
                                <div className="address-card__row-content">{shippingAddress.phone}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

AccountPageOrderDetails.propTypes = {
    /**
     * Category slug.
     */
    orderId: PropTypes.string,
};

export default AccountPageOrderDetails;
