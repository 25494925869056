// react
import React, {
    useEffect, useReducer, useContext, useState,
} from 'react';

// third-party
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import BlockLoader from '../blocks/BlockLoader';
import CategorySidebar from './CategorySidebar';
import CategorySidebarItem from './CategorySidebarItem';
import PageHeader from '../shared/PageHeader';
import ProductsView from './ProductsView';
import categoryApi from '../../server/real/endpoints/categories';
import productApi from '../../server/real/endpoints/products';
import WidgetFilters from '../widgets/WidgetFilters';
import { sidebarClose } from '../../store/sidebar';
import CategoryFilterBuilder from '../../server/filters/category';
import CheckFilterBuilder from '../../server/filters/check';
import RangeFilterBuilder from '../../server/filters/price';
import ProgramContext from '../../contexts/ProgramContext';
import CategoriesContext from '../../contexts/CategoriesContext';


// data stubs
import { url, getCategoryParents } from '../../services/utils';

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === 'string') {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === 'string') {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === 'string') {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [
        parseQueryOptions(location),
        parseQueryFilters(location),
    ];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== 'default') {
        params.sort = options.sort;
    }

    if (options.search) {
        params.search = options.search;
    }

    Object.keys(filters).filter((x) => x !== 'category' && !!filters[x]).forEach((filterSlug) => {
        params[`filter_${filterSlug}`] = filters[filterSlug];
    });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};
function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_CATEGORY_SUCCESS':
            return {
                ...state,
                init: true,
                categoryIsLoading: false,
                category: action.category,
            };
        case 'FETCH_PRODUCTS_LIST':
            return { ...state, productsListIsLoading: true };
        case 'FETCH_PRODUCTS_LIST_SUCCESS':
            return { ...state, productsListIsLoading: false, productsList: action.productsList };
        case 'SET_OPTION_VALUE':
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case 'SET_FILTER_VALUE':
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case 'RESET_FILTERS':
            return { ...state, options: { ...state.options, page: 1 }, filters: {} };
        case 'RESET':
            return state.init ? initialState : state;
        default:
            throw new Error();
    }
}
function init(state) {
    const [options, filters] = parseQuery(window.location.search);
    return { ...state, options, filters };
}

function ShopPageCategory(props) {
    const { getCateogryLanguage } = useContext(CategoriesContext)
    const {
        categorySlug,
        columns,
        viewMode,
        sidebarPosition,
    } = props;
    const pageQuery = new URLSearchParams(window.location.search).get('filter_page');
    const offcanvas = columns === 3 ? 'mobile' : 'always';
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { region } = props;
    const [limit, setLimit] = useState();
    const [once, setOnce] = useState();
    const [page, setPage] = useState(pageQuery || 1);
    const [sort, setSort] = useState('default');
    const lang = JSON.parse(localStorage.getItem('state'))?.locale
    

    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? '?' : ''}${query}`;

        window.history.replaceState(null, '', location);
    }, [state.options, state.filters]);

    const resetFilters = () => {
        dispatch({ type: 'RESET_FILTERS' });
    };

    function insertUrlParam(key, value) {
        const url = new URL(window.location);
        url.searchParams.set(key, value);
        window.history.pushState(null, '', url.toString());
    }

    useEffect(() => {
        insertUrlParam('filter_page', page);
    }, [page, pageQuery]);

    // Load category.
    useEffect(() => {
        let request;
        let canceled = false;

        dispatch({ type: 'RESET', categorySlug });

        if (categorySlug) {
            request = categoryApi.getCategoryBySlug(categorySlug);
            setPage(1);
        } else {
            request = Promise.resolve(null);
        }

        request.then((category) => {
            if (canceled) {
                return;
            }
            dispatch({ type: 'FETCH_CATEGORY_SUCCESS', category });
        });

        return () => {
            canceled = true;
        };
    }, [dispatch, categorySlug]);
    // Load products.
    const { program } = useContext(ProgramContext);

    useEffect(() => {
        if (!once) {
            if (program && program.permissions.productCounts) {
                setLimit(program.permissions.productCounts[0] || '100');
                setOnce(true);
            }
        }
    }, [program, page]);

    useEffect(() => {
        let canceled = false;
        if (!program || !limit) {
            return () => {
                canceled = true;
            };
        }

        let filters = [];
        if (program) {
            switch (program.type) {
                default:
                    break;
                case 'points':
                    filters = [
                        new CheckFilterBuilder('badges', 'Badges'),
                        new CategoryFilterBuilder('category', 'Categories'),
                        new RangeFilterBuilder('price', 'Price'),
                        new CheckFilterBuilder('brand', 'Brand'),
                    ];
                    break;
                case 'plateau':
                    filters = [
                        new CheckFilterBuilder('badges', 'Badges'),
                        new CategoryFilterBuilder('category', 'Categories'),
                        new CheckFilterBuilder('brand', 'Brand'),
                    ];
                    break;
                case 'claim':
                    filters = [
                        new CheckFilterBuilder('badges', 'Badges'),
                        new CategoryFilterBuilder('category', 'Categories'),
                        new CheckFilterBuilder('brand', 'Brand'),
                    ];
                    break;
            }
        }

        dispatch({ type: 'FETCH_PRODUCTS_LIST' });
        productApi.getProductsList(
            {
                ...state.options, region, limit, page, sort,
            },
            { ...state.filters, category: categorySlug }, filters,
        ).then((productsList) => {
            if (canceled) {
                return;
            }

            dispatch({ type: 'FETCH_PRODUCTS_LIST_SUCCESS', productsList: productsList.data });
        });

        return () => {
            canceled = true;
        };
    }, [categorySlug, state.options, state.filters, program, region, limit, page, sort, window.location]);
    // Load latest products.

    if (state.categoryIsLoading || (state.productsListIsLoading && !state.productsList)) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Shop', url: url.catalog() },
    ];
    let pageTitle = getCateogryLanguage(lang, 'Shop');
    let content;

    if (state.category) {
        getCategoryParents(state.category).forEach((parent) => {
            breadcrumb.push({ title: parent.name, url: url.category(parent) });
        });

        breadcrumb.push({ title: state.category.name, url: url.category(state.category) });

        pageTitle = state.category.name;
    }

    const productsView = (
        <ProductsView
            isLoading={state.productsListIsLoading}
            productsList={state.productsList}
            options={state.options}
            onOptionChanged={(key, option) => {
                // eslint-disable-next-line default-case
                switch (key) {
                    case 'sort':
                        setSort(option);
                        break;
                    case 'limit':
                        setLimit(option);
                        break;
                    case 'page':
                        setPage(option);
                        break;
                }
            }}
            filters={state.filters}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? 'full' : 'sidebar'}`}
            offcanvas={offcanvas}
            resetFilters={resetFilters}
        />
    );

    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                <WidgetFilters
                    title="Filters"
                    offcanvas={offcanvas}
                    filters={state.productsList.filters}
                    // filters={filtersData}
                    values={state.filters}
                    dispatch={dispatch}
                />
            </CategorySidebarItem>

        </CategorySidebar>
    );

    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">{productsView}</div>
                {program && (program.permissions.showBrands || program.permissions.showCategories || program.permissions.showPriceSlider || program.permissions.showTags) && (sidebarComponent)}
            </div>
        );
    } else {
        const sidebar = (
            <div className="shop-layout__sidebar">
                {program && (program.permissions.showBrands || program.permissions.showCategories || program.permissions.showPriceSlider || program.permissions.showTags) && (sidebarComponent)}
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsView}</div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                {program && (<title>{`Shop Category Page — ${program.fullName}`}</title>)}
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'long', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: 'list',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
    region: state.region,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
