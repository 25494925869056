// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function Points(props) {
    const { value } = props;

    return (
        <React.Fragment>
            {`${value}`}
            {' '}
            Points
        </React.Fragment>
);
}

Points.propTypes = {
    /** price value */
    value: PropTypes.number.isRequired,
};

export default connect()(Points);
