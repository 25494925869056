// react
import React from 'react';

// data stubs

function ProductTabSpecification(props) {
    const { product } = props;
    const features = product.features && product.features.map((feature, key) => (
        <div key={key} className="spec__row">
            <div className="spec__value">
                {feature.featureName}
            </div>
        </div>
));

    return (
        <div className="spec">
            <h3 className="spec__header">Specification</h3>
            {product && product.features && (
            <div className="spec__section">
                <h4 className="spec__section-title">Features</h4>
                {features}
            </div>
)}
            <div className="spec__section">
                <h4 className="spec__section-title">Dimensions</h4>
                {/* {product.dims.cartonHeight !== 0 && (
                <div className="spec__row">
                    <div className="spec__name">Carton Height</div>
                    <div className="spec__value">
                        {product.dims.cartonHeight.toFixed(2)}
                        {' '}
                        <i style={{ color: 'grey' }}>inches</i>
                    </div>
                </div>
)}
                {product.dims.cartonLength !== 0 && (
                <div className="spec__row">
                    <div className="spec__name">Carton Length</div>
                    <div className="spec__value">
                        {product.dims.cartonLength.toFixed(2)}
                        {' '}
                        <i style={{ color: 'grey' }}>inches</i>
                    </div>
                </div>
)}
                {product.dims.cartonWeight !== 0 && (
                <div className="spec__row">
                    <div className="spec__name">Carton Weight</div>
                    <div className="spec__value">
                        {product.dims.cartonWeight.toFixed(2)}
                        {' '}
                        <i style={{ color: 'grey' }}>lbs</i>
                    </div>
                </div>
)}
                {product.dims.cartonWidth !== 0 && (
                <div className="spec__row">
                    <div className="spec__name">Carton Width</div>
                    <div className="spec__value">
                        {product.dims.cartonWidth.toFixed(2)}
                        {' '}
                        <i style={{ color: 'grey' }}>inches</i>
                    </div>
                </div>
)} */}
                {product?.dims?.itemHeight !== 0 && (
                <div className="spec__row">
                    <div className="spec__name">Item Height</div>
                    <div className="spec__value">
                        {product?.dims?.itemHeight?.toFixed(2)}
                        {' '}
                        <i style={{ color: 'grey' }}>inches</i>
                    </div>
                </div>
)}
                {product?.dims?.itemWeight !== 0 && (
                <div className="spec__row">
                    <div className="spec__name">Item Weight</div>
                    <div className="spec__value">
                        {product?.dims?.itemWeight?.toFixed(2)}
                        {' '}
                        <i style={{ color: 'grey' }}>lbs</i>
                    </div>
                </div>
)}
                {product?.dims?.itemWidth !== 0 && (
                <div className="spec__row">
                    <div className="spec__name">Item Width</div>
                    <div className="spec__value">
                        {product?.dims?.itemWidth?.toFixed(2)}
                        {' '}
                        <i style={{ color: 'grey' }}>inches</i>
                    </div>
                </div>
)}
            </div>
        </div>
    );
}

export default ProductTabSpecification;
