// react
import React, { useContext, useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// application
import { Check9x7Svg } from '../../svg';

// data stubs
import ErrModal from './AccountPassModal';

// context
import ProgramContext from '../../contexts/ProgramContext';
import auth from '../../server/real/endpoints/auth';
import PageHeader from '../shared/PageHeader';

export default function AccountPageLogin() {
    const { program } = useContext(ProgramContext);
    const [email, setEmail] = useState('');
    const [failCounter, setFailCounter] = useState(0);
    const [password, setPassword] = useState('');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    const failFunc = (fail) => {
        setFailCounter(fail + 1);
    };

    useEffect(() => {
        if (token && token.length > 0) {
            auth.authenticateSSO(token, (result) => {
                if (result.success) window.location = '/';
                else failFunc(failCounter);
            });
        }
    }, [token]);

    const enterLoginClaim = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            auth.authenticate(email, email, (result) => {
                if (result.success) window.location = '/';
                else failFunc(failCounter);
            });
        }
    };

    const enterLoginPassword = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            auth.authenticate(email, password, (result) => {
                if (result.success) {
                    window.location = '/';
                } else { failFunc(failCounter); }
            });
        }
    };

    const claimComponent = (
        <div className="card-body">
            <div style={{ textAlign: 'center' }}>
            <img style={{ maxHeight: '90px', maxWidth: '150px'}} src={program?.theme?.loginLogo}></img>
            </div>
            <h3 className="card-title"><FormattedMessage id="account_loginclaim" defaultMessage="Claim" /></h3>
            <form>
                <div className="form-group">
                    <label htmlFor="claim-code">
                        <FormattedMessage id="account_loginclaimcode" defaultMessage="Enter Claim Code" />
                    </label>
                    <FormattedMessage id="account_loginclaimcodeplaceholder" defaultMessage="Claim Code">
                        {(placeholder) => (
                            <input
                                onKeyDown={(e) => enterLoginClaim(e)}
                                id="register-email"
                                type="text"
                                className="form-control"
                                placeholder={placeholder}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                value={email}
                            />
                        )}
                    </FormattedMessage>
                    <p style={{ marginTop: '15px' }}><FormattedMessage id="account_disclaimerMessage" defaultMessage=" " /></p>
                    {failCounter > 0 && <span style={{ color: 'red' }}>Please check Code and try again</span>}
                </div>
                <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginTop: '186px' }}
                    onClick={() => {
                        auth.authenticate(email, email, (result) => {
                            if (result.success) window.location = '/';
                            else failFunc(failCounter);
                        });
                    }}
                >
                    <FormattedMessage id="account_loginbuttonclaim" defaultMessage="Claim" />
                </button>
            </form>
        </div>
    );
    const loginComponent = (
        <div className="card-body">
            <div style={{ textAlign: 'center' }}>
            <img style={{ maxHeight: '90px', maxWidth: '150px'}} src={program?.theme?.loginLogo}></img>
            </div>
            <h3 className="card-title"><FormattedMessage id="account_loginlabel" defaultMessage="Login" /></h3>
            <form>
                <div className="form-group">
                    <label htmlFor="login-id">
                        <FormattedMessage id="account_loginuserid" defaultMessage="User ID" />
                    </label>
                    <FormattedMessage id="account_loginuseridplaceholder" defaultMessage="Please Enter User ID">
                        {(placeholder) => (
                            <input
                                onKeyDown={(e) => enterLoginPassword(e)}
                                id="login-id"
                                className="form-control"
                                placeholder={placeholder}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                value={email}
                            />
                        )}
                    </FormattedMessage>
                </div>
                <div className="form-group">
                    <label htmlFor="login-password">
                        <FormattedMessage id="account_loginpassword" defaultMessage="Password" />
                    </label>
                    <FormattedMessage id="account_loginpassword" defaultMessage="Password">
                        {(placeholder) => (
                            <input
                                onKeyDown={(e) => enterLoginPassword(e)}
                                id="login-password"
                                type="password"
                                className="form-control"
                                placeholder={placeholder}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                value={password}
                            />
                        )}
                    </FormattedMessage>
                    <small className="form-text text-muted">
                        <ErrModal />
                    </small>
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <span className="form-check-input input-check">
                            <span className="input-check__body">
                                <input
                                    id="login-remember"
                                    type="checkbox"
                                    className="input-check__input"
                                />
                                <span className="input-check__box" />
                                <Check9x7Svg className="input-check__icon" />
                            </span>
                        </span>
                        <label className="form-check-label" htmlFor="login-remember">
                            <FormattedMessage id="account_loginremember" defaultMessage="Remember Me" />
                        </label>
                    </div>
                    <p style={{ marginTop: '15px' }}><FormattedMessage id="account_disclaimerMessage" defaultMessage=" " /></p>
                    {failCounter > 0 && <span style={{ color: 'red' }}>Please check your ID and/or password and try again</span>}
                </div>
                <button
                    type="button"
                    onClick={() => {
                        auth.authenticate(email, password, (result) => {
                            if (result.success) {
                                window.location = '/';
                            } else { failFunc(failCounter); }
                        });
                    }}
                    className="btn btn-primary mt-2 mt-md-3 mt-lg-4"
                >
                    <FormattedMessage id="account_loginlabel" defaultMessage="Login" />
                </button>
            </form>
        </div>
    );
    let authComponent;
    if (program) {
        if (program.type === 'points') {
            authComponent = (
                <div className="container d-flex justify-content-center">
                    <div className="col-md-6 d-flex">
                        <div className="card flex-grow-1 mb-md-0">
                            {loginComponent}
                        </div>
                    </div>
                </div>
            );
        } else if (program.type === 'plateau') {
            authComponent = (
                <div className="container d-flex justify-content-center">
                    <div className="col-md-6 d-flex">
                        <div className="card flex-grow-1 mb-md-0">
                            {loginComponent}
                        </div>
                    </div>
                </div>
            );
        } else if (program.type === 'claim') {
            authComponent = (
                <div className="container d-flex justify-content-center">
                    <div className="col-md-6 d-flex mt-4 mt-md-0">
                        <div className="card flex-grow-1 mb-0">
                            {claimComponent}
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${program.fullName}`}</title>
            </Helmet>
            <PageHeader />
            <div style={{ marginTop: '10px' }}>
                <div className="block">
                    {authComponent}
                </div>
            </div>
        </React.Fragment>
    );
}
