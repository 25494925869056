// react
import React, { useContext } from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageNewAddress from './AccountPageNewAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';
import ProgramContext from '../../contexts/ProgramContext';
import AccountPageTransactions from './AccountPageTransactions';
import AccountTimeout from './AccountTimeout';

export default function AccountLayout(props) {
    const { match, location } = props;
    const { program } = useContext(ProgramContext);
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    let defaultRedirect;
    let urls = [];
    let components = [];
    if (program.type === 'points') {
        urls = [
            { title: <FormattedMessage id="account_myProfile" defaultMessage="My Profile" />, url: 'myprofile' },
            { title: <FormattedMessage id="account_myOrders" defaultMessage="My Orders" />, url: 'orders' },
            { title: <FormattedMessage id="account_myPoints" defaultMessage="My Points" />, url: 'transactions' },
            { title: <FormattedMessage id="account_myAddresses" defaultMessage="My Addresses" />, url: 'addresses' },
            { title: <FormattedMessage id="account_logout" defaultMessage="Logout" />, url: 'logout' },
        ];

        components = [
            <Route exact key={`${match.path}/myprofile`} path={`${match.path}/myprofile`} component={AccountPageDashboard} />,
            <Route exact key={`${match.path}/profile`} path={`${match.path}/profile`} component={AccountPageProfile} />,
            <Route exact key={`${match.path}/password`} path={`${match.path}/password`} component={AccountPagePassword} />,
        ];

        defaultRedirect = 'myprofile';
    } else if (program.type === 'claim') {
        urls = [
            { title: <FormattedMessage id="account_myOrders" defaultMessage="My Orders" />, url: 'orders' },
            { title: <FormattedMessage id="account_myAddresses" defaultMessage="My Addresses" />, url: 'addresses' },
            { title: <FormattedMessage id="account_logout" defaultMessage="Logout" />, url: 'logout' },
        ];

        defaultRedirect = 'adresses';
    } else if (program.type === 'plateau') {
        urls = [
            { title: <FormattedMessage id="account_myProfile" defaultMessage="My Profile" />, url: 'myprofile' },
            { title: <FormattedMessage id="account_myOrders" defaultMessage="My Orders" />, url: 'orders' },
            { title: <FormattedMessage id="account_myAddresses" defaultMessage="My Addresses" />, url: 'addresses' },
            { title: <FormattedMessage id="account_logout" defaultMessage="Logout" />, url: 'logout' },
        ];

        components = [
            <Route exact key={`${match.path}/myprofile`} path={`${match.path}/myprofile`} component={AccountPageDashboard} />,
            <Route exact key={`${match.path}/profile`} path={`${match.path}/profile`} component={AccountPageProfile} />,
            <Route exact key={`${match.path}/password`} path={`${match.path}/password`} component={AccountPagePassword} />,
        ];

        defaultRedirect = 'adresses';
    }
    const links = urls.map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });
        return (
            <li key={link.url} className={classes}>
                <Link to={url}>{link.title}</Link>
            </li>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header={<FormattedMessage id="account_myAccount" defaultMessage="My Account" />} breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title"><FormattedMessage id="account_navigation" defaultMessage="Navigation" /></h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/${defaultRedirect}`} />
                                {components}
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/transactions`} component={AccountPageTransactions} />
                                <Route
                                    exact
                                    path={`${match.path}/orders/:orderId`}
                                    render={(props) => {
                                        const { match } = props;
                                        const { params } = match;
                                        const { orderId } = params;
                                            <AccountPageOrderDetails
                                                {...props}
                                                orderId={orderId}
                                            />;
                                    }}
                                    component={AccountPageOrderDetails}
                                />
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                <Route exact path={`${match.path}/addresses/:addressId`} component={AccountPageEditAddress} />
                                <Route exact path={`${match.path}/newaddress`} component={AccountPageNewAddress} />
                                <Route exact path={`${match.path}/timeout`} component={AccountTimeout} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
