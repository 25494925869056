import React, { useState, useContext } from 'react';
import {
     Button, Modal, ModalHeader, ModalBody, ModalFooter,
    } from 'reactstrap';
import userApi from '../../server/real/endpoints/users';
import ProgramContext from '../../contexts/ProgramContext';

const ErrModal = (props) => {
  const {
    className,
  } = props;
  const { program } = useContext(ProgramContext);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState();
  const toggle = () => setModal(!modal);

  const centeredmodal = true;

  function onSubmit() {
    userApi.forgotPassword(email);
}
    return (
        <div>
            {!program?.ableToPasswordReset && (
                <button type="button" className="link-button" style={{ marginTop: '10px' }} onClick={toggle}>Forgot Password</button>
            )}
            <Modal isOpen={modal} toggle={toggle} className={className} centered={centeredmodal}>
                <ModalHeader toggle={toggle}>Reset Password</ModalHeader>
                <div>
                    <form onSubmit={onSubmit}>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="login-email">Email Address</label>
                                <input
                                    id="login-email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Please Enter Your Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <small className="form-text text-muted" />
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary">Reset Password</Button>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default ErrModal;
