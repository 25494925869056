// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { mobileMenuOpen } from '../../store/mobile-menu';
import ProgramContext from '../../contexts/ProgramContext';

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    render() {
        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <ProgramContext.Consumer>
                                {(context) => {
                                    let logo;
                                    const { program } = context;
                                    const { theme } = program;
                                    if (theme !== undefined) {
                                        logo = (
                                            <Link to="/" className="mobile-header__logo">
                                                <img width="auto" height="50px" alt="logo" src={theme.mobileLogo} />
                                            </Link>
                                        );
                                    }
                                    return logo;
                                }}
                            </ProgramContext.Consumer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    locale: state.locale,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileHeader);
