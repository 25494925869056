import AbstractFilterBuilder from './abstract';

export default class CheckFilterBuilder extends AbstractFilterBuilder {
    items = [];

    value = [];

    test(product) {
        if (this.value.length === 0) {
            return true;
        }

        return this.value.reduce((result, value) => (
            result || this.extractItems(product).map((x) => x.slug).includes(value)
        ), false);
    }

    makeItems(value) {
        this.items = [];
        this.value = this.parseValue(value);
    }

    calc() {
        this.items = [];
    }

    build() {
        return {
            type: 'check',
            slug: this.slug,
            name: this.name,
            items: this.items,
            value: this.value,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    parseValue(value) {
        return value ? value.split(',') : [];
    }

    extractItems(product) {
        if (this.slug === 'brand') {
            return product.brandName ? [{
                slug: product.brandName,
                name: product.brandName,
                count: 0,
            }] : null;
        }
        if (this.slug === 'badges') {
            const { badges } = product;
            const list = [];
            for (let i = 0; i < badges.length; i += 1) {
                list.push({
                    slug: badges[i],
                    name: badges[i],
                    count: 0,
                });
            }
            return list;
        }

        throw Error();
    }
}
