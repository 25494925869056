// react
import React, { useState, useContext, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import UserContext from '../../contexts/UserContext';
import userApi from '../../server/real/endpoints/users';

export default function AccountPageProfile() {
    const { user } = useContext(UserContext);
    const [newUser, setNewUser] = useState();
    function validationEmail() {
        const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g;
        const result = pattern.test(newUser && newUser.email);
        if (result) {
          return 'form-control';
        }
          return 'form-control is-invalid';
    }
    function setDisableButton() {
        if (validationEmail() === 'form-control') {
            return false;
        }
        return true;
    }
    const updateUser = () => {
        userApi.updateUser(user.sid, newUser).then(() => {
            window.location = 'account/myprofile';
        });
    };
    useEffect(() => {
        setNewUser(user);
        validationEmail(newUser && newUser.email);
        setDisableButton();
    }, [user]);

    function keyChanged(key, e) {
        const newUser = { ...user };
        newUser[key] = e.target.value;
        setNewUser(newUser);
    }

    return (
        <div className="card">
            <Helmet>
                <title>Profile Edit</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Profile</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Full Name</label>
                            <input
                                id="profile-first-name"
                                type="text"
                                onChange={(e) => keyChanged('name', e)}
                                className="form-control"
                                placeholder="Please Type Your Name"
                                value={newUser && newUser.name}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-email">Email Address</label>
                            <input
                                id="profile-email"
                                type="email"
                                className={validationEmail()}
                                placeholder="Email Address"
                                onChange={(e) => keyChanged('email', e)}
                                value={newUser && newUser.email}
                            />
                            <div className="invalid-feedback">Please Enter valid email address</div>
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="submit" disabled={setDisableButton()} className="btn btn-primary" onClick={updateUser}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
