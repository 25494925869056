import { REGION_CHANGE } from './regionActionTypes';

const initialState = {
    location: 'USA',
    country: 'USA-CONT',
};

export default function regionReducer(state = initialState, action) {
    if (action.type === REGION_CHANGE) {
        return action.region;
    }

    return state;
}
