import AbstractFilterBuilder from './abstract';

export default class CategoryFilterBuilder extends AbstractFilterBuilder {
    value = null;

    items = [];

    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    test(product) {
        if (this.value === null) {
            return true;
        }

        return product.categories.reduce((acc, category) => (
            acc || category === this.value
        ), false);
    }

    makeItems(value) {
        this.value = value || null;

        if (this.value) {
            this.items = [];
        } else {
            this.items = [];
        }
    }

    // eslint-disable-next-line class-methods-use-this
    calc() { }

    build() {
        return {
            type: 'category',
            slug: this.slug,
            name: this.name,
            items: this.items,
            value: this.value,
        };
    }
}
