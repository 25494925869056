// react
import React from 'react';
import auth from '../../server/real/endpoints/auth';

export default function AccountPageLogout() {
    auth.signout(() => {
        window.location = '/';
    });
    return (
        <React.Fragment>        </React.Fragment>
    );
}
