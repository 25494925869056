// react
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ProgramContext from '../../contexts/ProgramContext';

// data stubs
function FooterContacts() {
    const { program } = useContext(ProgramContext);
    return (
        <div className="site-footer__widget footer-contacts row align-items-center">
            <h5 className="col-12 col-md-3 col-lg-3 align-middle"><FormattedMessage id="footer_lefttext" defaultMessage=" " /></h5>

            <div className="footer-contacts__text col-12 col-md-6 col-lg-6 align-middle" 
            style={{ textAlign: `${program?.footerTextAlign === 'Left' ? 'left' : program?.footerTextAlign === 'Right' ? 'right' : 'center' }`, fontSize: `${program?.footerTextSize === 'Small' ? '18px' : program?.footerTextSize === 'Large' ? '22px' : '20px' }`}}>
                <FormattedMessage id="footer_text" defaultMessage="We reserve the right to substitute similar items with same or higher value if item shown is not available." />
            </div>

            {program && program.contacts && (
                <div className="col-12 col-md-3 col-lg-3">
                <h5 style={{ textAlign: 'center' }} className="align-middle"><FormattedMessage id="footer_contact" defaultMessage="Contact Us" /></h5>
                <ul className="footer-contacts__contacts">
                    {program.contacts.address && (
                    <li>
                        <i className="footer-contacts__icon fas fa-globe-americas" />
                        {program.contacts.address}
                    </li>
)}
                    {program.contacts.email && (
                    <li>
                        <i className="footer-contacts__icon far fa-envelope" />
                        {program.contacts.email}
                    </li>
)}
                    {program.contacts.phone && (
                    <li>
                        <i className="footer-contacts__icon fas fa-mobile-alt" />
                        {`${program.contacts.phone}`}
                    </li>
)}
                </ul>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterContacts);
