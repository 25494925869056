// react
import React, { useContext, useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data stubs
import orderApi from '../../server/real/endpoints/orders';

// context
import UserContext from '../../contexts/UserContext';
import ProgramContext from '../../contexts/ProgramContext';

export default function AccountPageDashboard() {
    const { user } = useContext(UserContext);
    const { program } = useContext(ProgramContext);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        let canceled = false;

        orderApi.getOrders().then((data) => {
            if (canceled) {
                return;
            }
            setOrders(data.data);
        });

        return () => {
            canceled = true;
        };
    }, []);

    let address;
    let fullname;
    let useremail;
    if (user) {
        if (user.addresses) {
            [address] = user.addresses;
        }
        fullname = user.name;
        useremail = user.email;
    }

    const orderLines = orders.map((order) => (
        <tr key={order.id}>
            <td>
                <Link to={`/account/orders/${order.id}`}>
                    #
                    {order.orderNumber}
                </Link>
            </td>
            <td>
                {new Date(order.date).toLocaleString('en-US', {
                    weekday: 'short', // long, short, narrow
                    day: 'numeric', // numeric, 2-digit
                    year: 'numeric', // numeric, 2-digit
                    month: 'long', // numeric, 2-digit, long, short, narrow
                })}
            </td>
            <td>{order.status === 'Shipped' && order.items[0].tracking.trackingLink ? <a href={order.items[0].tracking.trackingLink} target="_blank" rel="noreferrer">{order.status}</a> : <React.Fragment>{order.status === 'API Error' ? 'Pending' : order.status}</React.Fragment>}</td>
            {program && program.type === 'points' && (<td>{order.total}</td>)}
        </tr>
    ));
    let addressComponent;
    if (address) {
        addressComponent = (
            <div className="dashboard__address card address-card address-card--featured">
                {address.default && <div className="address-card__badge"><FormattedMessage id="account_dashdefault" defaultMessage="Default Address" /></div>}
                <div className="address-card__body">
                    <div className="address-card__name">{`${address.address1 === undefined ? 'Default' : address.address1}`}</div>
                    <div className="address-card__row">
                        {address.address1}
                        <br />
                        {address.postcode}
                        <br />
                        {address.city}
                        <br />
                        {address.stateCounty}
                        <br />
                        {address.country}
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title"><FormattedMessage id="account_addressphone" defaultMessage="Phone Number" /></div>
                        <div className="address-card__row-content">{address.phone}</div>
                    </div>
                    <div className="address-card__footer">
                        <Link to={`/account/addresses/${address.id}`}><FormattedMessage id="account_dasheditaddress" defaultMessage="Edit Address" /></Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`My Account — ${program.fullName}`}</title>
            </Helmet>

            <div className="dashboard__profile card profile-card">
                <div className="card-body profile-card__body">
                    {/* <div className="profile-card__avatar">
                        <img src="images/avatars/avatar-3.jpg" alt="" />
                    </div> */}
                    <div className="profile-card__name">{fullname}</div>
                    <div className="profile-card__email">{useremail}</div>
                    <div className="profile-card__edit d-flex justify-content-between" style={{ width: !program?.ableToPasswordReset ? '90%' : 'auto' }}>
                        <Link to="profile" className="btn btn-secondary btn-sm"><FormattedMessage id="account_dasheditprofile" defaultMessage="Edit Profile" /></Link>
                        {!program?.ableToPasswordReset && (
                            <Link to="password" className="btn btn-secondary btn-sm"><FormattedMessage id="account_dasheditchangepass" defaultMessage="Change Password" /></Link>
                        )}
                    </div>
                </div>
            </div>

            {addressComponent}

            <div className="dashboard__orders card">
                <div className="card-header">
                    <h5><FormattedMessage id="account_dashrecentorders" defaultMessage="Recent Orders" /></h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="account_dashorder" defaultMessage="Order" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="account_dashdate" defaultMessage="Date" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="account_dashstatus" defaultMessage="Status" />
                                    </th>
                                    {program && program.type === 'points' && (
                                    <th>
                                        <FormattedMessage id="account_dashtotal" defaultMessage="Total" />
                                    </th>
)}
                                </tr>
                            </thead>
                            <tbody>
                                {orderLines}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
