// react
import React, { useContext } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data stubs
import UserContext from '../../contexts/UserContext';
import ProgramContext from '../../contexts/ProgramContext';
import userApi from '../../server/real/endpoints/users';

export default function AccountPageAddresses() {
    const { user } = useContext(UserContext);
    const { program } = useContext(ProgramContext);

    const deleteAddress = (e, addressid) => {
        userApi.deleteAddress(user.sid, addressid).then(() => {
            window.location.reload();
        });
    };
    let addresses;
    if (user) {
        addresses = user.addresses.map((address) => (
            <React.Fragment key={address.id}>
                {address && address.country && (
                <div className="addresses-list__item card address-card">
                    {address && address.default && <div className="address-card__badge"><FormattedMessage id="account_addressdefault" defaultMessage="Default" /></div>}
                    <div className="address-card__body">
                        <div className="address-card__name">{address.address1 === undefined ? 'Default' : address.address1}</div>
                        <div className="address-card__row">
                            {address.address1}
                            <br />
                            {address.address2}
                            <br />
                            {address.address3}
                            <br />
                            {address.address4}
                            <br />
                            {address.postcode}
                            <br />
                            {address.stateCounty}
                            <br />
                            {address.country}
                        </div>
                        <div className="address-card__row">
                            <div className="address-card__row-title"><FormattedMessage id="account_addressphone" defaultMessage="Phone Number" /></div>
                            <div className="address-card__row-content">{address.phone}</div>
                        </div>
                        <div className="address-card__footer">
                            <Link to={`/account/addresses/${address.id}`}><FormattedMessage id="account_addressedit" defaultMessage="Edit" /></Link>
                            &nbsp;&nbsp;
                            <button type="button" style={{ color: 'var(--link-color)', border: '0px', background: 'transparent' }} onClick={(e) => deleteAddress(e, address.id)}>
                                <FormattedMessage id="account_addressremove" defaultMessage="Remove" />
                            </button>
                        </div>
                    </div>
                </div>
)}
                <div className="addresses-list__divider" />
            </React.Fragment>
        ));
    }

    return (
        <div className="addresses-list">
            <Helmet>
                <title>{`Address List — ${program.fullName}`}</title>
            </Helmet>

            <Link to="/account/newaddress" className="addresses-list__item addresses-list__item--new">
                <div className="addresses-list__plus" />
                <div className="btn btn-secondary btn-sm"><FormattedMessage id="account_addressaddnew" defaultMessage="Add New" /></div>
            </Link>
            <div className="addresses-list__divider" />
            {addresses}
        </div>
    );
}
