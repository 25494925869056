/* eslint-disable no-nested-ternary */
// react
import React, { useState } from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import { Check9x7Svg } from '../../svg';

function FilterCheck(props) {
    const [showCount, setShowCount] = useState(15);
    const [showText, setShowText] = useState(<FormattedMessage id="sidebar_allbrands" defaultMessage="All Brands" />);

    const HandleShow = () => {
        setShowCount(showCount === 15 ? 1000 : 15);
        setShowText(showText === 'All Brands' ? <FormattedMessage id="sidebar_showless" defaultMessage="Show Less" /> : <FormattedMessage id="sidebar_allbrands" defaultMessage="All Brands" />);
    };

    const { data, value, onChangeValue } = props;

    const updateValue = (newValue) => {
        onChangeValue({ filter: data, value: newValue });
    };

    const handleChange = (event) => {
        if (event.target.checked && !value.includes(event.target.value)) {
            updateValue([...value, event.target.value]);
        }
        if (!event.target.checked && value.includes(event.target.value)) {
            updateValue(value.filter((x) => x !== event.target.value));
        }
    };

    const itemsList = data.items.sort((b, a) => a.count - b.count).slice(0, showCount).sort((a, b) => {
        const textA = a.name;
        const textB = b.name;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}).map((item) => {
        let count;
        const name = <span className="filter-list__title">{item.name}</span>;

        const itemClasses = classNames('filter-list__item', {
            'filter-list__item--disabled': item.count === 0,
        });

        return (
            <label key={item.slug} className={itemClasses}>
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input
                            className="input-check__input"
                            type="checkbox"
                            value={item.slug}
                            checked={value.includes(item.slug)}
                            disabled={item.count === 0}
                            onChange={handleChange}
                        />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                {name}
                {count}
            </label>
        );
    });

    return (
        <div className="filter-list">
            <div className="filter-list__list">
                {itemsList}
            </div>
            {itemsList && itemsList.length > 14 && (
                <div className="d-flex justify-content-center">
                    <button className="btn btn-secondary btn-sm mt-3" type="button" onClick={HandleShow}>{showText}</button>
                </div>
            )}
        </div>
    );
}

FilterCheck.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
    /**
     * Value.
     */
    value: PropTypes.arrayOf(PropTypes.string),
    /**
     * Change value callback.
     */
    onChangeValue: PropTypes.func,
};

export default FilterCheck;
