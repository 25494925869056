// react
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, withRouter } from 'react-router-dom';

// application
import Points from '../shared/Points';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import UserContext from '../../contexts/UserContext';
import ProgramContext from '../../contexts/ProgramContext';
import userApi from '../../server/real/endpoints/users';
import { getProductPrice, selectedLanguage } from '../../server/utils';
// import orderApi from '../../server/real/endpoints/orders';
import ShopPageCheckoutAddress from './ShopPageCheckoutAddress';

class ShopPageCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aggrementCheckBox: false,
            isSubmitted: false,
            isValid: false,
        };
    }

    componentDidUpdate() {

    }

    checkBox = (e) => {
        this.setState({ aggrementCheckBox: e.target.checked });
    };

    grandTotal = () => {
        const { cart, region } = this.props;
        let grandTotal = 0;
        cart.items.map((item) => {
            const unitPrice = getProductPrice(item.product, item.option, region);
            const totalPrice = unitPrice * item.quantity;
            grandTotal += totalPrice;
            return grandTotal;
        });
        return grandTotal;
    };

    isDisabled = (user) => {
        const { program } = this.props;
        const { isSubmitted, aggrementCheckBox, isValid } = this.state;
        if (isSubmitted) {
            return true;
        }
        if (!isValid) {
            return true;
        }
        if (!aggrementCheckBox) {
            return true;
        }
        if (program.type === 'points') {
            if (user.balance < this.grandTotal()) {
                return true;
            }
        }
        return false;
    }

    addressHandling = (user, e) => {
        const id = e.target.value;
        const address = userApi.getAddress(user, id);
        this.setState({ defaultAddress: address });
    };

    keyChanged = (key, e) => {
        const { defaultAddress } = this.state;
        defaultAddress[key] = e.target.value;
        this.setState({ defaultAddress });
    };

    renderTotals() {
        const { program } = this.props;

        return (
            <React.Fragment>
                {program && program.type === 'points' && (
                    <tbody className="checkout__totals-subtotals" />
                )}

            </React.Fragment>
        );
    }

    renderCart() {
        const {
            cart, program, region, locale,
        } = this.props;
        function equalizeLang (langData) {
            if (langData === 'sp') {
                return 'es'
            }
            if (langData === 'ge') {
                return 'de'
            }
            return langData
        }
        const goToShop = () => {
            program?.checkoutSelectDifferentProductRedirectHome ? window.location.href = '/' : window.location.href = '/shop';
        };
        console.log(cart);
        let grandTotal = 0;
        const items = cart.items.map((item) => {
            const unitPrice = getProductPrice(item.product, item.option, region);
            const totalPrice = unitPrice * item.quantity;
            grandTotal += totalPrice;
            return (
                <tr key={item.id}>
                    <td>
                        {`${selectedLanguage(item.product, equalizeLang(locale), item.product.productName, 'name')} × ${item.quantity}`}

                        {item.option && item.option.optionColorValue && (<li style={{ color: 'grey', listStyleType: 'none' }}>{`Color: ${item.option.optionColorValue}`}</li>)}
                        {item.option && item.option.optionSizeValue && (<li style={{ color: 'grey', listStyleType: 'none' }}>{`Size: ${item.option.optionSizeValue}`}</li>)}
                        {item.option && item.option.optionCustom1Value && (<li style={{ color: 'grey', listStyleType: 'none' }}>{`${item.product.optionsCustom1Label}: ${item.option.optionCustom1Value}`}</li>)}
                        {item.option && item.option.optionCustom2Value && (<li style={{ color: 'grey', listStyleType: 'none' }}>{`${item.product.optionsCustom2Label}: ${item.option.optionCustom2Value}`}</li>)}
                        {item.option && item.option.optionCustom3Value && (<li style={{ color: 'grey', listStyleType: 'none' }}>{`${item.product.optionsCustom3Label}: ${item.option.optionCustom3Value}`}</li>)}
                    </td>
                    <td>{program && program.type === 'points' && (<Points value={totalPrice} />)}</td>
                </tr>
            );
        });

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>
                            <FormattedMessage id="account_orderproduct" defaultMessage="Product" />
                        </th>
                        <th>{program && program.type === 'points' && ('Total')}</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                    <br />
                    {program.type !== 'points' && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={goToShop}
                    >
                        <FormattedMessage id="checkout_differentproduct" defaultMessage="Select a different product" />
                    </button>
)}
                    <br />
                    <tr>
                        <td aria-label="points1" />
                        <td aria-label="points2" />
                    </tr>
                </tbody>
                {this.renderTotals()}
                {program && program.type === 'points' && (
                    <tfoot className="checkout__totals-footer">
                        <tr>
                            <th>
                                <FormattedMessage id="account_ordertotal" defaultMessage="Total" />
                            </th>
                            <td aria-label="total"><Points value={grandTotal} /></td>
                        </tr>
                    </tfoot>
                )}

            </table>
        );
    }

    render() {
        const { defaultAddress } = this.state;
        const { cart, history } = this.props;
        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Shop', url: '/shop' },
            { title: 'Shopping Cart', url: '/shop/cart' },
            { title: 'Checkout', url: '' },
        ];

        const breadcrumbPlateau = [
            { title: 'Home', url: '' },
            { title: 'Shop', url: '/shop' },
            { title: 'Checkout', url: '' },
        ];
        return (
            <ProgramContext.Consumer>
                {(context) => {
                    const { program } = context;
                    return (
                        <UserContext.Consumer>
                            {(userContext) => {
                                const { user } = userContext;
                                let address = { ...defaultAddress };
                                if (address && Object.keys(address).length === 0 && address.constructor === Object && user.addresses.length > 0) [address] = user.addresses;
                                return (
                                    <React.Fragment>

                                        <Helmet>
                                            {program && (<title>{`Checkout — ${program.fullName}`}</title>)}
                                        </Helmet>
                                        <PageHeader header="Checkout" breadcrumb={program && program.type === 'points' ? breadcrumb : breadcrumbPlateau} />
                                        <div className="checkout block">
                                            <div className="container">
                                                <div className="row">

                                                    <div className="col-12 col-lg-6 col-xl-7">
                                                        <div className="card mb-lg-0">
                                                            <div className="card-body">

                                                                {user && (
                                                                    <ShopPageCheckoutAddress onAddressChange={(data, valid, mail) => { this.setState({ address: data, isValid: valid, claimEmail: mail }); }} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                                        <div className="card mb-0">
                                                            <div className="card-body">
                                                                <h3 className="card-title"><FormattedMessage id="checkout_yourorder" defaultMessage="Your Order" /></h3>

                                                                {this.renderCart(user)}

                                                                <div className="checkout__agree form-group">
                                                                    <div className="form-check">
                                                                        <span className="form-check-input input-check">
                                                                            <span className="input-check__body">
                                                                                <input className="input-check__input" type="checkbox" id="checkout-terms" onClick={this.checkBox} />
                                                                                <span className="input-check__box" />
                                                                                <Check9x7Svg className="input-check__icon" />
                                                                            </span>
                                                                        </span>
                                                                        <label className="form-check-label" htmlFor="checkout-terms">
                                                                            <FormattedMessage id="checkout_haveread" defaultMessage="I have read and agree to the website" />
                                                                            {' '}
                                                                            <Link to="/termsandconditions"><FormattedMessage id="checkout_terms" defaultMessage="terms and conditions" /></Link>
                                                                            *
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    onClick={() => {
                                                                        this.setState({ isSubmitted: true }, function () {
                                                                            const { address, claimEmail } = this.state;
                                                                            const data = { ...cart };
                                                                            data.shippingAddress = address;
                                                                            data.claimEmail = claimEmail;
                                                                            let grandTotal = 0;
                                                                            const { region } = this.props;
                                                                            for (let i = 0; i < cart.items.length; i += 1) {
                                                                                const unitPrice = getProductPrice(cart.items[i].product, cart.items[i].option, region);
                                                                                const totalPrice = unitPrice * cart.items[i].quantity;
                                                                                cart.items[i].total = totalPrice;
                                                                                cart.items[i].price = unitPrice;
                                                                                grandTotal += totalPrice;
                                                                            }
                                                                            data.total = grandTotal;
                                                                            data.region = region;
                                                                            localStorage.setItem('summary_data', JSON.stringify(data));
                                                                            history.push('/shop/checkout/summary');

                                                                            //  orderApi.placeOrder(data)
                                                                            //      .then((response) => response.json())
                                                                            //      .then((response) => {
                                                                            //          if (response.success) {
                                                                            //              window.location = `/shop/checkout/success/${response.id}`;
                                                                            //          }
                                                                            //      });
                                                                         });
                                                                    }}
                                                                    type="button"
                                                                    disabled={this.isDisabled(user)}
                                                                    className="btn btn-primary btn-xl btn-block"
                                                                >
                                                                    <FormattedMessage id="checkout_placeorder" defaultMessage="Place Order" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                        </UserContext.Consumer>
                    );
                }}
            </ProgramContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout));

ShopPageCheckout.contextType = UserContext;
