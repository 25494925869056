// react
import React, {
    useEffect, useState, useContext,
} from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import Sections from './Sections';
import ProgramContext from '../../contexts/ProgramContext';
import programApi from '../../server/real/endpoints/programs';
import UserContext from '../../contexts/UserContext';

function HomePageTwo(props) {
    const { locale, region } = props;
    const [page, setPage] = useState();
    const { program } = useContext(ProgramContext);
    const { user } = useContext(UserContext);
    const calculateLevelPage = () => {
        let levelPage;
        if (user) {
        levelPage = program?.plateauLevels?.filter((level) => level.id === user?.levelId);
        }
        if (levelPage?.[0]?.customHomePage && levelPage?.[0]?.customHomePage?.length > 0) {
            return levelPage?.[0].customHomePage;
        }
        return false
    };

    useEffect(() => {
        if (calculateLevelPage() && calculateLevelPage()?.length > 0) {
            if (!program) return;
            const content = programApi.getContent(program, locale);
            if (!content) return;
            const { pages } = content;
            if (!pages) return;
            const homePageData = pages.filter((data) => data.url === calculateLevelPage())?.length > 0 ? pages.filter((data) => data.url === calculateLevelPage()) : pages.filter((data) => data.url === 'home');
            if (homePageData.length === 0) return;
            setPage(homePageData[0]); 
        } else {
        if (!program) return;
        const content = programApi.getContent(program, locale);
        if (!content) return;
        const { pages } = content;
        if (!pages) return;
        const homePageData = pages.filter((data) => data.url === 'home');
        if (homePageData.length === 0) return;
        setPage(homePageData[0]);            
        }

    }, [program, locale, region]);
    return (
        <React.Fragment>
            <Helmet>
                {program && (<title>{`Home — ${program.fullName}`}</title>)}
            </Helmet>
            {page && <Sections sections={page.sections} />}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    region: state.region,
});

export default connect(mapStateToProps)(HomePageTwo);
