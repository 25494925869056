// react
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import Sections from '../home/Sections';

// data stubs
import ProgramContext from '../../contexts/ProgramContext';
import programApi from '../../server/real/endpoints/programs';

function UsedAccount(props) {
    const { program } = useContext(ProgramContext);
    const { locale, region } = props;
    const [page, setPage] = useState();
    useEffect(() => {
        if (!program) return;
        const content = programApi.getContent(program, locale);
        if (!content) return;
        const { pages } = content;
        if (!pages) return;
        const homePageData = pages.filter((data) => data.url === 'usedaccount');
        if (homePageData.length === 0) return;
        setPage(homePageData[0]);
    }, [program, locale, region]);
    return (
        <div className="block">

            <Helmet>
                <title>{`Program is not accessable — ${program.fullName}`}</title>
            </Helmet>

            <div className="container">
                {page && page.sections.length > 0 ? <Sections sections={page && page.sections} /> : (
                    <div className="not-found">
                        <div className="not-found__404" />

                        {program && program.type === 'claim' ? (
                            <div className="not-found__content">
                                <h1 className="not-found__title">The code you have entered has already been used to redeem an item.</h1>

                                <p className="not-found__text">
                                    Please contact your program administrator if you believe this information is not correct.
                                </p>

                            </div>
) : (
    <div className="not-found__content">
        <h1 className="not-found__title">An item has already been redeemed using the credentials you provided.</h1>

        <p className="not-found__text">
            Please contact your program administrator if you believe this information is not correct.
        </p>

    </div>
)}
                    </div>
)}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    region: state.region,
    locale: state.locale,
});

export default connect(mapStateToProps)(UsedAccount);
