import getApi from './api';
import { GetAPIEndpoint } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`;
    return { Authorization };
}
const languageApi = {
    getLanguages: () => getApi().get(`${GetAPIEndpoint()}/api/languages`, { headers: getHeaders() }),
};

export default languageApi;
