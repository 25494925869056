// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import {
    Route, Switch, useHistory,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { IntlProvider, FormattedMessage } from 'react-intl';

// application

// pages
import BlockLoader from './blocks/BlockLoader';
import Footer from './footer';
import PrivateRoute from './PrivateRoute';
import AccountLayout from './account/AccountLayout';
import AccountPageLogout from './account/AccountPageLogout';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';

import ProgramContext from '../contexts/ProgramContext';
import UserContext from '../contexts/UserContext';
import SiteRebuilding from './site/SiteRebuilding';

// data stubs
import programApi from '../server/real/endpoints/programs';
import userApi from '../server/real/endpoints/users';
import languageApi from '../server/real/endpoints/languages';

function UsedLayout() {
    const [program, setProgram] = useState();
    const [languages, setLanguages] = useState();
    const [isLoadingProgram, setisLoadingProgram] = useState(true);
    const [isLoadingLanguages, setisLoadingLanguages] = useState(true);
    const history = useHistory();
    const [user, setUser] = useState({
        addresses: [],
    });
    useEffect(() => {
        const preloader = document.querySelector('.site-preloader');
        if (preloader) {
            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity' && preloader.parentNode) {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }

        return () => {
        };
    }, [program, languages, user]);

    useEffect(() => {
        let canceled = false;

        programApi.getProgram().then((programData) => {
            if (canceled) {
                return;
            }
            setProgram(programData.data);
            setisLoadingProgram(false);
        });

        return () => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        let canceled = false;
        userApi.getUser().then((userData) => {
            const use = { ...userData };
            if (canceled) {
                return;
            }
            setUser(use.data);
        });

        return () => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        let canceled = false;

        languageApi.getLanguages().then((data) => {
            if (canceled) {
                return;
            }
            setLanguages(data.data);
            setisLoadingLanguages(false);
        });

        return () => {
            canceled = true;
        };
    }, []);

    if (isLoadingProgram || isLoadingLanguages) {
        return <BlockLoader />;
    }

    const { theme } = program;
    const root = document.documentElement;
    if (theme !== undefined) {
        root?.style.setProperty('--accent-color', theme.color1);
        root?.style.setProperty('--buttons-theme-color', theme.color2);
        root?.style.setProperty('--link-color', theme.color3);
    }
    const myProfile = () => {
        history.push('/account/myprofile');
    };
    return (
        <React.Fragment>
            <IntlProvider>
                <UserContext.Provider value={{ user, setUser }}>
                    <ProgramContext.Provider value={{ program, setProgram }}>
                        {program && (
                            <Helmet>
                                <title>{program.fullName}</title>
                                <meta name="description" content={program.fullName} />
                            </Helmet>
                        )}
                        <div className="site">
                            <header className="site__header d-lg-block d-none">
                                <div className="site-header">
                                    <div className="container">
                                        <div className="site-header__topbar topbar">
                                            <div className="topbar__container container">
                                                <div className="topbar__row">

                                                    <div className="topbar__spring" />
                                                    <div className="topbar__item" />
                                                    <button type="button" className="topbar-dropdown__btn" onClick={myProfile}>
                                                        <FormattedMessage id="topbar_myAccount" defaultMessage="MyAccount" />
                                                    </button>

                                                    <div className="topbar__item" />

                                                    <div className="topbar__item" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container row align-items-center" style={{ height: '50px' }}>
                                            <div className="nav-panel__logo col-4">

                                                <img width="auto" height="50px" alt="logo" src={program.theme.logo} />
                                            </div>
                                            {program && (
                                                <div className="col-7">
                                                    {program.adminMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="site-header__nav-panel">
                                        <div className="nav-panel">
                                            <div className="nav-panel__container container">
                                                <div className="nav-panel__row">

                                                    <div className="nav-panel__nav-links nav-links" />

                                                    <div className="nav-panel__indicators" />
                                                    <div>
                                                        <div className="nav-links__item" style={{ fontWeight: '700', marginLeft: '30px', fontSize: '15px' }} />
                                                    </div>
                                                </div>
                                                <div className="container" style={{ marginTop: '15px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <div className="site-header__search" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>

                            <ToastContainer autoClose={5000} hideProgressBar />

                            <div className="site__body">
                                <Switch>

                                    <Route exact path="/" component={SiteRebuilding} />
                                    <PrivateRoute exact path="/shop/checkout/success/:orderId" component={ShopPageOrderSuccess} />
                                    <Route
                                        exact
                                        path="/account/logout"
                                        component={AccountPageLogout}
                                    />
                                    <PrivateRoute path="/account" component={AccountLayout} />

                                </Switch>
                            </div>

                            <footer className="site__footer">
                                <Footer />
                            </footer>
                        </div>
                    </ProgramContext.Provider>
                </UserContext.Provider>
            </IntlProvider>
        </React.Fragment>
    );
}

UsedLayout.defaultProps = {
    headerLayout: 'compact',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(UsedLayout);
