// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import SitePageNotFound from './site/SitePageNotFound';
import AccountPageResetPassword from './account/AccountPageResetPassword';

// application

// pages
import BlockLoader from './blocks/BlockLoader';
import AccountPageLogin from './account/AccountPageLogin';
import Footer from './footer';

import ProgramContext from '../contexts/ProgramContext';
import UserContext from '../contexts/UserContext';
import SiteProgramClosed from './site/SiteProgramClosed';

// data stubs
import programApi from '../server/real/endpoints/programs';
import userApi from '../server/real/endpoints/users';
import languageApi from '../server/real/endpoints/languages';
import MobileHeaderLogin from './mobile/MobileHeaderLogin';

function Layout(props) {
    const { locale } = props;
    const [program, setProgram] = useState();
    const [languages, setLanguages] = useState();
    const [isLoadingProgram, setisLoadingProgram] = useState(true);
    const [isLoadingLanguages, setisLoadingLanguages] = useState(true);
    const history = useHistory();
    const [user, setUser] = useState({
        addresses: [],
    });
    useEffect(() => {
        const preloader = document.querySelector('.site-preloader');
        if (preloader) {
            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity' && preloader.parentNode) {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }

        return () => {
        };
    }, [program, languages, user]);

    useEffect(() => {
        let canceled = false;

        programApi.getProgram().then((programData) => {
            if (!programData.data) {
                history.push('/login/not-found');
            }
            if (canceled) {
                return;
            }
            setProgram(programData.data);
            setisLoadingProgram(false);
        });

        return () => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        let canceled = false;
        userApi.getUser().then((userData) => {
            const use = { ...userData };
            if (canceled) {
                return;
            }
            setUser(use.data);
        });

        return () => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        let canceled = false;

        languageApi.getLanguages().then((data) => {
            if (canceled) {
                return;
            }
            setLanguages(data.data);
            setisLoadingLanguages(false);
        });

        return () => {
            canceled = true;
        };
    }, []);

    if (isLoadingProgram || isLoadingLanguages) {
        return <BlockLoader />;
    }
    const { messages } = languages?.[locale];
    const { theme } = program;
    const root = document.documentElement;
    if (theme !== undefined) {
        root?.style.setProperty('--accent-color', theme.color1);
        root?.style.setProperty('--buttons-theme-color', theme.color2);
        root?.style.setProperty('--link-color', theme.color3);
    }
    return (
        <React.Fragment>
            <IntlProvider locale={locale} messages={messages}>
                <UserContext.Provider value={{ user, setUser }}>
                    <ProgramContext.Provider value={{ program, setProgram }}>
                        {program && (
                        <Helmet>
                            <link rel="shortcut icon" href={`${program && program.theme.favIcon}`} />
                            <title>{program.fullName}</title>
                            <meta name="description" content={program.fullName} />
                        </Helmet>
                        )}
                        <div className="site">
                            <header className="site__header d-lg-none d-flex justify-content-center">
                                <MobileHeaderLogin />
                            </header>
                            <header className="site__header d-lg-block d-none" style={{ height: '138px'}}>
                                <div className="site-header">
                                    <div className="container">
                                        <div className="site-header__topbar topbar">
                                            <div className="topbar__container container">
                                                <div className="topbar__row">

                                                    <div className="topbar__spring" />
                                                    <div className="topbar__item" />

                                                    <div className="topbar__item" />

                                                    <div className="topbar__item" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container row align-items-center" style={{ height: '50px' }}>
                                            <div className="nav-panel__logo col-3">

                                            <div style={{ textAlign: 'center' }}>
                                                <img style={{ maxHeight: '90px', maxWidth: '150px'}} src={program.theme.logo}></img>
                                                </div>
                                            </div>
                                            {program && (
                                            <div className="col-6" style={{ textAlign: `${program?.messageAlign === 'Left' ? 'left' : program?.messageAlign === 'Right' ? 'right' : 'center' }`, fontSize: `${program?.messageSize === 'Small' ? '18px' : program?.messageSize === 'Large' ? '22px' : '20px' }`}}>
                                            <span ><FormattedMessage id="header_adminmessage" defaultMessage={program.adminMessage || " "} /></span>
                                        </div>
                                        
                                            )}
                                            <div className="col-3"></div>
                                        </div>
                                    </div>
                                    {/* <div className="site-header__nav-panel">
                                        <div className="nav-panel">
                                            <div className="nav-panel__container container">
                                                <div className="nav-panel__row">

                                                    <div className="nav-panel__nav-links nav-links" />

                                                    <div className="nav-panel__indicators" />
                                                    <div>
                                                        <div className="nav-links__item" style={{ fontWeight: '700', marginLeft: '30px', fontSize: '15px' }} />
                                                    </div>
                                                </div>
                                                <div className="container" style={{ marginTop: '15px', paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <div className="site-header__search" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </header>

                            <ToastContainer autoClose={5000} hideProgressBar />

                            <div className="site__body" style={{ backgroundImage: `url(${program?.theme?.backgroundImage})`, backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    alignContent: 'center'}}>
                                <Switch>
                                    <Route exact path="/login" component={program && program.status ? AccountPageLogin : SiteProgramClosed} />
                                    <Route exact path="/login/reset-password" component={AccountPageResetPassword} />
                                    <Route component={SitePageNotFound} />
                                </Switch>
                            </div>

                            <footer className="site__footer">
                                <Footer />
                            </footer>
                        </div>
                    </ProgramContext.Provider>
                </UserContext.Provider>
            </IntlProvider>
        </React.Fragment>
    );
}

Layout.defaultProps = {
    headerLayout: 'compact',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Layout);
