// react
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
// import Sections from '../home/Sections';

// application
import Points from '../shared/Points';
// import { Check100Svg } from '../../svg';
import orderApi from '../../server/real/endpoints/orders';

// data stubs
import { url } from '../../services/utils';
import ProgramContext from '../../contexts/ProgramContext';
import { cartClear } from '../../store/cart';
// import programApi from '../../server/real/endpoints/programs';

function ShopPageOrderSummary() {
    const { program } = useContext(ProgramContext);
//    const { locale, region } = props;
    const order = JSON.parse(localStorage.getItem('summary_data'));
    const [disableButton, setDisableButton] = useState(false);
    const history = useHistory();
    // const { params } = match;
//    const [page, setPage] = useState();
    // useEffect(() => {
    //     if (!program) return;
    //     const content = programApi.getContent(program, locale);
    //     if (!content) return;
    //     const { pages } = content;
    //     if (!pages) return;
    //     const homePageData = pages.filter((data) => data.url === 'ordersuccess');
    //     if (homePageData.length === 0) return;
    //     setPage(homePageData[0]);
    // }, [program, locale, region]);

    // const formatDate = (date) => {
    //     const d = new Date(date);
    //     let month = `${d.getMonth() + 1}`;
    //     let day = `${d.getDate()}`;
    //     const year = d.getFullYear();

    // if (month.length < 2) month = `0${month}`;
    // if (day.length < 2) day = `0${day}`;

    // return [year, month, day].join('-');
    // };

    let items;
    if (order) {
        items = order.items.map((item) => {
            const options = (item.options || []).map((option) => (
                <li key={option.value} className="order-list__options-item">
                    <span className="order-list__options-label">
                        {option.optionCustom1Value}
                        {': '}
                    </span>
                    <span className="order-list__options-value">{option.optionCustom1Value}</span>
                </li>
            ));

            return (
                <tr key={`order-tr-${item.id}`}>
                    <td className="order-list__column-image">
                        <div className="product-image">
                            <span className="product-image__body">
                                <img className="product-image__img" alt={item.product.productName} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXqVOrqDGDYnd2CaWJzVWnDi75ZZzOQuH3UoTQddunEcBVND450sTSfLrZh9fvdbfw_eY&usqp=CAU" />
                            </span>
                        </div>
                    </td>
                    <td className="order-list__column-product">
                        <span>{item.product.productName}</span>

                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {item.product.options.length < 0 && (
                                <React.Fragment>
                                    {item.product.options && item.product.options[0].optionColorValue && (
                                    <li>
                                        {item.product.options[0].optionColorValue}
                                        &nbsp;
                                        /
                                        &nbsp;
                                    </li>
)}
                                    {item.product.options && item.product.options[0].optionSizeValue && (
                                    <li>
                                        {item.product.options[0].optionSizeValue}
                                        &nbsp;
                                        /
                                        &nbsp;
                                    </li>
)}
                                    {item.product.options && item.product.options[0].optionCustom1Value && (
                                    <li>
                                        {item.product.options[0].optionCustom1Value}
                                        &nbsp;
                                        /
                                        &nbsp;
                                    </li>
)}
                                    {item.product.options && item.product.options[0].optionCustom2Value && (
                                    <li>
                                        {item.product.options[0].optionCustom2Value}
                                        &nbsp;
                                        /
                                        &nbsp;
                                    </li>
)}
                                    {item.product.options && item.product.options[0].optionCustom2Value && (
                                    <li>
                                        {item.product.options[0].optionCustom3Value}
                                    </li>
)}
                                </React.Fragment>
)}

                            </ul>
                        </div>
                    </td>

                    <td className="order-list__column-product">
                        <Link to={url.product(item)}>{item.name}</Link>
                        {options.length > 0 && (
                            <div className="order-list__options">
                                <ul className="order-list__options-list">
                                    {options}
                                </ul>
                            </div>
                        )}
                    </td>
                    {program.type !== 'points' && (
                        <td aria-label="span2"><span /></td>
                    )}
                    <td className="order-list__column-quantity" width={110} data-title="ETA:">
                        {item.product.productDropShipDays}
                        {' '}
                        days
                    </td>
                    {program.type === 'points' && (
                        <React.Fragment>

                            <td className="order-list__column-quantity" data-title="Qty:">{item.quantity}</td>
                            <td aria-label="itemtotal" className="order-list__column-total"><Points value={item.total} /></td>
                        </React.Fragment>
                    )}
                </tr>
            );
        });
    }

    let colSpan = '4';
    if (program.type === 'points') {
        colSpan = '3';
    }
    if (!order) {
        return (<span />);
    }
    const { shippingAddress } = order;

    return (
        <div className="block order-success" style={{ marginTop: '50px' }}>
            <Helmet>
                <title>{`Order Success — ${program.fullName}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">

                    {/* {page && page.sections ? <Sections sections={page && page.sections} /> : (
                        <div className="order-success__header">
                            <Check100Svg className="order-success__icon" />
                            <h1 className="order-success__title"><FormattedMessage id="success_thankyou" defaultMessage="Thank you" /></h1>
                            <div className="order-success__subtitle"><FormattedMessage id="success_orderrecieved" defaultMessage="Your order has been received" /></div>
                            <div className="order-success__actions">
                                <Link to="/" className="btn btn-xs btn-secondary"><FormattedMessage id="success_homepage" defaultMessage="Go To Homepage" /></Link>
                            </div>
                        </div>
) } */}
                    {/* <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="success_ordernumber" defaultMessage="Order number" />
                                    :
                                </span>
                                <span className="order-success__meta-value">{`#${order.orderNumber}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="success_created" defaultMessage="Created at" />
                                    :
                                </span>
                                <span className="order-success__meta-value">{formatDate(order.date)}</span>
                            </li>

                            {program.type === 'points' && (
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title">Total:</span>
                                    <span className="order-success__meta-value"><Points value={order.total} /></span>
                                </li>
                            )}
                        </ul>
                    </div> */}

                    <div className="card">
                        <div className="order-list">
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan={colSpan}>
                                            <FormattedMessage id="success_product" defaultMessage="Product" />
                                        </th>
                                        <th className="order-list__column-quantity">
                                                    <FormattedMessage id="success_qty" defaultMessage="Eta" />
                                        </th>
                                        {program.type === 'points' && (
                                            <React.Fragment>
                                                <th className="order-list__column-quantity">
                                                    <FormattedMessage id="success_qty" defaultMessage="Qty" />
                                                </th>
                                                <th className="order-list__column-total">
                                                    <FormattedMessage id="success_total" defaultMessage="Total" />
                                                </th>
                                            </React.Fragment>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                    {items}
                                </tbody>
                                {program.type === 'points' && (
                                    <tfoot className="order-list__footer">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="4">
                                                <FormattedMessage id="success_total" defaultMessage="Total" />
                                            </th>
                                            <td><button type="button" className="btn btn-primary" style={{ float: 'right' }} onClick={() => history.push('/shop/cart')}>Edit</button></td>
                                            <td aria-label="order-total2" className="order-list__column-total"><Points value={order.total} /></td>
                                        </tr>
                                    </tfoot>
                                )}
                            </table>
                        </div>
                    </div>

                    <div className="row mt-3 no-gutters mx-n2">
                        <div className="col-sm-12 col-12 px-2">
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        <FormattedMessage id="success_shipping" defaultMessage="Shipping Address" />
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title"><FormattedMessage id="success_addressName" defaultMessage="Address Name" /></div>
                                        <div className="address-card__row-content">{shippingAddress.fullName}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title"><FormattedMessage id="success_address" defaultMessage="Address" /></div>
                                        <div className="address-card__row-content">{shippingAddress.address1}</div>
                                        {shippingAddress.address2 && (
                                        <React.Fragment>
                                            <div className="address-card__row-content">{shippingAddress.address2}</div>
                                        </React.Fragment>
)}
                                        {shippingAddress.address3 && (
                                        <React.Fragment>
                                            <div className="address-card__row-content">{shippingAddress.address3}</div>
                                        </React.Fragment>
)}
                                        {shippingAddress.address4 && (
                                        <React.Fragment>
                                            <div className="address-card__row-content">{shippingAddress.address4}</div>
                                        </React.Fragment>
)}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">{program && program.type === 'claim' ? <FormattedMessage id="success_addressNameClaim" defaultMessage="Code" /> : <FormattedMessage id="success_addressName" defaultMessage="Email" />}</div>
                                        <div className="address-card__row-content">{order?.user?.email}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title"><FormattedMessage id="checkout_statecounty" defaultMessage="State / County" /></div>
                                        <div className="address-card__row-content">{shippingAddress.stateCounty}</div>
                                    </div>

                                    <div className="address-card__row">
                                        <div className="address-card__row-title"><FormattedMessage id="checkout_towncity" defaultMessage="Town / City" /></div>
                                        <div className="address-card__row-content">{shippingAddress.townCity}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title"><FormattedMessage id="checkout_postcodezip" defaultMessage="Postcode / ZIP" /></div>
                                        <div className="address-card__row-content">{shippingAddress.postcode}</div>
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title"><FormattedMessage id="success_phone" defaultMessage="Phone Number" /></div>
                                        <div className="address-card__row-content">{order.shippingAddress.phone}</div>
                                    </div>
                                    <button type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" style={{ float: 'right' }} onClick={() => history.push('/shop/checkout')}>Edit Address</button>
                                </div>
                            </div>
                            <button
                            type="button"
                                className="btn btn-primary mt-2 mt-md-3 mt-lg-4"
                                style={{ float: 'right' }}
                                disabled={disableButton}
                                onClick={() => {
                                    setDisableButton(true)
                                  orderApi.placeOrder(order)
                                      .then((response) => response.json())
                                      .then((response) => {
                                          if (response.success) {
                                              history.push(`/shop/checkout/success/${response.id}`);
                                              localStorage.removeItem('summary_data');
                                          }
                                          setDisableButton(false)
                                      });
                            }}
                            >
                                Confirm

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    cart: state.cart,
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {
    cartClear,
    };

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderSummary);
