export function makeIdGenerator() {
    let lastId = 0;

    return () => {
        lastId += 1;

        return lastId;
    };
}

export function nameToSlug(name) {
    return name.toLowerCase().replace(/[^a-z0-9]/, '-').replace(/-+/, '-');
}

export function categoryHasProducts() {
    return true;
}

export function getProductColors(product) {
    if (!product.options) {
        return [];
    }
    const colors = product.options.map((a) => a.optionColorValue);
    const unique = colors.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}

export function getProductSizes(product) {
    if (!product || !product.options) {
        return [];
    }
    const sizes = product.options.map((a) => a.optionSizeValue);
    const unique = sizes.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}
export function getCustom1Options(product) {
    if (!product || !product.options) {
        return [];
    }
    const values = product.options.map((a) => a.optionCustom1Value);
    const unique = values.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}
export function getCustom2Options(product) {
    if (!product || !product.options) {
        return [];
    }
    const values = product.options.map((a) => a.optionCustom2Value);
    const unique = values.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}
export function getCustom3Options(product) {
    if (!product || !product.options) {
        return [];
    }
    const values = product.options.map((a) => a.optionCustom3Value);
    const unique = values.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}

export function getAvailableOptions(product, color, size, custom1, custom2, custom3) {
    if (!product || !product.options) {
        return [];
    }
    const options = product.options.filter((data) => {
        const colorValid = color === undefined || data.optionColorValue === color;
        const sizeValid = size === undefined || data.optionSizeValue === size;
        const custom1Valid = custom1 === undefined || data.optionCustom1Value === custom1;
        const custom2Valid = custom2 === undefined || data.optionCustom2Value === custom2;
        const custom3Valid = custom3 === undefined || data.optionCustom3Value === custom3;
        return colorValid && sizeValid && custom1Valid && custom2Valid && custom3Valid;
    });
    return options;
}
export function getProductOption(product, color, size, custom1, custom2, custom3) {
    const options = getAvailableOptions(product, color, size, custom1, custom2, custom3);

    const colorExists = product.options.filter((data) => data.optionSizeValue && data.optionSizeValue.length > 0) > 0;
    const sizeExists = product.options.filter((data) => data.optionColorValue && data.optionColorValue.length > 0) > 0;
    const custom1Exists = product.optionsCustom1Label && product.optionsCustom1Label.length > 0;
    const custom2Exists = product.optionsCustom2Label && product.optionsCustom2Label.length > 0;
    const custom3Exists = product.optionsCustom3Label && product.optionsCustom3Label.length > 0;

    const colorValid = (colorExists && color && color.length > 0) || !colorExists;
    const sizeValid = (sizeExists && size && size.length > 0) || !colorExists;
    const custom1Valid = (custom1Exists && custom1 && custom1.length > 0) || !custom1Exists;
    const custom2Valid = (custom2Exists && custom2 && custom2.length > 0) || !custom2Exists;
    const custom3Valid = (custom3Exists && custom3 && custom3.length > 0) || !custom3Exists;

    if (!colorValid || !sizeValid || !custom1Valid || !custom2Valid || !custom3Valid) return undefined;

    if (options.length === 1) return options[0];
    return undefined;
}

export function getCostByRegion(product, region) {
    if (!region) return undefined;
    const { costs } = product;
    if (costs) {
        const selectedCosts = costs.filter((data) => region.country.indexOf('USA') !== -1 ? data.regionName === region.country : data.regionCCCode === region.country);
        if (selectedCosts.length === 0) return undefined;
        return selectedCosts[0];
    }
    return undefined;
}

export function getProductPrice(product, option, region) {
    let { price } = product;
    if (!price) price = 0;
    if (option) {
        const optionCost = getCostByRegion(option, region);
        if (optionCost) {
            return optionCost.price;
        }
    }
    const cost = getCostByRegion(product, region);
    if (cost) {
        return cost.price;
    }
    return price;
}

export function getProductDiscountPrice(product, option, region) {
    let { discountPrice } = product;
    const cost = getCostByRegion(product, region);
    if (cost) {
        discountPrice += cost.costHandling;
    }
    if (option && option.price) {
        discountPrice += option.price;
    }
    return discountPrice;
}

export function selectedLanguage(product, locale, defaultData, data) {
    if (product.langs === undefined) {
        return defaultData;
    }
    const selectedLang = product.langs[locale];

    if (selectedLang) {
        if (selectedLang[data] === '') {
            return defaultData;
        }
        return selectedLang[data];
    }
    return defaultData;
}
