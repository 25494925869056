// react
import React, { useContext } from 'react';

// third-party
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ProgramContext from '../../contexts/ProgramContext';

// application
import Dropdown from './Dropdown';
import { localeChange } from '../../store/locale';

function DropdownLanguage(props) {
    const { locale, localeChange: changeLocale } = props;
    const { program } = useContext(ProgramContext);

    let languages = [
        {
            title: 'English',
            locale: 'en',
            code: 'EN',
        },
        {
            title: 'French',
            locale: 'fr',
            code: 'FR',
        },
        {
            title: 'Spanish',
            locale: 'sp',
            code: 'SP',
        },
        {
            title: 'German',
            locale: 'ge',
            code: 'GE',
        },
        {
            title: 'Italian',
            locale: 'it',
            code: 'IT',
        },
        {
            title: 'Portuguese',
            locale: 'pt',
            code: 'PT',
        },
        {
            title: 'Dutch',
            locale: 'nl',
            code: 'NL',
        },
    ];
    languages = languages.filter((data) => program.languages.indexOf(data.locale) !== -1);

    const language = languages.find((x) => x.locale === locale);

    const title = (
        <React.Fragment>
            <FormattedMessage id="topbar_language" defaultMessage="Language" />
            {': '}
            <span className="topbar__item-value">{language.code}</span>
        </React.Fragment>
    );

    if (program.languages.length < 2) {
        return (
        <React.Fragment>
        <button type="button" className="topbar-dropdown__btn">
            <FormattedMessage id="topbar_language" defaultMessage="Language" />
            :
            {language.code}
        </button>
        </React.Fragment>
        );
    }
        return (
            <Dropdown
                title={title}
                items={languages}
                onClick={(item) => changeLocale(item.locale)}
            />
        );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage);
