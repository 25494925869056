// * fallback: https://testpoints.rewardstaging.com
export const API_ENDPOINT = '';
export const ACTION_INVALID2 = 'This action is invalid!';

export const GetAPIEndpoint = () => {
    if (API_ENDPOINT.length > 0) {
        return API_ENDPOINT;
    }
    return window.location.origin;
};
export function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`;
    return { Authorization };
}
