// react
import React from 'react';

// third-party
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import auth from '../server/real/endpoints/auth';

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => (auth.isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/account/timeout',
                        state: { from: props.location },
                    }}
                />
            ))}
        />
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PrivateRoute);
