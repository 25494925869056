import axios from 'axios';
import { GetAPIEndpoint } from '../../configuration';

function getToken() {
    const tokenString = localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token');
    if (!tokenString) return '';
    return tokenString;
}

const auth = {
    isAuthenticated: () => {
    const authenticated = getToken().length > 0;
    if (!authenticated && !(window.location.href.includes('login') || window.location.href.includes('mindtickle/score') || window.location.href.includes('mindtickle/callback') || window.location.href.includes('mindtickle/okta') || window.location.href.includes('mindtickle/redirect'))) {
        window.location = '/login';
    }
    return authenticated;
},
    authenticate(email, password, cb) {
        axios.post(`${GetAPIEndpoint()}/token`, { email, password }).then((data) => {
            if (!data.data) {
                cb({ success: false });
                return;
            }
            if (data.status !== 200) {
                cb({ success: false });
                return;
            }

            localStorage.setItem('access_token', data.data.access_token);
            cb({ success: true });
        }).catch(
            (error) => {
                cb({ success: false });
                return Promise.reject(error);
            },
        );
    },
    authenticateSSO(token, cb) {
        axios.post(`${GetAPIEndpoint()}/api/sso`, { token }).then((data) => {
            if (!data.data) {
                cb({ success: false });
                return;
            }
            if (data.status !== 200) {
                cb({ success: false });
                return;
            }

            localStorage.setItem('access_token', data.data.access_token);
            cb({ success: true });
        }).catch(
            (error) => {
                cb({ success: false });
                return Promise.reject(error);
            },
        );
    },
    signout(cb) {
        localStorage.removeItem('access_token');
        sessionStorage.removeItem('access_token');
        cb();
    },
};

export default auth;
