// react
import React, { Component, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ScrollContext } from 'react-router-scroll-4';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';

// application
import { localeChange } from '../store/locale';
import programApi from '../server/real/endpoints/programs';

// pages
import Layout from './Layout';
import LoginLayout from './LoginLayout';
import MindTickle from './shop/MindTickle';
import RedirectOidc from './shop/RedirectOidc';
import auth from '../server/real/endpoints/auth';
import CallbackOkta from './shop/Callback';


class Root extends Component {

    shouldUpdateScroll = (prevRouterProps, { location }) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );


    constructor(props) {
        super(props);
        this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
           window.location = toRelativeUrl(originalUri || '/mindtickle/redirect', window.location.origin);
        };
        this.state = {
            programData: undefined,
          }
      }
      componentDidMount() {
        programApi.getProgram().then((programData) => {
           this.setState({
            programData: programData.data
          });
       });
   }
    render() {
        const { locale } = this.props;
        const direction = 'ltr';
        let oktaAuth
         if (this.state?.programData?.ssoEnabled) {
             oktaAuth = new OktaAuth({
                 issuer: this.state.programData?.oktaConfig?.serviceURL,
                 clientId: this.state.programData?.oktaConfig?.clientId,
                 redirectUri: window.location.origin + '/mindtickle/callback'
               });
         }
            if (!this.state?.programData) return null
            if (auth.isAuthenticated()) {
                return (
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <HelmetProvider>
                            <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                            <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                                <Switch>
                                    <Route
                                        path="/"
                                        render={(props) => (
                                            <Layout {...props} headerLayout="compact" />
                                    )}
                                    />
                                    <Redirect to="/" />
                                </Switch>
                            </ScrollContext>
                        </HelmetProvider>
                    </BrowserRouter>
                );
            }
            return (
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                            <Switch>
                                <Route
                                    path="/login"
                                    render={(props) => (
                                        <LoginLayout {...props} headerLayout="compact" />
                                    )}
                                />
                                <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                                    <Route path="/mindtickle/score" exact={true} component={MindTickle} />
                                    <Route path="/mindtickle/callback" component={LoginCallback} />
                                    <SecureRoute path="/mindtickle/okta" component={CallbackOkta} />
                                    <SecureRoute path="/mindtickle/redirect" component={RedirectOidc} />
                                </Security>
                                <Redirect to="/login" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            );
        }

    }

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
    program: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
