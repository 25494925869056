// react
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import Collapse from '../shared/Collapse';
import FilterCategory from '../filters/FilterCategory';
import FilterCheck from '../filters/FilterCheck';
import FilterRange from '../filters/FilterRange';
import { FormattedMessage } from 'react-intl';
import getFilterHandler from '../../services/filters';
import { ArrowRoundedDown12x7Svg } from '../../svg';

const filterComponents = {
    category: FilterCategory,
    range: FilterRange,
    check: FilterCheck,

};

function WidgetFilters(props) {
    const {
        dispatch,
        values,
        title,
        offcanvas,
        filters,
    } = props;

    const history = useHistory();
    const [checkbox, setCheckbox] = useState();
//    const [chipData, setChipData] = useState([]);

    const getCheckBox = (data) => {
        setCheckbox(data);
    };

    const handleValueChange = useCallback(({ filter, value }) => {
        const handler = getFilterHandler(filter);

        if (handler) {
            dispatch({
                type: 'SET_FILTER_VALUE',
                filter: filter.slug,
                value: handler.isDefaultValue(filter, value) ? undefined : handler.serialize(value),
            });
        }
    }, [dispatch]);
    const handleResetFilters = () => {
        dispatch({ type: 'RESET_FILTERS' });
        setCheckbox(false);
        if (window.location.href.indexOf('/shop/catalog/') !== -1) {
            history.push('/shop/catalog');
        }
    };

    const brandChips = [];
    let brandFilter;

    const filtersList = filters.map((filter) => {
        let filterView;
        let { value } = filter;
        const handler = getFilterHandler(filter);

        if (handler && filter.slug in values) {
            value = handler.deserialize(values[filter.slug]) || handler.getDefaultValue(filter);
            if (filter.slug === 'brand') {
                brandChips.push(...value);
                brandFilter = filter;
            }
        }

        const FilterComponent = filterComponents[filter.type];

        if (FilterComponent) {
            filterView = (
                <FilterComponent
                    getCheckBox={(data) => { getCheckBox(data); }}
                    checkbox={checkbox}
                    data={filter}
                    value={value}
                    onChangeValue={handleValueChange}
                />
            );
        }

        return (
            <Collapse
                key={filter.slug}
                toggleClass="filter--opened"
                render={({ toggle, setItemRef, setContentRef }) => filter && filter.permission && (
                    <div key={filter.slug} className="widget-filters__item">
                        <div className="filter filter--opened" ref={setItemRef}>
                            <button type="button" className="filter__title" onClick={toggle}>
                                {filter.name === 'Brands' ? <FormattedMessage id="sidebar_mostpopularbrands" defaultMessage="Most Popular Brands" /> : filter.name === 'Categories' ? <FormattedMessage id="sidebar_categories" defaultMessage="Categories" /> : filter.name }
                                <ArrowRoundedDown12x7Svg className="filter__arrow" />
                            </button>
                            <div className="filter__body" ref={setContentRef}>
                                <div className="filter__container">
                                    {filterView}
                                </div>
                            </div>
                        </div>
                    </div>
)}
            />
        );
    });

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    const chipDataHandler = (index) => {
        const chipCopy = { value: brandChips, filter: brandFilter };
        chipCopy.value.splice(index, 1);
        handleValueChange(chipCopy);
    };

    return (
        <div className={classes}>
            <div className="row align-items-center justify-content-around">
                <h4><FormattedMessage id="sidebar_filters" defaultMessage="Filters" /></h4>
            </div>
            <div className="row align-items-center">
                {brandChips.map((brand, index) => (
                        <div key={index} style={{ width: '100%', textAlign: 'center' }}>
                            <div className="md-chip">
                            <span>{brand}</span>
                            <button type="button" aria-label="Chip" className="md-chip-remove" onClick={() => chipDataHandler(index)} />
                            </div>
                        </div>
                     ))}

            </div>
            <div className="row align-items-center justify-content-around">
                <button
                    style={{ marginBottom: '20px', marginTop: '20px' }}
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={handleResetFilters}
                >
                    <FormattedMessage id="sidebar_resetfilters" defaultMessage="Reset Filters" />
                </button>
            </div>
            <div className="widget-filters__list">
                {filtersList}
            </div>
            {/* <div className="widget-filters__actions d-flex mb-n2" />
            <button
                style={{ marginBottom: '20px' }}
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={handleResetFilters}
            >
                Reset
            </button> */}
        </div>
    );
}

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * Products list filter values.
     */
    values: PropTypes.object,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

WidgetFilters.defaultProps = {
    offcanvas: 'mobile',
};

export default WidgetFilters;
