// react
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useContext,
} from 'react';

// third-party
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import CategoriesContext from '../../contexts/CategoriesContext';
// application
import productApi from '../../server/real/endpoints/products';
import Suggestions from './Suggestions';
import { Cross20Svg, Search20Svg } from '../../svg';

function Search(props) {
    const {
        context,
        className,
        inputRef,
        onClose,
        location,
        region,
    } = props;
    const [cancelFn, setCancelFn] = useState(() => () => { });
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const [suggestedProducts, setSuggestedProducts] = useState();
    const [categories, setCategories] = useState([]);
    const { categories: categoriesData } = useContext(CategoriesContext);

    const treeToList = (categories, depth = 0) => (
        categories.reduce(
            (result, category) => [
                ...result,
                { depth, ...category },
                ...treeToList(category.children || [], depth + 1),
            ],
            [],
        )
    );

    let searchQuery = new URLSearchParams(location.search).get('filter_search');
    if (searchQuery === 'null') { searchQuery = ''; }
    const [query, setQuery] = useState();
    const [category, setCategory] = useState('[all]');

    useEffect(() => {
        if (!categoriesData) {
            return () => {
            };
        }

        setCategories(treeToList(categoriesData));

        return () => {
        };
    }, [categoriesData]);

    const wrapper = useRef(null);
    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setSuggestionsOpen(false);
    }, [onClose]);

    // Close suggestions when the location has been changed.
    useEffect(() => close(), [close, location]);

    // Close suggestions when a click has been made outside component.
    useEffect(() => {
        const onGlobalClick = (event) => {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener('mousedown', onGlobalClick);

        return () => document.removeEventListener('mousedown', onGlobalClick);
    }, [close]);

    // Cancel previous typing.
    useEffect(() => () => cancelFn(), [cancelFn]);

    const getSuggestions = () => {
        let canceled = false;
        let timer;

        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        };

        if (!query || query.length < 3) {
            setHasSuggestions(false);
        } else {
            const options = { limit: 5 };

            if (category !== '[all]') {
                options.category = category;
            }
            options.region = region;

            productApi.getSuggestions(query, options).then((products) => {
                if (canceled) {
                    return;
                }
                setSuggestedProducts(products.data.items);
                setHasSuggestions(products.data.items.length > 0);
                setSuggestionsOpen(true);
            });
        }
        setCancelFn(() => newCancelFn);
    };

    useEffect(() => {
        getSuggestions();
    }, [query, category]);

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleChangeQuery = (event) => {
        setQuery(event.target.value);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
                close();
            }
        }, 10);
    };

    // Close suggestions when the Escape key has been pressed.
    const handleKeyDown = (event) => {
        // Escape.
        if (event.which === 27) {
            close();
        }
    };

    const rootClasses = classNames(`search search--location--${context}`, className, {
        'search--suggestions-open': suggestionsOpen,
        'search--has-suggestions': hasSuggestions,
    });

    const closeButton = context !== 'mobile-header' ? '' : (
        <button className="search__button search__button--type--close" type="button" onClick={close}>
            <Cross20Svg />
        </button>
    );

    const categoryOptions = categories.map((category) => (
        <option key={category.slug} value={category.slug}>
            {'\u00A0'.repeat(4 * category.depth)}
            {category.name}
        </option>
    ));

    let action = 'shop';
    if (category && category !== '[all]') { action = `shop/catalog/${category}`; }

    return (
        <div className={rootClasses} ref={wrapper} onBlur={handleBlur}>
            <div className="search__body">
                <form className="search__form" action={action}>
                    {context === 'header' && (
                        <select
                            className="search__categories"
                            aria-label="Category"
                            style={{ color: '#3d464d' }}
                            value={category}
                            onFocus={close}
                            onChange={handleChangeCategory}
                        >
                            <FormattedMessage id="search_allcategories" defaultMessage="All Categories">
                                {(placeholder) => (
                                    <option value="[all]">
                                        {placeholder}
                                    </option>
                                )}
                            </FormattedMessage>
                            {categoryOptions}
                        </select>
                    )}
                    <FormattedMessage id="search_searchplaceholder" defaultMessage="Search over 10,000 products">
                        {(placeholder) => (
                            <input
                                style={{ color: 'black' }}
                                ref={inputRef}
                                onChange={handleChangeQuery}
                                onFocus={handleFocus}
                                onKeyDown={handleKeyDown}
                                value={query}
                                className="search__input"
                                name="filter_search"
                                placeholder={placeholder}
                                aria-label="Site search"
                                type="text"
                                autoComplete="on"
                            />
                        )}
                    </FormattedMessage>
                    <button className="search__button search__button--type--submit" type="submit">
                        <Search20Svg />
                    </button>
                    {closeButton}
                    <div className="search__border" />
                </form>
                {suggestedProducts && <Suggestions className="search__suggestions" context={context} products={suggestedProducts} />}

            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    region: state.region,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
