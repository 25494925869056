// react
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import ProgramContext from '../../contexts/ProgramContext';
import UserContext from '../../contexts/UserContext';
import userApi from '../../server/real/endpoints/users';
import locations from '../Countries';

export default function AccountPageNewAddress() {
    const { user } = useContext(UserContext);
    const [address, setAddress] = useState();
    const { program } = useContext(ProgramContext);
    let isDisabled;
    if (program && program.permissions && program.permissions.allowAddressManagement === false) {
        isDisabled = true;
    }

    const addAddress = () => {
        userApi.addAddress(user.sid, address).then(() => {
            window.location = 'account/addresses';
        });
    };

    const zipRegCnd = () => {
        const zipReg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if (!zipReg.test(address.postcode)) {
          return 'form-control is-invalid';
        }
        return 'form-control';
    };
    const zipRegUs = () => {
        const zipReg = /(^\d{5}$)|(^\d{5}[ -]?\d{4}$)/;
        if (!zipReg.test(address.postcode)) {
          return 'form-control is-invalid';
        }
        return 'form-control';
    };

    const phoneLength = () => {
        if ((address && address.country && address.country.indexOf('USA') !== -1) || (address && address.country === 'Canada')) {
        return 18;
    }
    return 50;
    };

    const zipRegex = () => {
        if (address && address.country && address.country.indexOf('USA') !== -1) {
            return zipRegUs();
        }
        if (address.country === 'Canada') {
            return zipRegCnd();
        }
        return 'form-control';
    };
    const addressValidate = () => {
        if (address && address.address1 && address.address1.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const countryValidate = () => {
        if ((address && address.country && address.country.length > 1) && (address && address.country && address.country.indexOf('Select Country...') === -1)) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const phoneValidate = () => {
        const number = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        if (address && address.phone && address.phone.length > 1 && number.test(address.phone)) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const fullnameValidate = () => {
        if (address && address.fullName && address.fullName.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const cityValidate = () => {
        if (address && address.townCity && address.townCity.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const stateValidate = () => {
        if (address && address.stateCounty && address.stateCounty.length > 1) {
            return 'form-control';
        }
        return 'form-control is-invalid';
    };
    const checkValid = () => {
        if (address && address.country && address.country.indexOf('USA') !== -1) {
        if (addressValidate() === 'form-control' && countryValidate() === 'form-control' && phoneValidate() === 'form-control' && zipRegex() === 'form-control' && fullnameValidate() === 'form-control' && cityValidate() === 'form-control' && stateValidate() === 'form-control') {
            return false;
        }
        if (addressValidate() === 'form-control' && countryValidate() === 'form-control' && phoneValidate() === 'form-control' && zipRegex() === 'form-control' && fullnameValidate() === 'form-control') {
            return false;
        }
    }
        return true;
    };

    function keyChanged(key, e) {
        const newAddress = { ...address };
        newAddress[key] = e.target.value;
        setAddress(newAddress);
        checkValid();
    }

    const selectCountry = () => {
        if (program && program.geolocation.enabled) {
            const continentsFilter = locations.filter((data) => program && program.continents.includes(data.code));
            const filteredContinents = continentsFilter && continentsFilter.map((a) => a.countries);
            const allCountries = [];
        for (let i = 0; i < filteredContinents.length; i += 1) {
                for (let j = 0; j < filteredContinents[i].length; j += 1) {
                    allCountries.push(filteredContinents[i][j].title);
                }
            }
            return allCountries;
        }
        if (program && program.defaultContinent && program.defaultCountry) {
            const defaultContinents = program && program.defaultContinent && locations.filter((a) => a.code === program.defaultContinent);
            const defaultCountry = program && program.defaultCountry && defaultContinents.map((a) => a.countries.filter((b) => b.code === program.defaultCountry));
            return [defaultCountry[0][0].title];
        }
        return ['USA'];
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`New Address — ${program.fullName}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>New Address</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">
                        <React.Fragment>
                            <div className="form-group">
                                <label htmlFor="name">
                                    <FormattedMessage id="checkout_name" defaultMessage="Name Surname" />
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <FormattedMessage id="address_nameSurnamePlaceholder" defaultMessage="Name Surname">
                                    {(placeholder) => (
                                        <input
                                            type="text"
                                            className={fullnameValidate()}
                                            id="checkout-company-name"
                                            placeholder={placeholder}
                                            maxLength="50"
                                            disabled={isDisabled}
                                            onChange={(e) => keyChanged('fullName', e)}
                                        />
                                )}
                                </FormattedMessage>
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-company-name">
                                    <FormattedMessage id="checkout_company" defaultMessage="Company Name" />
                                    {' '}
                                    <span className="text-muted"><FormattedMessage id="checkout_mutedoptional" defaultMessage="(Optional)" /></span>
                                </label>
                                <FormattedMessage id="address_companyPlaceholder" defaultMessage="Company Name">
                                    {(placeholder) => (
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="checkout-company-name"
                                            placeholder={placeholder}
                                            maxLength="50"
                                            disabled={isDisabled}
                                            onChange={(e) => keyChanged('companyName', e)}
                                        />
                                )}
                                </FormattedMessage>
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-country">
                                    <FormattedMessage id="checkout_country" defaultMessage="Country" />
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <select id="checkout-country" className={countryValidate()} onChange={(e) => keyChanged('country', e)} value={address && address.country} disabled={isDisabled}>
                                    <option>Select Country...</option>
                                    {selectCountry().map((a) => <option>{a}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-street-address1">
                                    <FormattedMessage id="checkout_address1" defaultMessage="Address 1" />
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <FormattedMessage id="address_address1Placeholder" defaultMessage="Address">
                                    {(placeholder) => (
                                        <input
                                            type="text"
                                            className={addressValidate()}
                                            id="checkout-street-address 1"
                                            placeholder={placeholder}
                                            maxLength="50"
                                            disabled={isDisabled}
                                            onChange={(e) => keyChanged('address1', e)}
                                        />
                                    )}
                                </FormattedMessage>
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-street-address2">
                                    <FormattedMessage id="checkout_address2" defaultMessage="Address 2- optional" />
                                </label>
                                <FormattedMessage id="address_address2Placeholder" defaultMessage="You Can Continue Here if Necessary">
                                    {(placeholder) => (
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="checkout-street-address 2"
                                            placeholder={placeholder}
                                            maxLength="50"
                                            disabled={isDisabled}
                                            onChange={(e) => keyChanged('address2', e)}
                                        />
                                )}
                                </FormattedMessage>
                            </div>
                            {(address && address.country && address.country.indexOf('USA') === -1) ? (
                                <React.Fragment>
                                    <div className="form-group">
                                        <label htmlFor="checkout-street-address3">
                                            <FormattedMessage id="checkout_address3" defaultMessage="Address 3" />
                                        </label>
                                        <FormattedMessage id="address_address3Placeholder" defaultMessage="You Can Continue Here if Necessary">
                                            {(placeholder) => (
                                                <input
                                                    type="text"
                                                    className="form-control 3"
                                                    id="checkout-street-address"
                                                    placeholder={placeholder}
                                                    maxLength="50"
                                                    disabled={isDisabled}
                                                    onChange={(e) => keyChanged('address3', e)}
                                                />
                                        )}
                                        </FormattedMessage>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="checkout-street-address4">
                                            <FormattedMessage id="checkout_address4" defaultMessage="Address 4" />
                                        </label>
                                        <FormattedMessage id="address_address4Placeholder" defaultMessage="You Can Continue Here if Necessary">
                                            {(placeholder) => (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-street-address 4"
                                                    placeholder={placeholder}
                                                    maxLength="50"
                                                    disabled={isDisabled}
                                                    onChange={(e) => keyChanged('address4', e)}
                                                />
                                        )}
                                        </FormattedMessage>
                                    </div>
                                </React.Fragment>
) : ''}
                            {(address && address.country && address.country.indexOf('USA') !== -1) ? (
                                <React.Fragment>
                                    <div className="form-group">
                                        <label htmlFor="checkout-city">
                                            <FormattedMessage id="checkout_towncity" defaultMessage="Town / City" />
                                            <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <input type="text" className={cityValidate()} id="checkout-city" disabled={isDisabled} onChange={(e) => keyChanged('townCity', e)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="checkout-state">
                                            <FormattedMessage id="checkout_statecounty" defaultMessage="State / County" />
                                            <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <FormattedMessage id="address_statePlaceholder" defaultMessage="e.g. WY or QC">
                                            {(placeholder) => (
                                                <input type="text" placeholder={placeholder} maxLength="2" className={stateValidate()} id="checkout-state" disabled={isDisabled} onChange={(e) => keyChanged('stateCounty', e)} />
                                        )}
                                        </FormattedMessage>
                                    </div>
                                </React.Fragment>
) : ''}
                            {address && address.country === 'Canada' && (
                            <div className="form-group">
                                <label htmlFor="checkout-postcode">
                                    <FormattedMessage id="checkout_postcodezip" defaultMessage="Postcode / ZIP" />
                                </label>
                                <input type="text" className={zipRegCnd()} maxLength="7" id="checkout-postcode" disabled={isDisabled} onChange={(e) => keyChanged('postcode', e)} />
                            </div>
)}
                            {(address && address.country && address.country.indexOf('USA') !== -1) && (
                            <div className="form-group">
                                <label htmlFor="checkout-postcode">
                                    <FormattedMessage id="checkout_postcodezip" defaultMessage="Postcode / ZIP" />
                                </label>
                                <input type="text" className={zipRegUs()} maxLength="10" id="checkout-postcode" disabled={isDisabled} onChange={(e) => keyChanged('postcode', e)} />
                            </div>
)}

                            {(address && address.country && address.country.indexOf('USA') === -1) && address.country !== 'Canada' && (
                            <div className="form-group">
                                <label htmlFor="checkout-postcode">
                                    <FormattedMessage id="checkout_postcodezip" defaultMessage="Postcode / ZIP" />
                                </label>
                                <input type="text" className="form-control" id="checkout-postcode" maxLength="9" disabled={isDisabled} onChange={(e) => keyChanged('postcode', e)} />
                            </div>
)}
                            <div className="form-row" style={{ alignItems: 'flex-end'}}>
                                <div className="form-group col-md-9">
                                    <label htmlFor="checkout-email">
                                        <FormattedMessage id="checkout_phone" defaultMessage="Phone Number" />
                                        <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <FormattedMessage id="address_phonePlaceholder" defaultMessage="Please Enter Your Phone Number">
                                        {(placeholder) => (
                                            <input
                                                type="text"
                                                className={phoneValidate()}
                                                id="checkout-phone"
                                                placeholder={placeholder}
                                                onChange={(e) => keyChanged('phone', e)}
                                                disabled={isDisabled}
                                                maxLength={phoneLength()}
                                            />
                                    )}
                                    </FormattedMessage>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="checkout-phone">
                                        <FormattedMessage id="checkout_ext" defaultMessage="Ext" />
                                    </label>
                                    <FormattedMessage id="address_phoneExtPlaceholder" defaultMessage="Ext">
                                        {(placeholder) => (
                                            <input type="text" className="form-control" id="checkout-phone-ext" maxLength={5} placeholder={placeholder} disabled={isDisabled} onChange={(e) => keyChanged('phoneExt', e)} />
                                    )}
                                    </FormattedMessage>
                                </div>
                            </div>
                            <div className="form-row" style={{ alignItems: 'flex-end'}}>
                                <div className="form-group col-md-9">
                                    <label htmlFor="checkout-email">
                                        <FormattedMessage id="checkout_altphone" defaultMessage="Alternative Phone Number" />
                                    </label>
                                    <FormattedMessage id="address_altPhonePlaceholder" defaultMessage="Alternative Phone Number">
                                        {(placeholder) => (
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-alt-phone"
                                                placeholder={placeholder}
                                                disabled={isDisabled}
                                                onChange={(e) => keyChanged('alternativePhone', e)}
                                            />
                                    )}
                                    </FormattedMessage>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="Alternativecheckout-phone">
                                        <FormattedMessage id="checkout_ext" defaultMessage="Ext" />
                                    </label>
                                    <FormattedMessage id="address_altPhoneExtPlaceholder" defaultMessage="Ext">
                                        {(placeholder) => (
                                            <input type="text" className="form-control" id="checkout-alt-phone-ext" placeholder={placeholder} maxLength={5} disabled={isDisabled} onChange={(e) => keyChanged('alternativePhoneExt', e)} />
                                    )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </React.Fragment>

                        <div className="form-group mt-3 mb-0">
                            <button className="btn btn-primary" onClick={addAddress} disabled={checkValid()} type="button">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
