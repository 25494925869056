// react
import React, {
    useEffect, useState, useContext,
} from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import Sections from './Sections';
import ProgramContext from '../../contexts/ProgramContext';
import programApi from '../../server/real/endpoints/programs';

function SitePage(props) {
    const { locale, region, pageName } = props;
    const [page, setPage] = useState({ sections: [] });
    const { program } = useContext(ProgramContext);
    useEffect(() => {
        if (!program) return;
        const content = programApi.getContent(program, locale);
        if (!content) return;
        const { pages } = content;
        if (!pages) return;
        const homePageData = pages.filter((data) => data.url === pageName);
        if (homePageData.length === 0) return;
        setPage(homePageData[0]);
    }, [program, locale, region, pageName]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home — ${program.fullName}`}</title>
            </Helmet>

            <Sections sections={page.sections} />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    region: state.region,
});

export default connect(mapStateToProps)(SitePage);
