// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

// data stubs
import orderApi from '../../server/real/endpoints/orders';
import ProgramContext from '../../contexts/ProgramContext';
import UserContext from '../../contexts/UserContext';

export default class AccountPageTransactions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transactions: [],
        };
    }

    componentDidMount = () => {
        orderApi.getTransactions().then((transactionsData) => {
            this.setState({ transactions: transactionsData.data });
        });
    }

    render() {
        const { transactions } = this.state;

        const transactionsList = transactions.map((transaction) => (
            <tr key={transaction.id}>
                <td>
                    {new Date(transaction.createDate).toLocaleString('en-US', {
                    weekday: 'short', // long, short, narrow
                    day: 'numeric', // numeric, 2-digit
                    year: 'numeric', // numeric, 2-digit
                    month: 'long', // numeric, 2-digit, long, short, narrow
                })}
                </td>
                <td>{transaction.description}</td>
                <td>{transaction.amount}</td>
            </tr>
        ));

        return (
            <div className="card">
                <ProgramContext.Consumer>
                    {(context) => {
                        const { program } = context;
                        return (
                            <Helmet>
                                <title>{`Transaction History — ${program.fullName}`}</title>
                            </Helmet>
                        );
                    }}
                </ProgramContext.Consumer>

                <div className="card-header row d-flex justify-content-between">
                    <h5>Transaction History</h5>
                    <UserContext.Consumer>
                        {(context) => {
                        const { user } = context;
                        return (
                            <h5 className="hiddenbalance">
                                Balance:
                                {' '}
                                {user.balance}
                            </h5>
);
                    }}
                    </UserContext.Consumer>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="account_dashtransactiondate" defaultMessage="Date" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="account_dashtransactiondescription" defaultMessage="Description" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="account_dashtransactionamount" defaultMessage="Amount" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionsList}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
            </div>
        );
    }
}
