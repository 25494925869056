const locations = [
    {
        code: 'USA',
        title: 'USA',
        countries: [
            {
                code: 'USA-CONT',
                title: 'USA Continental',
            },
            {
                code: 'USA-HI',
                title: 'USA Hawai',
            },
            {
                code: 'USA-AK',
                title: 'USA Alaska',
            },
            {
                code: 'USA-VI',
                title: 'USA Virgin Islands',
            },
            {
                code: 'USA-PR',
                title: 'USA Puerto Rico',
            },
        ],
    },
    {
        code: 'NORTH_AMERICA',
        title: 'North America',
        countries: [
            {
                parent: 'North America',
                title: 'Bermuda',
                code: 'BMU',
                code2: 'BM',
            },
            {
                parent: 'North America',
                title: 'Canada',
                code: 'CAN',
                code2: 'CA',
            },
            {
                parent: 'North America',
                title: 'Mexico',
                code: 'MEX',
                code2: 'MX',
            },
        ],
    },
    {
        code: 'SOUTH_AMERICA',
        title: 'South America',
        countries: [
            {
                parent: 'South America',
                title: 'Anguilla',
                code: 'AIA',
                DZ: 'AI',
            },
            {
                parent: 'South America',
                title: 'Antigua And Barbuda',
                code: 'ATG',
                DZ: 'AG',
            },
            {
                parent: 'South America',
                title: 'Argentina',
                code: 'ARG',
                DZ: 'AR',
            },
            {
                parent: 'South America',
                title: 'Bahamas',
                code: 'BHS',
                DZ: 'BS',
            },
            {
                parent: 'South America',
                title: 'Barbados',
                code: 'BRB',
                DZ: 'BB',
            },
            {
                parent: 'South America',
                title: 'Belize',
                code: 'BLZ',
                DZ: 'BZ',
            },
            {
                parent: 'South America',
                title: 'Bolivia',
                code: 'BOL',
                DZ: 'BO',
            },
            {
                parent: 'South America',
                title: 'Bonaire (Netherlands Antilles)',
                code: 'BES',
                DZ: 'BQ',
            },
            {
                parent: 'South America',
                title: 'Brazil',
                code: 'BRA',
                DZ: 'BR',
            },
            {
                parent: 'South America',
                title: 'British Virgin Islands',
                code: 'VGB',
                DZ: 'VG',
            },
            {
                parent: 'South America',
                title: 'Cayman Islands',
                code: 'CYM',
                DZ: 'KY',
            },
            {
                parent: 'South America',
                title: 'Chile',
                code: 'CHL',
                DZ: 'CL',
            },
            {
                parent: 'South America',
                title: 'Colombia',
                code: 'COL',
                DZ: 'CO',
            },
            {
                parent: 'South America',
                title: 'Costa Rica',
                code: 'CRI',
                DZ: 'CR',
            },
            {
                parent: 'South America',
                title: 'Curaçao (Netherlands Antilles)',
                code: 'CUW',
                DZ: 'CW',
            },
            {
                parent: 'South America',
                title: 'Dominica',
                code: 'DMA',
                DZ: 'DM',
            },
            {
                parent: 'South America',
                title: 'Dominican Republic',
                code: 'DOM',
                DZ: 'DO',
            },
            {
                parent: 'South America',
                title: 'Ecuador',
                code: 'ECU',
                DZ: 'EC',
            },
            {
                parent: 'South America',
                title: 'El Salvador',
                code: 'SLV',
                DZ: 'SV',
            },
            {
                parent: 'South America',
                title: 'French Guiana',
                code: 'GUF',
                DZ: 'GF',
            },
            {
                parent: 'South America',
                title: 'Grenada',
                code: 'GRD',
                DZ: 'GD',
            },
            {
                parent: 'South America',
                title: 'Guadeloupe',
                code: 'GLP',
                DZ: 'GP',
            },
            {
                parent: 'South America',
                title: 'Guatemala',
                code: 'GTM',
                DZ: 'GT',
            },
            {
                parent: 'South America',
                title: 'Guyana',
                code: 'GUY',
                DZ: 'GY',
            },
            {
                parent: 'South America',
                title: 'Haiti',
                code: 'HTI',
                DZ: 'HT',
            },
            {
                parent: 'South America',
                title: 'Honduras',
                code: 'HND',
                DZ: 'HN',
            },
            {
                parent: 'South America',
                title: 'Jamaica',
                code: 'JAM',
                DZ: 'JM',
            },
            {
                parent: 'South America',
                title: 'Martinique',
                code: 'MTQ',
                DZ: 'MQ',
            },
            {
                parent: 'South America',
                title: 'Montserrat',
                code: 'MSR',
                DZ: 'MS',
            },
            {
                parent: 'South America',
                title: 'Netherlands Antilles',
                code: 'ANT',
                DZ: 'AN',
            },
            {
                parent: 'South America',
                title: 'Nevis (St. Kitts)',
                code: 'KNA',
                DZ: 'KN',
            },
            {
                parent: 'South America',
                title: 'Nicaragua',
                code: 'NIC',
                DZ: 'NI',
            },
            {
                parent: 'South America',
                title: 'Panama',
                code: 'PAN',
                DZ: 'PA',
            },
            {
                parent: 'South America',
                title: 'Paraguay',
                code: 'PRY',
                DZ: 'PY',
            },
            {
                parent: 'South America',
                title: 'Peru',
                code: 'PER',
                DZ: 'PE',
            },
            {
                parent: 'South America',
                title: 'Saba (Netherlands Antilles)',
                code: 'ANT',
                DZ: 'AN',
            },
            {
                parent: 'South America',
                title: 'St. Barthelemy',
                code: 'BLM',
                DZ: 'BL',
            },
            {
                parent: 'South America',
                title: 'St. Christopher (St. Kitts)',
                code: 'KNA',
                DZ: 'KN',
            },
            {
                parent: 'South America',
                title: 'St. Croix (Us Virgin Islands)',
                code: 'VIR',
                DZ: 'VI',
            },
            {
                parent: 'South America',
                title: 'St. Eustatius (Netherlands Antilles)',
                code: 'ANT',
                DZ: 'AN',
            },
            {
                parent: 'South America',
                title: 'St. John (Us Virgin Islands)',
                code: 'VIR',
                DZ: 'VI',
            },
            {
                parent: 'South America',
                title: 'St. Lucia',
                code: 'LCA',
                DZ: 'LC',
            },
            {
                parent: 'South America',
                title: 'St. Maarten (Netherlands Antilles)',
                code: 'ANT',
                DZ: 'AN',
            },
            {
                parent: 'South America',
                title: 'St. Thomas (Us Virgin Islands)',
                code: 'VIR',
                DZ: 'VI',
            },
            {
                parent: 'South America',
                title: 'St. Vincent And The Grenadines',
                code: 'VCT',
                DZ: 'VC',
            },
            {
                parent: 'South America',
                title: 'Tortola (British Virgin Islands)',
                code: 'VGB',
                DZ: 'VG',
            },
            {
                parent: 'South America',
                title: 'Trinidad And Tobago',
                code: 'TTO',
                DZ: 'TT',
            },
            {
                parent: 'South America',
                title: 'Turks And Caicos Islands',
                code: 'TCA',
                DZ: 'TC',
            },
            {
                parent: 'South America',
                title: 'Union Island (St. Vincent And The Grenadines)',
                code: 'VCT',
                DZ: 'VC',
            },
            {
                parent: 'South America',
                title: 'Uruguay',
                code: 'URY',
                DZ: 'UY',
            },
            {
                parent: 'South America',
                title: 'Venezuela',
                code: 'VEN',
                DZ: 'VE',
            },
            {
                parent: 'South America',
                title: 'Virgin Gorda (British Virgin Islands)',
                code: 'VGB',
                DZ: 'VG',
            },
            {
                parent: 'South America',
                title: 'Virgin Islands (Us)',
                code: 'VIR',
                DZ: 'VI',
            },
        ],
    },
    {
        code: 'AFRICA',
        title: 'Africa',
        countries: [
            {
                parent: 'Africa',
                title: 'Angola',
                code: 'AGO',
                DZ: 'AO',
            },
            {
                parent: 'Africa',
                title: 'Benin',
                code: 'BEN',
                DZ: 'BJ',
            },
            {
                parent: 'Africa',
                title: 'Botswana',
                code: 'BWA',
                DZ: 'BW',
            },
            {
                parent: 'Africa',
                title: 'Burkina Faso',
                code: 'BFA',
                DZ: 'BF',
            },
            {
                parent: 'Africa',
                title: 'Burundi',
                code: 'BDI',
                DZ: 'BI',
            },
            {
                parent: 'Africa',
                title: "Ca�Te D'Ivoire",
                code: 'CIV',
                DZ: 'CI',
            },
            {
                parent: 'Africa',
                title: 'Cameroon',
                code: 'CMR',
                DZ: 'CM',
            },
            {
                parent: 'Africa',
                title: 'Cape Verde',
                code: 'CPV',
                DZ: 'CV',
            },
            {
                parent: 'Africa',
                title: 'Central African Republic',
                code: 'CAF',
                DZ: 'CF',
            },
            {
                parent: 'Africa',
                title: 'Chad',
                code: 'TCD',
                DZ: 'TD',
            },
            {
                parent: 'Africa',
                title: 'Comoros',
                code: 'COM',
                DZ: 'KM',
            },
            {
                parent: 'Africa',
                title: 'Democratic Republic Of The Congo',
                code: 'COG',
                DZ: 'CG',
            },
            {
                parent: 'Africa',
                title: 'Djibouti',
                code: 'DJI',
                DZ: 'DJ',
            },
            {
                parent: 'Africa',
                title: 'Egypt',
                code: 'EGY',
                DZ: 'EG',
            },
            {
                parent: 'Africa',
                title: 'Equatorial Guinea',
                code: 'GNQ',
                DZ: 'GQ',
            },
            {
                parent: 'Africa',
                title: 'Eritrea',
                code: 'ERI',
                DZ: 'ER',
            },
            {
                parent: 'Africa',
                title: 'Gabon',
                code: 'GAB',
                DZ: 'GA',
            },
            {
                parent: 'Africa',
                title: 'Gambia',
                code: 'GMB',
                DZ: 'GM',
            },
            {
                parent: 'Africa',
                title: 'Ghana',
                code: 'GHA',
                DZ: 'GH',
            },
            {
                parent: 'Africa',
                title: 'Guinea',
                code: 'GIN',
                DZ: 'GN',
            },
            {
                parent: 'Africa',
                title: 'Guinea-Bissau',
                code: 'GNB',
                DZ: 'GW',
            },
            {
                parent: 'Africa',
                title: 'Kenya',
                code: 'KEN',
                DZ: 'KE',
            },
            {
                parent: 'Africa',
                title: 'Lesotho',
                code: 'LSO',
                DZ: 'LS',
            },
            {
                parent: 'Africa',
                title: 'Liberia',
                code: 'LBR',
                DZ: 'LR',
            },
            {
                parent: 'Africa',
                title: 'Libyan Arab Jamahiriya',
                code: 'LBY',
                DZ: 'LY',
            },
            {
                parent: 'Africa',
                title: 'Madagascar',
                code: 'MDG',
                DZ: 'MG',
            },
            {
                parent: 'Africa',
                title: 'Malawi',
                code: 'MWI',
                DZ: 'MW',
            },
            {
                parent: 'Africa',
                title: 'Mali',
                code: 'MLI',
                DZ: 'ML',
            },
            {
                parent: 'Africa',
                title: 'Mauritania',
                code: 'MRT',
                DZ: 'MR',
            },
            {
                parent: 'Africa',
                title: 'Mayotte',
                code: 'MYT',
                DZ: 'YT',
            },
            {
                parent: 'Africa',
                title: 'Morocco',
                code: 'MAR',
                DZ: 'MA',
            },
            {
                parent: 'Africa',
                title: 'Mozambique',
                code: 'MOZ',
                DZ: 'MZ',
            },
            {
                parent: 'Africa',
                title: 'Namibia',
                code: 'NAM',
                DZ: 'NA',
            },
            {
                parent: 'Africa',
                title: 'Niger',
                code: 'NER',
                DZ: 'NE',
            },
            {
                parent: 'Africa',
                title: 'Nigeria',
                code: 'NGA',
                DZ: 'NG',
            },
            {
                parent: 'Africa',
                title: 'Reunion',
                code: 'REU',
                DZ: 'RE',
            },
            {
                parent: 'Africa',
                title: 'Rwanda',
                code: 'RWA',
                DZ: 'RW',
            },
            {
                parent: 'Africa',
                title: 'Senegal',
                code: 'SEN',
                DZ: 'SN',
            },
            {
                parent: 'Africa',
                title: 'Seychelles',
                code: 'SYC',
                DZ: 'SC',
            },
            {
                parent: 'Africa',
                title: 'Sierra Leone',
                code: 'SLE',
                DZ: 'SL',
            },
            {
                parent: 'Africa',
                title: 'South Africa',
                code: 'ZAF',
                DZ: 'ZA',
            },
            {
                parent: 'Africa',
                title: 'Suriname',
                code: 'SUR',
                DZ: 'SR',
            },
            {
                parent: 'Africa',
                title: 'Swaziland',
                code: 'SWZ',
                DZ: 'SZ',
            },
            {
                parent: 'Africa',
                title: 'Tanzania, United Republic Of',
                code: 'TZA',
                DZ: 'TZ',
            },
            {
                parent: 'Africa',
                title: 'Tunisia',
                code: 'TUN',
                DZ: 'TN',
            },
            {
                parent: 'Africa',
                title: 'Uganda',
                code: 'UGA',
                DZ: 'UG',
            },
            {
                parent: 'Africa',
                title: 'Zambia',
                code: 'ZMB',
                DZ: 'ZM',
            },
            {
                parent: 'Africa',
                title: 'Zimbabwe',
                code: 'ZWE',
                DZ: 'ZW',
            },
        ],
    },
    {
        code: 'ASIA',
        title: 'Asia',
        countries: [
            {
                parent: 'Asia',
                title: 'Cambodia',
                code: 'KHM',
                DZ: 'KH',
            },
            {
                parent: 'Asia',
                title: 'China',
                code: 'CHN',
                DZ: 'CN',
            },
            {
                parent: 'Asia',
                title: 'East Timor',
                code: 'TMP',
                DZ: 'TL',
            },
            {
                parent: 'Asia',
                title: 'Guam',
                code: 'GUM',
                DZ: 'GU',
            },
            {
                parent: 'Asia',
                title: 'Hong Kong',
                code: 'HKG',
                DZ: 'HK',
            },
            {
                parent: 'Asia',
                title: 'Japan',
                code: 'JPN',
                DZ: 'JP',
            },
            {
                parent: 'Asia',
                title: 'Korea',
                code: 'KOR',
                DZ: 'KR',
            },
            {
                parent: 'Asia',
                title: "Lao People'S Democratic Republic",
                code: 'LAO',
                DZ: 'LA',
            },
            {
                parent: 'Asia',
                title: 'Macau',
                code: 'MAC',
                DZ: 'MO',
            },
            {
                parent: 'Asia',
                title: 'Malaysia',
                code: 'MYS',
                DZ: 'MY',
            },
            {
                parent: 'Asia',
                title: 'Mongolia',
                code: 'MNG',
                DZ: 'MN',
            },
            {
                parent: 'Asia',
                title: 'Myanmar (Burma)',
                code: 'MMR',
                DZ: 'MM',
            },
            {
                parent: 'Asia',
                title: 'Papua New Guinea',
                code: 'PNG',
                DZ: 'PG',
            },
            {
                parent: 'Asia',
                title: 'Philippines',
                code: 'PHL',
                DZ: 'PH',
            },
            {
                parent: 'Asia',
                title: 'Singapore',
                code: 'SGP',
                DZ: 'SG',
            },
            {
                parent: 'Asia',
                title: 'Taiwan',
                code: 'TWN',
                DZ: 'TW',
            },
            {
                parent: 'Asia',
                title: 'Thailand',
                code: 'THA',
                DZ: 'TH',
            },
            {
                parent: 'Asia',
                title: 'Vietnam',
                code: 'VNM',
                DZ: 'VN',
            },
        ],
    },
    {
        code: 'AUSTRALASIA',
        title: 'Australasia',
        countries: [
            {
                parent: 'Australasia',
                title: 'American Samoa',
                code: 'ASM',
                DZ: 'AS',
            },
            {
                parent: 'Australasia',
                title: 'Australia',
                code: 'AUS',
                DZ: 'AU',
            },
            {
                parent: 'Australasia',
                title: 'Cook Islands',
                code: 'COK',
                DZ: 'CK',
            },
            {
                parent: 'Australasia',
                title: 'Fiji',
                code: 'FJI',
                DZ: 'FJ',
            },
            {
                parent: 'Australasia',
                title: 'French Polynesia',
                code: 'PYF',
                DZ: 'PF',
            },
            {
                parent: 'Australasia',
                title: 'Kiribati',
                code: 'KIR',
                DZ: 'KI',
            },
            {
                parent: 'Australasia',
                title: 'Kosrae (Micronesia, Federated States Of)',
                code: 'FSM',
                DZ: 'FM',
            },
            {
                parent: 'Australasia',
                title: 'Marshall Islands',
                code: 'MHL',
                DZ: 'MH',
            },
            {
                parent: 'Australasia',
                title: 'Micronesia (Federated States Of)',
                code: 'FSM',
                DZ: 'FM',
            },
            {
                parent: 'Australasia',
                title: 'New Caledonia',
                code: 'NCL',
                DZ: 'NC',
            },
            {
                parent: 'Australasia',
                title: 'New Zealand',
                code: 'NZL',
                DZ: 'NZ',
            },
            {
                parent: 'Australasia',
                title: 'Norfolk Island',
                code: 'NFK',
                DZ: 'NF',
            },
            {
                parent: 'Australasia',
                title: 'Northern Mariana Islands',
                code: 'MNP',
                DZ: 'MP',
            },
            {
                parent: 'Australasia',
                title: 'Palau',
                code: 'PLW',
                DZ: 'PW',
            },
            {
                parent: 'Australasia',
                title: 'Ponape (Micronesia, Federated States Of)',
                code: 'FSM',
                DZ: 'FM',
            },
            {
                parent: 'Australasia',
                title: 'Rota (Northern Mariana Islands)',
                code: 'MNP',
                DZ: 'MP',
            },
            {
                parent: 'Australasia',
                title: 'Saipan (Northern Mariana Islands)',
                code: 'MNP',
                DZ: 'MP',
            },
            {
                parent: 'Australasia',
                title: 'Solomon Islands',
                code: 'SLB',
                DZ: 'SB',
            },
            {
                parent: 'Australasia',
                title: 'Tahiti',
                code: 'PYF',
                DZ: 'PF',
            },
            {
                parent: 'Australasia',
                title: 'Timor-Leste',
                code: 'TLS',
                DZ: 'TL',
            },
            {
                parent: 'Australasia',
                title: 'Tinian (Northern Mariana Islands)',
                code: 'MNP',
                DZ: 'MP',
            },
            {
                parent: 'Australasia',
                title: 'Togo',
                code: 'TGO',
                DZ: 'TG',
            },
            {
                parent: 'Australasia',
                title: 'Tonga',
                code: 'TON',
                DZ: 'TO',
            },
            {
                parent: 'Australasia',
                title: 'Truk (Micronesia, Federated States Of)',
                code: 'FSM',
                DZ: 'FM',
            },
            {
                parent: 'Australasia',
                title: 'Tuvalu',
                code: 'TUV',
                DZ: 'TV',
            },
            {
                parent: 'Australasia',
                title: 'Vanuatu',
                code: 'VUT',
                DZ: 'VU',
            },
            {
                parent: 'Australasia',
                title: 'Wallis And Futuna Islands',
                code: 'WLF',
                DZ: 'WF',
            },
            {
                parent: 'Australasia',
                title: 'Western Samoa',
                code: 'WSM',
                DZ: 'WS',
            },
            {
                parent: 'Australasia',
                title: 'Yap (Micronesia, Federated States Of)',
                code: 'FSM',
                DZ: 'FM',
            },
        ],
    },
    {
        code: 'EUROPEAN_UNION',
        title: 'European Union',
        countries: [
            {
                parent: 'European Union',
                title: 'Aland Islands',
                code: 'ALA',
                DZ: 'AX',
            },
            {
                parent: 'European Union',
                title: 'Aruba',
                code: 'ABW',
                DZ: 'AW',
            },
            {
                parent: 'European Union',
                title: 'Austria',
                code: 'AUT',
                DZ: 'AT',
            },
            {
                parent: 'European Union',
                title: 'Azores',
                code: 'PRT',
                DZ: 'PT',
            },
            {
                parent: 'European Union',
                title: 'Belgium',
                code: 'BEL',
                DZ: 'BE',
            },
            {
                parent: 'European Union',
                title: 'Bulgaria',
                code: 'BGR',
                DZ: 'BG',
            },
            {
                parent: 'European Union',
                title: 'Canary Islands',
                code: 'CNR',
                DZ: 'GC',
            },
            {
                parent: 'European Union',
                title: 'Ceuta',
                code: 'ESP',
                DZ: 'ES',
            },
            {
                parent: 'European Union',
                title: 'Channel Islands (Guernsey)',
                code: 'GGY',
                DZ: 'GG',
            },
            {
                parent: 'European Union',
                title: 'Channel Islands (Jersey)',
                code: 'JEY',
                DZ: 'JE',
            },
            {
                parent: 'European Union',
                title: 'Croatia',
                code: 'HRV',
                DZ: 'HR',
            },
            {
                parent: 'European Union',
                title: 'Cyprus',
                code: 'CYP',
                DZ: 'CY',
            },
            {
                parent: 'European Union',
                title: 'Czech Republic',
                code: 'CZE',
                DZ: 'CZ',
            },
            {
                parent: 'European Union',
                title: 'Denmark',
                code: 'DNK',
                DZ: 'DK',
            },
            {
                parent: 'European Union',
                title: 'Finland',
                code: 'FIN',
                DZ: 'FI',
            },
            {
                parent: 'European Union',
                title: 'France',
                code: 'FRA',
                DZ: 'FR',
            },
            {
                parent: 'European Union',
                title: 'Germany',
                code: 'DEU',
                DZ: 'DE',
            },
            {
                parent: 'European Union',
                title: 'Gibraltar',
                code: 'GIB',
                DZ: 'GI',
            },
            {
                parent: 'European Union',
                title: 'Greece',
                code: 'GRC',
                DZ: 'GR',
            },
            {
                parent: 'European Union',
                title: 'Holy See (Vatican City State)',
                code: 'VAT',
                DZ: 'VA',
            },
            {
                parent: 'European Union',
                title: 'Hungary',
                code: 'HUN',
                DZ: 'HU',
            },
            {
                parent: 'European Union',
                title: 'Ireland, Republic Of',
                code: 'IRL',
                DZ: 'IE',
            },
            {
                parent: 'European Union',
                title: 'Italy',
                code: 'ITA',
                DZ: 'IT',
            },
            {
                parent: 'European Union',
                title: 'Latvia',
                code: 'LVA',
                DZ: 'LV',
            },
            {
                parent: 'European Union',
                title: 'Lithuania',
                code: 'LTU',
                DZ: 'LT',
            },
            {
                parent: 'European Union',
                title: 'Luxembourg',
                code: 'LUX',
                DZ: 'LU',
            },
            {
                parent: 'European Union',
                title: 'Madeira',
                code: 'PRT',
                DZ: 'PT',
            },
            {
                parent: 'European Union',
                title: 'Malta',
                code: 'MLT',
                DZ: 'MT',
            },
            {
                parent: 'European Union',
                title: 'Melilla',
                code: 'ESP',
                DZ: 'ES',
            },
            {
                parent: 'European Union',
                title: 'Monaco',
                code: 'MCO',
                DZ: 'MC',
            },
            {
                parent: 'European Union',
                title: 'Netherlands, The (Holland)',
                code: 'NLD',
                DZ: 'NL',
            },
            {
                parent: 'European Union',
                title: 'Poland',
                code: 'POL',
                DZ: 'PL',
            },
            {
                parent: 'European Union',
                title: 'Portugal',
                code: 'PRT',
                DZ: 'PT',
            },
            {
                parent: 'European Union',
                title: 'Romania',
                code: 'ROU',
                DZ: 'RO',
            },
            {
                parent: 'European Union',
                title: 'Slovakia',
                code: 'SVK',
                DZ: 'SK',
            },
            {
                parent: 'European Union',
                title: 'Slovenia',
                code: 'SVN',
                DZ: 'SI',
            },
            {
                parent: 'European Union',
                title: 'Spain',
                code: 'ESP',
                DZ: 'ES',
            },
            {
                parent: 'European Union',
                title: 'Sweden',
                code: 'SWE',
                DZ: 'SE',
            },
        ],
    },
    {
        code: 'NON_EU_EUROPE',
        title: 'Non EU Countries - Europe',
        countries: [
            {
                parent: 'Europe - Non EU',
                title: 'Albania',
                code: 'ALB',
                DZ: 'AL',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Andorra',
                code: 'AND',
                DZ: 'AD',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Armenia',
                code: 'ARM',
                DZ: 'AM',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Belarus (Byelorussia)',
                code: 'BLR',
                DZ: 'BY',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Bosnia-Herzegovina',
                code: 'BIH',
                DZ: 'BA',
            },
            {
                parent: 'Europe - Non EU',
                title: 'England',
                code: 'GBR',
                DZ: 'GB',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Estonia',
                code: 'EST',
                DZ: 'EE',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Faroe Islands',
                code: 'FRO',
                DZ: 'FO',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Georgia',
                code: 'GEO',
                DZ: 'GE',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Greenland',
                code: 'GRL',
                DZ: 'GL',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Iceland',
                code: 'ISL',
                DZ: 'IS',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Isle Of Man',
                code: 'IMN',
                DZ: 'IM',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Kosovo',
                code: 'SRB',
                DZ: 'RS',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Liechtenstein',
                code: 'LIE',
                DZ: 'LI',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Moldova',
                code: 'MDA',
                DZ: 'MD',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Montenegro',
                code: 'MNE',
                DZ: 'ME',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Northern Ireland',
                code: 'GBR',
                DZ: 'GB',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Norway',
                code: 'NOR',
                DZ: 'NO',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Russia',
                code: 'RUS',
                DZ: 'RU',
            },
            {
                parent: 'Europe - Non EU',
                title: 'San Marino',
                code: 'SMR',
                DZ: 'SM',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Scotland',
                code: 'GBR',
                DZ: 'GB',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Serbia And Montenegro',
                code: 'SRB',
                DZ: 'RS',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Switzerland (Express Plus Available For Ups Express Envelopes And Documents Only)',
                code: 'CHE',
                DZ: 'CH',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Turkey',
                code: 'TUR',
                DZ: 'TR',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Ukraine',
                code: 'UKR',
                DZ: 'UA',
            },
            {
                parent: 'Europe - Non EU',
                title: 'Wales',
                code: 'GBR',
                DZ: 'GB',
            },
        ],
    },
    {
        code: 'INDIAN_SUB_CONTINENT',
        title: 'Indian Sub Continent',
        countries: [
            {
                parent: 'Indian Sub Continent',
                title: 'Afghanistan',
                code: 'AFG',
                DZ: 'AF',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Bangladesh',
                code: 'BGD',
                DZ: 'BD',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Bhutan',
                code: 'BTN',
                DZ: 'BT',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Brunei',
                code: 'BRN',
                DZ: 'BN',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'India',
                code: 'IND',
                DZ: 'IN',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Indonesia',
                code: 'IDN',
                DZ: 'ID',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Kyrgyzstan',
                code: 'KGZ',
                DZ: 'KG',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Macedonia (Fyrom)',
                code: 'MKD',
                DZ: 'MK',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Maldives',
                code: 'MDV',
                DZ: 'MV',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Mauritius',
                code: 'MUS',
                DZ: 'MU',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Nepal',
                code: 'NPL',
                DZ: 'NP',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Pakistan',
                code: 'PAK',
                DZ: 'PK',
            },
            {
                parent: 'Indian Sub Continent',
                title: 'Sri Lanka',
                code: 'LKA',
                DZ: 'LK',
            },
        ],
    },
    {
        code: 'MIDDLE_EAST',
        title: 'Middle East',
        countries: [
            {
                parent: 'Middle East',
                title: 'Azerbaijan',
                code: 'AZE',
                DZ: 'AZ',
            },
            {
                parent: 'Middle East',
                title: 'Bahrain',
                code: 'BHR',
                DZ: 'BH',
            },
            {
                parent: 'Middle East',
                title: 'Iraq',
                code: 'IRQ',
                DZ: 'IQ',
            },
            {
                parent: 'Middle East',
                title: 'Israel',
                code: 'ISR',
                DZ: 'IL',
            },
            {
                parent: 'Middle East',
                title: 'Jordan',
                code: 'JOR',
                DZ: 'JO',
            },
            {
                parent: 'Middle East',
                title: 'Kazakhstan',
                code: 'KAZ',
                DZ: 'KZ',
            },
            {
                parent: 'Middle East',
                title: 'Kuwait',
                code: 'KWT',
                DZ: 'KW',
            },
            {
                parent: 'Middle East',
                title: 'Lebanon',
                code: 'LBN',
                DZ: 'LB',
            },
            {
                parent: 'Middle East',
                title: 'Oman',
                code: 'OMN',
                DZ: 'OM',
            },
            {
                parent: 'Middle East',
                title: 'Qatar',
                code: 'QAT',
                DZ: 'QA',
            },
            {
                parent: 'Middle East',
                title: 'Saudi Arabia',
                code: 'SAU',
                DZ: 'SA',
            },
            {
                parent: 'Middle East',
                title: 'Tajikistan',
                code: 'TJK',
                DZ: 'TJ',
            },
            {
                parent: 'Middle East',
                title: 'Turkmenistan',
                code: 'TKM',
                DZ: 'TM',
            },
            {
                parent: 'Middle East',
                title: 'United Arab Emirates',
                code: 'ARE',
                DZ: 'AE',
            },
            {
                parent: 'Middle East',
                title: 'Uzbekistan',
                code: 'UZB',
                DZ: 'UZ',
            },
            {
                parent: 'Middle East',
                title: 'Yemen',
                code: 'YEM',
                DZ: 'YE',
            },
        ],
    },
];

export default locations;
