import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import localeReducer from './locale';
import regionReducer from './region';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import version from './version';

export default combineReducers({
    version: (state = version) => state,
    cart: cartReducer,
    locale: localeReducer,
    region: regionReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
});
