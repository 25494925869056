import getApi from './api';
import { GetAPIEndpoint } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`;
    return { Authorization };
}
const userApi = {
    getUser: () => getApi().get(`${GetAPIEndpoint()}/api/self`, { headers: getHeaders() }),
    getDefaultAddress: (user) => {
        if (!user || !user.addresses || user.addresses.length === 0) {
            return {
                id: 0,
                default: false,
                fullName: '',
                email: '',
                phone: '',
                country: '',
                townCity: '',
                city: '',
                stateCounty: '',
                postcode: '',
                address1: '',
                address2: '',
                address3: '',
                address4: '',
                companyName: '',
            };
        }
        const addresses = user.addresses.filter((data) => data.default);
        if (addresses.length > 0) return addresses[0];
        return user.addresses[0];
    },
    getAddress: (user, id) => {
        if (!user || !user.addresses || user.addresses.length === 0) {
            return {
                id: 0,
                default: false,
                fullName: '',
                email: '',
                phone: '',
                country: '',
                townCity: '',
                city: '',
                stateCounty: '',
                postcode: '',
                address1: '',
                address2: '',
                address3: '',
                address4: '',
                companyName: '',
            };
        }
        const addresses = user.addresses.filter((data) => data.id.toString() === id.toString());
        if (addresses.length > 0) return addresses[0];
        return {
            id: 0,
            default: false,
            fullName: '',
            email: '',
            phone: '',
            country: '',
            townCity: '',
            city: '',
            stateCounty: '',
            postcode: '',
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            companyName: '',
        };
    },
    updateAddress: (userid, addressId, address) => getApi().post(`${GetAPIEndpoint()}/api/users/${userid}/addresses/${addressId}`, address, { headers: getHeaders() }),
    addAddress: (userId, address) => getApi().post(`${GetAPIEndpoint()}/api/users/${userId}/addresses`, address, { headers: getHeaders() }),
    deleteAddress: (userid, addressId) => getApi().delete(`${GetAPIEndpoint()}/api/users/${userid}/addresses/${addressId}`, { headers: getHeaders() }),
    updateUser: (userid, user) => getApi().post(`${GetAPIEndpoint()}/api/users/${userid}`, user, { headers: getHeaders() }),
    changePassword: (currentPassword, newPassword) => getApi().post(`${GetAPIEndpoint()}/api/users/changepassword`, { currentPassword, newPassword }, { headers: getHeaders() }),
    forgotPassword: (email) => {
        const data = { email };
        return getApi().post('/api/users/forgotpassword', data);
    },
    resetPassword: (email, password, token) => {
        const data = { email, password, token };
        return getApi().post('/api/users/resetpassword', data);
    },
};

export default userApi;
