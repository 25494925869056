import React from 'react';
import {
    Modal, ModalHeader, ModalBody, Button, ModalFooter,
} from 'reactstrap';

const PasswordModal = (props) => {
    const {
        className, modal,
    } = props;

    const centeredmodal = true;

    return (
        <React.Fragment>
            <Modal isOpen={modal} className={className} centered={centeredmodal}>
                <ModalHeader>Congratulations</ModalHeader>
                <ModalBody>
                    The password change was succes. You will be redirect to homepage.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => { window.location = '/'; }}
                    >
                        Go homepage
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};
export default PasswordModal;
