// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import {
    Redirect, Route, Switch, useHistory,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

// application
import HomePage from './home/HomePage';
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';
import PrivateRoute from './PrivateRoute';

// pages
import BlockLoader from './blocks/BlockLoader';
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import AccountPageLogout from './account/AccountPageLogout';
import PageCart from './shop/ShopPageCart';
import PageCheckout from './shop/ShopPageCheckout';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageOrderSummary from './shop/ShopPageOrderSummary';
import ShopPageProduct from './shop/ShopPageProduct';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageNotFound from './site/SitePageNotFound';
import TermsAndConditions from './site/SiteTermsConditions';
import RebuildingLayout from './RebuildingLayout';

import ProgramContext from '../contexts/ProgramContext';
import CategoriesContext from '../contexts/CategoriesContext';
import UserContext from '../contexts/UserContext';
import UsedAccountLayout from './UsedAccountLayout';

// data stubs
import programApi from '../server/real/endpoints/programs';
import categoryApi from '../server/real/endpoints/categories';
import userApi from '../server/real/endpoints/users';
import languageApi from '../server/real/endpoints/languages';
import SitePage from './home/SitePage';
import auth from '../server/real/endpoints/auth';

function Layout(props) {
    const {
        match, headerLayout, locale, location, cart,
    } = props;
    const [program, setProgram] = useState();
    const [refreshUser, setRefreshUser] = useState();
    const [categories, setCategories] = useState([]);
    const [categoryLanguages, setCategoryLanguages] = useState();
    const [languages, setLanguages] = useState();
    const [isLoadingProgram, setisLoadingProgram] = useState(true);
    const [isLoadingLanguages, setisLoadingLanguages] = useState(true);
    const [user, setUser] = useState();
    const [determineLangList, setDetermineLangList] = useState([]);
    const history = useHistory();
    const orderSummary = JSON.parse(localStorage.getItem('summary_data'));

    useEffect(() => {
        const preloader = document.querySelector('.site-preloader');
        if (preloader) {
            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity' && preloader.parentNode) {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }

        return () => {
        };
    }, [program, languages, user]);

    useEffect(() => {
        let canceled = false;

        programApi.getProgram().then((programData) => {
            if (!programData.data) {
                history.push('/site/not-found');
            }
            if (canceled) {
                return;
            }
            setProgram(programData.data);
            setisLoadingProgram(false);
        });

        return () => {
            canceled = true;
        };
    }, []);


    useEffect(() => {
        let canceled = false;

        categoryApi.getCategories().then((categoriesData) => {
            if (canceled) {
                return;
            }
            setCategories(categoriesData);

        });

        categoryApi.getLangCategories().then((langData) => {
            if (canceled) {
                return;
            }
            setCategoryLanguages(langData);

        });

        return () => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        const languageList = [{originalCategoryName: 'Home', de: 'Heim', es: 'Hogar', fr: 'Maison', it: 'Casa', nl: 'Thuis', pt: 'Lar'},
        {originalCategoryName: 'Checkout', de: 'Kasse', es: 'Verificar', fr: 'Vérifier', it: 'Carrello', nl: 'Uitchecken', pt: 'Confira'},
        {originalCategoryName: 'Shopping Cart', de: 'Einkaufswagen', es: 'Carro de la compra', fr: 'Chariot', it: 'Carrello della spesa', nl: 'Winkelwagen', pt: 'Carrinho de compras'},
        {originalCategoryName: 'Shop', de: 'Geschäft', es: 'Comercio', fr: 'Boutique', it: 'Negozio', nl: 'Winkel', pt: 'Comprar'}]
         if (categoryLanguages) {
             for (let i = 0; i < categoryLanguages?.length; i += 1) {
                 for (let j = 0; j < categoryLanguages[i]?.children?.length; j += 1) {
                     for (let u = 0; u < categoryLanguages[i]?.children[j]?.children?.length; u += 1) {
                         if (languageList.filter((lang) => lang.originalCategoryName === categoryLanguages[i].children[j].children[u].feedCategory.productCategoryChild).length < 1) {
                             languageList.push({originalCategoryName: categoryLanguages[i].children[j].children[u].feedCategory.productCategoryChild, 
                                 de: categoryLanguages[i].children[j].children[u].feedCategory.langs.de.productCategoryChild,
                                 es: categoryLanguages[i].children[j].children[u].feedCategory.langs.es.productCategoryChild,
                                 fr: categoryLanguages[i].children[j].children[u].feedCategory.langs.fr.productCategoryChild,
                                 it: categoryLanguages[i].children[j].children[u].feedCategory.langs.it.productCategoryChild,
                                 nl: categoryLanguages[i].children[j].children[u].feedCategory.langs.nl.productCategoryChild,
                                 pt: categoryLanguages[i].children[j].children[u].feedCategory.langs.pt.productCategoryChild,
                             })
                         }
                         if (languageList.filter((lang) => lang.originalCategoryName === categoryLanguages[i].children[j].children[u].feedCategory.productCategoryGrandChild).length < 1) {
                             languageList.push({originalCategoryName: categoryLanguages[i].children[j].children[u].feedCategory.productCategoryGrandChild, 
                                 de: categoryLanguages[i].children[j].children[u].feedCategory.langs.de.productCategoryGrandChild,
                                 es: categoryLanguages[i].children[j].children[u].feedCategory.langs.es.productCategoryGrandChild,
                                 fr: categoryLanguages[i].children[j].children[u].feedCategory.langs.fr.productCategoryGrandChild,
                                 it: categoryLanguages[i].children[j].children[u].feedCategory.langs.it.productCategoryGrandChild,
                                 nl: categoryLanguages[i].children[j].children[u].feedCategory.langs.nl.productCategoryGrandChild,
                                 pt: categoryLanguages[i].children[j].children[u].feedCategory.langs.pt.productCategoryGrandChild,
                             })
                         }
                         if (languageList.filter((lang) => lang.originalCategoryName === categoryLanguages[i].children[j].children[u].feedCategory.productCategoryGreatGrandChild).length < 1) {
                             languageList.push({originalCategoryName: categoryLanguages[i].children[j].children[u].feedCategory.productCategoryGreatGrandChild, 
                                 de: categoryLanguages[i].children[j].children[u].feedCategory.langs.de.productCategoryGreatGrandChild,
                                 es: categoryLanguages[i].children[j].children[u].feedCategory.langs.es.productCategoryGreatGrandChild,
                                 fr: categoryLanguages[i].children[j].children[u].feedCategory.langs.fr.productCategoryGreatGrandChild,
                                 it: categoryLanguages[i].children[j].children[u].feedCategory.langs.it.productCategoryGreatGrandChild,
                                 nl: categoryLanguages[i].children[j].children[u].feedCategory.langs.nl.productCategoryGreatGrandChild,
                                 pt: categoryLanguages[i].children[j].children[u].feedCategory.langs.pt.productCategoryGreatGrandChild,
                             })
                         }

                     }
                 }
             }
         }
         setDetermineLangList(languageList)
    }, [categoryLanguages]);

    const equalizeLang = (langData) => {
        if (langData === 'sp') {
            return 'es'
        }
        if (langData === 'ge') {
            return 'de'
        }
        return langData
    }

    const getCateogryLanguage = (lang, category) => {
         const categoryText = determineLangList?.filter((langData) => langData.originalCategoryName === category)[0]
         if (lang && categoryText) {
             return categoryText[equalizeLang(lang)] || category
         }
        return category
    }

    useEffect(() => {
        let canceled = false;
        userApi.getUser().then((userData) => {
            const use = { ...userData };
            if (canceled) {
                return;
            }
            setUser(use.data);
        }).catch(
            (error) => {
                auth.signout(() => {
                    window.location.href = '/login';
                });
                return Promise.reject(error);
            },
        );

        return () => {
            canceled = true;
        };
    }, [refreshUser]);
    useEffect(() => {
        let canceled = false;

        languageApi.getLanguages().then((data) => {
            if (canceled) {
                return;
            }
            setLanguages(data.data);
            setisLoadingLanguages(false);
        });

        return () => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        if (user) {
        userApi.updateUser(user.sid, { ...user, cartItems: cart?.cart });
    }
    }, [cart]);

    if (program && program.status === false) {
        auth.signout(() => {
            window.location = '/';
        });
        return (
            <React.Fragment>        </React.Fragment>
        );
    }

    if (isLoadingProgram || isLoadingLanguages) {
        return <BlockLoader />;
    }
    const messages = languages[locale]?.messages
    console.log(languages)
    const { theme } = program;
    let gridType;
    const root = document.documentElement;
    if (theme !== undefined) {
        root?.style.setProperty('--accent-color', theme.color1);
        root?.style.setProperty('--buttons-theme-color', theme.color2);
        root?.style.setProperty('--link-color', theme.color3);
        gridType = theme && theme.gridType;
    }

    if ((program && program.feedStatus) && (
        // program.feedStatus.status === 'Started'
        // || program.feedStatus.status === 'Feed Requested'
        // || program.feedStatus.status === 'Feed Received'
        program.feedStatus.status === 'Cleared Catalog'
        || program.feedStatus.status === 'Created Categories'
        || program.feedStatus.status === 'Created Products'
        || program.feedStatus.status === 'Set Category Items'
        // || program.feedStatus.status === 'Created Brands'
        // || program.feedStatus.status === 'Completed'
        || program.feedStatus.status === 'Completed with Error'
    )) {
        return (
            <IntlProvider locale={locale} messages={messages}>
                <UserContext.Provider value={{ user, setUser }}>
                    <ProgramContext.Provider value={{ program, setProgram }}>
                        <RebuildingLayout />
                    </ProgramContext.Provider>
                </UserContext.Provider>
            </IntlProvider>
        );
    }

    if (!user) { return <span />; }
    if (user.status === 'Used') {
        return (
            <IntlProvider locale={locale} messages={messages}>
                <UserContext.Provider value={{ user, setUser, setRefreshUser }}>
                    <ProgramContext.Provider value={{ program, setProgram }}>
                        <CategoriesContext.Provider value={{ categories, setCategories, getCateogryLanguage }}>
                            <UsedAccountLayout />
                        </CategoriesContext.Provider>
                    </ProgramContext.Provider>
                </UserContext.Provider>
            </IntlProvider>
        );
    }
    return (
        <React.Fragment>
            <IntlProvider locale={locale} messages={messages}>
                <UserContext.Provider value={{ user, setUser, setRefreshUser }}>
                    <ProgramContext.Provider value={{ program, setProgram }}>
                        <CategoriesContext.Provider value={{ categories, setCategories, getCateogryLanguage }}>
                            {program && (
                                <Helmet>
                                    <link rel="shortcut icon" href={`${program && program?.theme?.favIcon}`} />
                                    <title>{program?.fullName}</title>
                                    <meta name="description" content={program?.fullName} />
                                </Helmet>
                            )}

                            <ToastContainer autoClose={5000} hideProgressBar />

                            <Quickview />

                            <MobileMenu />
                            <div className="site">
                                <header className="site__header d-lg-none">
                                    <MobileHeader />
                                </header>

                                <header className="site__header d-lg-block d-none">
                                    <Header layout={headerLayout} />
                                </header>

                                <div className="site__body" style={{ marginTop: '24px' }}>
                                    <Switch>
                                        <PrivateRoute exact path={`${match.path}`} component={HomePage} />
                                        <Route
                                            exact
                                            path="/shop"
                                            render={(props) => {
                                                if (auth.isAuthenticated()) return <ShopPageCategory {...props} columns={3} viewMode={gridType} sidebarPosition="start" />;
                                                window.location = '/login';
                                                return <span />;
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/shop/catalog"
                                            render={(props) => {
                                                if (auth.isAuthenticated()) return <ShopPageCategory {...props} columns={3} viewMode={gridType} sidebarPosition="start" />;
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/account/login',
                                                            state: { from: location },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/shop/catalog/:categorySlug"
                                            render={(props) => {
                                                if (auth.isAuthenticated()) {
                                                    const { match } = props;
                                                    const { params } = match;
                                                    const { categorySlug } = params;
                                                    return (
                                                        <ShopPageCategory
                                                            {...props}
                                                            columns={3}
                                                            viewMode={gridType}
                                                            sidebarPosition="start"
                                                            categorySlug={categorySlug}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/account/login',
                                                            state: { from: location },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/site/:pageName"
                                            render={(props) => {
                                                if (auth.isAuthenticated()) {
                                                    const { match } = props;
                                                    const { params } = match;
                                                    const { pageName } = params;
                                                    return (
                                                        <SitePage
                                                            {...props}
                                                            pageName={pageName}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/account/login',
                                                            state: { from: location },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/:pageName"
                                            render={(props) => {
                                                if (auth.isAuthenticated()) {
                                                    const { match } = props;
                                                    const { params } = match;
                                                    const { pageName } = params;
                                                    console.log(pageName)
                                                    return (
                                                        <SitePage
                                                            {...props}
                                                            pageName={pageName}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/account/login',
                                                            state: { from: location },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            user={user}
                                            exact
                                            path="/shop/products/:productSlug"
                                            render={(props) => {
                                                if (auth.isAuthenticated()) {
                                                    const { match } = props;
                                                    const { params } = match;
                                                    const { productSlug } = params;
                                                    return (
                                                        <ShopPageProduct
                                                            {...props}
                                                            layout="standard"
                                                            productSlug={productSlug}
                                                            user={user}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/account/login',
                                                            state: { from: location },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            exact
                                            path="/mindtickle/redirect"
                                            render={(props) => {
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/',
                                                            state: { from: location },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            user={user}
                                            exact
                                            path="/termsconditions"
                                            component={TermsAndConditions}
                                        />
                                        <Route
                                            exact
                                            path="/shop/checkout"
                                            render={() => {
                                                if (auth.isAuthenticated()) {
                                                    return (
                                                        <PageCheckout user={user} program={program} />
                                                    );
                                                }
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/account/login',
                                                            state: { from: location },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <PrivateRoute exact path="/shop/cart" component={PageCart} />
                                        <PrivateRoute exact path="/shop/checkout/success/:orderId" component={ShopPageOrderSuccess} />
                                        {orderSummary && <PrivateRoute exact path="/shop/checkout/summary" component={ShopPageOrderSummary} />}
                                        <PrivateRoute exact path="/shop/track-order" component={ShopPageTrackOrder} />

                                        <Route exact path="/account/login" component={AccountPageLogin} />
                                        <Route exact path="/account/logout" component={AccountPageLogout} />
                                        <PrivateRoute path="/account" component={AccountLayout} />

                                        <Route exact path="/site/not-found" component={SitePageNotFound} />

                                        <Route component={SitePageNotFound} />
                                    </Switch>
                                </div>

                                <footer className="site__footer">
                                    <Footer />
                                </footer>
                            </div>
                        </CategoriesContext.Provider>
                    </ProgramContext.Provider>
                </UserContext.Provider>
            </IntlProvider>
        </React.Fragment>
    );
}

Layout.propTypes = {
    headerLayout: PropTypes.oneOf(['default', 'compact']),
};

Layout.defaultProps = {
    headerLayout: 'compact',
};

const mapStateToProps = (state) => ({
    cart: state,
    locale: state.locale,
});

export default connect(mapStateToProps)(Layout);
