// react
import React, { useContext } from 'react';

// third-party
// import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// application
// import Dropdown from './Dropdown';
import DropdownLanguage from './DropdownLanguage';
import ProgramContext from '../../contexts/ProgramContext';
import UserContext from '../../contexts/UserContext';
import GeoModal from './Geolocationmodal';
import auth from '../../server/real/endpoints/auth';

function Topbar(props) {
    const { locale } = props;
    const { program } = useContext(ProgramContext);
    const { user } = useContext(UserContext);
    const { theme } = program;
    const history = useHistory();
    let logo;
    if (theme !== undefined) {
        logo = theme.logo;
    }
    const links = [

    ];

    const myProfile = () => {
        history.push('/account/myprofile');
    };
    const logout = () => {
        auth.signout(() => {
            window.location = '/';
        });
    };

    //    const accountLinks = [
    //       { title: 'My Profile', url: '/account/myprofile' },
    //   ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <Link className="topbar-link" to={item.url}>{item.title}</Link>
        </div>
    ));

    const calculateLevelMessage = () => {
        let levelMessage;
        if (user) {
        levelMessage = program?.plateauLevels?.filter((level) => level.id === user?.levelId);
        }
        if (levelMessage?.[0]?.levelMessage) {
            return levelMessage?.[0].levelMessage;
        }
        return program.adminMessage;
    };

    return (
        <div className="container">
            <div className="site-header__topbar topbar">
                <div className="topbar__container container">
                    <div className="topbar__row">
                        {linksList}
                        <div className="topbar__spring" />
                        {!program.showTopAccountBar
                        ? (
                        <div className="topbar__item">
                            <button type="button" className="topbar-dropdown__btn" onClick={myProfile}>
                                <FormattedMessage id="topbar_myAccount" defaultMessage="MyAccount" />
                            </button>
                        </div>
                        )
                        : (
                        <div className="topbar__item">
                            <button type="button" className="topbar-dropdown__btn" onClick={logout}>
                                <FormattedMessage id="topbar_logout" defaultMessage="Logout" />
                            </button>
                        </div>
                        )}
                        {program && program.geolocation && program.geolocation.enabled && (
                            <div className="topbar__item">
                                <GeoModal locale={locale} />
                            </div>
                        )}
                        <div className="topbar__item">
                            <DropdownLanguage />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container row align-items-center">
                <div className="nav-panel__logo col-3">

                    <Link to="/">
                        <img style={{ maxHeight: '110px', maxWidth: '230px'}} alt="logo" src={logo} />
                    </Link>
                </div>
                {program && (
                    <div className="col-6" style={{ textAlign: `${program?.messageAlign === 'Left' ? 'left' : program?.messageAlign === 'Right' ? 'right' : 'center' }`, fontSize: `${program?.messageSize === 'Small' ? '18px' : program?.messageSize === 'Large' ? '22px' : '20px' }`}}>
                        <span >{program.type === 'points' ? <FormattedMessage id="header_adminmessage" defaultMessage={program.adminMessage || " "} /> : calculateLevelMessage()}</span>
                    </div>
                )}
                <div className="col-3"></div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Topbar);
