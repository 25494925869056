import React from 'react';
import Slider from 'react-slick';

export default function APP(props) {
    const slickSettings = {
        dots: true,
        arrows: true,
    };
    const { product } = props;
    return (
        <div className="container block-slideshow-video">
            <Slider {...slickSettings}>
                {product && product.resources.product.filter((video) => video.productResourceType === 'Video').map((a) => (<div className="embed-responsive embed-responsive-16by9" dangerouslySetInnerHTML={{ __html: a.productResourceEmbed }} />))}
            </Slider>
        </div>
    );
}
