// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { useHistory } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Points from './Points';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import { cartAddItem } from '../../store/cart';

// contex
import ProgramContext from '../../contexts/ProgramContext';
import UserContext from '../../contexts/UserContext';
import {
    selectedLanguage, getProductColors, getProductSizes, getProductOption, getProductPrice, getProductDiscountPrice, getCustom1Options, getCustom2Options, getCustom3Options,
} from '../../server/utils';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
        };
    }

    onClick = () => {
        try {
            window.scrollTo({ top: 10000, left: 0, behavior: 'smooth' });
        } catch {
            window.scrollTo(0, 0);
        }
    };

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    handleChangeColor = (e) => {
        this.setState({ color: e.currentTarget.value }, function () {
            this.handleChangeOption();
        });
    };

    handleChangeSize = (e) => {
        this.setState({ size: e.currentTarget.value }, function () {
            this.handleChangeOption();
        });
    };

    handleChangeOption = () => {
        const { product } = this.props;
        const {
            color, size, custom1, custom2, custom3,
        } = this.state;
        const option = getProductOption(product, color, size, custom1, custom2, custom3);
        this.setState({ option });
    }

    handleChangeCustom1Option = (e) => {
        this.setState({ custom1: e.currentTarget.value }, function () {
            this.handleChangeOption();
        });
    };

    handleChangeCustom2Option = (e) => {
        this.setState({ custom2: e.currentTarget.value }, function () {
            this.handleChangeOption();
        });
    };

    handleChangeCustom3Option = (e) => {
        this.setState({ custom3: e.currentTarget.value }, function () {
            this.handleChangeOption();
        });
    };

    addToCartEnabled = () => {
        const { product } = this.props;
        const { option } = this.state;

        if (product && product.options && product.options.length > 0) {
            if (option) {
                return false;
            } if (option && option.length < 1) {
                return false;
            }
            return true;
        }
        return false;
    }

    render() {
        const {
            layout, cartAddItem, product, region, locale,
        } = this.props;
        const {
            quantity, option, color, size,
        } = this.state;

    //    const history = useHistory();

        let colors;
        let sizes;
        let custom1Options;
        let custom2Options;
        let custom3Options;
        function equalizeLang (langData) {
            if (langData === 'sp') {
                return 'es'
            }
            if (langData === 'ge') {
                return 'de'
            }
            return langData
        }
        if (product && product.options && product.options.length > 0) {
            colors = getProductColors(product);
            sizes = getProductSizes(product);
            custom1Options = getCustom1Options(product);
            custom2Options = getCustom2Options(product);
            custom3Options = getCustom3Options(product);
        }

        return (
            <ProgramContext.Consumer>
                {(context) => {
                    const { program } = context;
                    return (
                        <UserContext.Consumer>
                            {(userContext) => {
                                // eslint-disable-next-line no-unused-vars
                                const { user } = userContext;
                                return (
                                    <div className={`product product--layout--${layout}`}>
                                                                    <button
                                                    className="btn btn-primary"
                                                                                                                        style={{
                                                    paddingLeft: '10px', paddingRight: '10px', marginBottom: '20px',
                                                    }}
                                                    type="button"
                                                    onClick={() => window.history.go(-1)}
                                                                    >
                                                                        <FormattedMessage id="product_back" defaultMessage="Back" />
                                                                    </button>
                                        <div className="product__content">
                                            <ProductGallery layout={layout} option={option} product={product} />
                                            <div className="product__info">
                                                <h5 className="product__name">
                                                    {product.brandName}
                                                </h5>
                                                <h1 className="product__name">{product !== null && (selectedLanguage(product, equalizeLang(locale), product && product.productName, 'name'))}</h1>
                                                {product && product.options && product.options.length < 1 && (
                                                <div className="product__description">
                                                    {product !== null && (selectedLanguage(product, equalizeLang(locale), product && product.productSalesCopy, 'salesCopy'))}
                                                </div>
                                                )}

                                                <ul className="product__meta">
                                                    <li className="product__meta-availability">
                                                        <FormattedMessage id="product_shipsin" defaultMessage="Ships in" />
                                                        {': '}
                                                        <span className="text-success">
                                                            {`${product.productDropShipDays} days`}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="product__sidebar">
                                                <div className="product__availability">
                                                    <FormattedMessage id="product_availability" defaultMessage="Availability" />
                                                    :
                                                    <span className="text-success">In Stock</span>
                                                </div>

                                                <div className="product__prices">
                                                    <React.Fragment>
                                                        {program && program.type === 'points' && product.discountPrice && (
                                                            <React.Fragment>
                                                                <span className="product__new-price">
                                                                    <Points value={getProductPrice(product, option, region)} />
                                                                </span>
                                                                {' '}
                                                                <span className="product__old-price">
                                                                    <Points value={getProductDiscountPrice(product, option, region)} />
                                                                </span>
                                                            </React.Fragment>
                                                        )}

                                                        {program && program.type === 'points' && !product.discountPrice && (<Points value={getProductPrice(product, option, region)} />)}
                                                    </React.Fragment>
                                                </div>

                                                <form className="product__options">
                                                    <div className="form-group product__option">
                                                        {colors && colors.length !== 0 && (<div className="product__option-label"><FormattedMessage id="product_color" defaultMessage="Color" /></div>)}

                                                        {colors && colors.length > 0 && colors.length < 5 && (
                                                            <div className="input-radio-label__list color">
                                                                {colors.map((color) => (
                                                                    <label key={`color-label-${color}`}>
                                                                        <input
                                                                            key={color}
                                                                            value={color}
                                                                            type="radio"
                                                                            name="color"
                                                                            onChange={this.handleChangeColor}
                                                                        />
                                                                        <span>
                                                                            {color}
                                                                        </span>
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        )}

                                                        {colors && colors.length > 0 && colors.length >= 5 && (
                                                            <select className="form-control mb-3 w-50" onChange={this.handleChangeColor}>
                                                                {colors.map((color) => (
                                                                    <option key={`color-option-${color}`} value={color}>
                                                                        {color}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </div>

                                                    <div className="form-group product__option">
                                                        {sizes && sizes.length !== 0 && (<div className="product__option-label"><FormattedMessage id="product_size" defaultMessage="Size" /></div>)}

                                                        <div className="input-radio-label">
                                                            {sizes && sizes.length > 0 && sizes.length < 5 && (
                                                                <div className="input-radio-label__list size">
                                                                    {sizes.map((size) => (
                                                                        <label key={`size-label-${size}`}>
                                                                            <input
                                                                                key={size}
                                                                                value={size}
                                                                                type="radio"
                                                                                name="Size"
                                                                                onChange={this.handleChangeSize}
                                                                            />
                                                                            <span>{size}</span>
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            {sizes && sizes.length > 0 && sizes.length >= 5 && (
                                                                <select className="form-control mb-3 w-50" onChange={this.handleChangeSize}>
                                                                    {sizes.map((size) => (
                                                                        <option key={`size-option-${size}`} value={size}>
                                                                            {size}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group product__option">
                                                        {custom1Options && custom1Options.length !== 0 && (<div className="product__option-label">{product.optionsCustom1Label}</div>)}

                                                        <div className="input-radio-label">
                                                            {custom1Options && custom1Options.length > 0 && custom1Options.length < 5 && (
                                                                <div className="input-radio-label__list size">
                                                                    {custom1Options.map((custom1Option) => (
                                                                        <label key={`size-label-${custom1Option}`}>
                                                                            <input
                                                                                key={custom1Option}
                                                                                value={custom1Option}
                                                                                type="radio"
                                                                                name="Custom1"
                                                                                onChange={this.handleChangeCustom1Option}
                                                                            />
                                                                            <span>{custom1Option}</span>
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            {custom1Options && custom1Options.length > 0 && custom1Options.length >= 5 && (
                                                                <select className="form-control mb-3 w-50" onChange={this.handleChangeCustom1Option}>
                                                                    {custom1Options.map((size) => (
                                                                        <option key={`size-option-${size}`} value={size}>
                                                                            {size}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group product__option">
                                                        {custom2Options && custom2Options.length !== 0 && (<div className="product__option-label">{product.optionsCustom2Label}</div>)}

                                                        <div className="input-radio-label">
                                                            {custom2Options && custom2Options.length > 0 && custom2Options.length < 5 && (
                                                                <div className="input-radio-label__list size">
                                                                    {custom2Options.map((custom2Option) => (
                                                                        <label key={`size-label-${custom2Option}`}>
                                                                            <input
                                                                                key={custom2Option}
                                                                                value={custom2Option}
                                                                                type="radio"
                                                                                name="Custom2"
                                                                                onChange={this.handleChangeCustom2Option}
                                                                            />
                                                                            <span>{custom2Option}</span>
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            {custom2Options && custom2Options.length > 0 && custom2Options.length >= 5 && (
                                                                <select className="form-control mb-3 w-50" onChange={this.handleChangeCustom2Option}>
                                                                    {custom2Options.map((size) => (
                                                                        <option key={`size-option-${size}`} value={size}>
                                                                            {size}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group product__option">
                                                        {custom3Options && custom3Options.length !== 0 && (<div className="product__option-label">{product.optionsCustom3Label}</div>)}

                                                        <div className="input-radio-label">
                                                            {custom3Options && custom3Options.length > 0 && custom3Options.length < 5 && (
                                                                <div className="input-radio-label__list size">
                                                                    {custom3Options.map((custom3Option) => (
                                                                        <label key={`size-label-${custom3Option}`}>
                                                                            <input
                                                                                key={custom3Option}
                                                                                value={custom3Option}
                                                                                type="radio"
                                                                                name="Custom3"
                                                                                onChange={this.handleChangeCustom3Option}
                                                                            />
                                                                            <span>{custom3Option}</span>
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            {custom3Options && custom3Options.length > 0 && custom3Options.length >= 5 && (
                                                                <select className="form-control mb-3 w-50" onChange={this.handleChangeCustom3Option}>
                                                                    {custom3Options.map((size) => (
                                                                        <option key={`size-option-${size}`} value={size}>
                                                                            {size}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group product__option">
                                                        <div>
                                                            {program && program.type === 'points' && program.permissions.allowPurchase && (
                                                                <label htmlFor="product-quantity" className="product__option-label">
                                                                    <FormattedMessage id="product_quantity" defaultMessage="Quantity" />
                                                                </label>
                                                            )}

                                                            <div className="product__actions">
                                                                {program && program.type === 'points' && program.permissions.allowPurchase && (
                                                                    <div className="product__actions-item">
                                                                        <InputNumber
                                                                            id="product-quantity"
                                                                            aria-label="Quantity"
                                                                            className="product__quantity"
                                                                            size="lg"
                                                                            min={1}
                                                                            value={quantity}
                                                                            onChange={this.handleChangeQuantity}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {program && program.permissions.allowPurchase && (
                                                                <div className="product__actions-item product__actions-item--addtocart">
                                                                    <AsyncAction
                                                                        action={() => cartAddItem(product, option, quantity, program)}
                                                                        render={({ run, loading }) => (
                                                                            <button
                                                                                type="button"
                                                                                onClick={run}
                                                                                disabled={!quantity || this.addToCartEnabled()}
                                                                                className={classNames('btn btn-primary btn-lg', {
                                                                                    'btn-loading': loading,
                                                                                })}
                                                                            >
                                                                                <FormattedMessage id={`${program.type}_addToCart`} defaultMessage="Add To Cart" />
                                                                            </button>
                                                                        )}
                                                                    />
                                                                </div>
)}
                                                                <div className="product__actions-item product__actions-item--addtocart">
                                                                    <button className="btn btn-primary btn-lg" type="button" onClick={this.onClick}>
                                                                        <FormattedMessage id="product_moredetails" defaultMessage="More Details" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {size && color && this.addToCartEnabled() && <p style={{ color: 'red', marginTop: '10px' }}><FormattedMessage id={`product_optionavailable`} defaultMessage="Current Option Selection is not Available" /></p>}
                                                            {product && product.options && product.options.length > 0 && (!size || !color || this.addToCartEnabled() === false) && <p style={{ color: '#28a745', marginTop: '10px' }}><FormattedMessage id={`product_optiontext`} defaultMessage="Choose the option to select the item" /></p>}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }}
                        </UserContext.Consumer>
                    );
                }}
            </ProgramContext.Consumer>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
};

const mapStateToProps = (state) => ({
    region: state.region,
    locale: state.locale,
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
