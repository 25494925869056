// react
import React, { useContext } from 'react';

// third-party
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// application
import CartIndicator from './IndicatorCart';
import NavLinks from './NavLinks';
import Search from './Search';

// context
import ProgramContext from '../../contexts/ProgramContext';
import UserContext from '../../contexts/UserContext';

function NavPanel() {
    let points;
    const { program } = useContext(ProgramContext);
    const { user } = useContext(UserContext);
    if (program && program.type === 'points') {
        points = true;
    } else {
        points = false;
    }

    return (
        <div className="nav-panel">
            <div className="nav-panel__container container">
                <div className="nav-panel__row">

                    <div className="nav-panel__nav-links nav-links">
                        <NavLinks />
                    </div>
                    { program && program.permissions && program.permissions.allowPurchase !== false && (
                    <React.Fragment>
                        <div className="nav-panel__indicators">

                            <CartIndicator />

                        </div>
                        <div>
                            <div className="nav-links__item" style={{ fontWeight: '700', marginLeft: '30px', fontSize: '15px' }}>
                                { points ? (
                                    <div>
                                        <FormattedMessage id="topbar_mypoints" defaultMessage="My Points" />
                                        {': '}
                                        {user.balance}
                                    </div>
) : ' ' }
                            </div>
                        </div>
                    </React.Fragment>
)}
                </div>
                {!program?.showTopCategorySearchBar && (
                <div className="container" style={{ marginTop: '15px', paddingLeft: '0px', paddingRight: '0px' }}>
                    <div className="site-header__search">
                        <Search context="header" />
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
