// react
import React, { useContext } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Points from '../shared/Points';
import { Cart16Svg } from '../../svg';
import { cartAddItem } from '../../store/cart';
import { url } from '../../services/utils';
import { quickviewOpen } from '../../store/quickview';
import ProgramContext from '../../contexts/ProgramContext';
import { getProductPrice, selectedLanguage } from '../../server/utils';

function Suggestions(props) {
    const {
        context,
        className,
        products,
        cartAddItem,
        quickviewOpen,
        locale,
    } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    const { program } = useContext(ProgramContext);
    const { region } = props;
    const list = (products && products.map((product) => {
        const unitPrice = getProductPrice(product, product.options, region);
        return (
            <li key={product.productID} className="suggestions__item">
                {product.images && (
                    <div className="suggestions__item-image product-image">
                        <div className="product-image__body">
                            <img className="product-image__img" src={product.images.imageLowSmall} alt="" />
                        </div>
                    </div>
                )}
                <div className="suggestions__item-info">
                    <Link className="suggestions__item-name" to={url.product(product)}>
                        {selectedLanguage(product, locale, product.productName, 'name')}
                    </Link>
                    <div className="suggestions__item-meta">
                        SKU:
                        {' '}
                        {product.productUPC}
                    </div>
                </div>
                <div className="suggestions__item-price">
                    {program && program.type === 'points' ? <Points value={unitPrice} /> : null}
                </div>
                {context === 'header' && program && program.permissions && program.permissions.allowPurchase !== false && (
                    <div className="suggestions__item-actions">
                        {product.options && product.options.length > 1 ? (
                            <span>
                                <AsyncAction
                                    action={() => quickviewOpen(product.slug)}
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            title="Add to cart"
                                            className={program && program.type === 'points'
                                                ? classNames('btn btn-primary btn-sm btn-svg-icon', {
                                                    'btn-loading': loading,
                                                }) : classNames('btn btn-primary btn-sm', {
                                                    'btn-loading': loading,
                                                })}
                                        >
                                            {program && program.type === 'points' ? <Cart16Svg /> : <React.Fragment>Select</React.Fragment>}
                                        </button>
                                    )}
                                />
                            </span>
                        ) : (
                            <span>
                                <AsyncAction
                                    action={() => cartAddItem(product, [], 1, program)}
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            title="Add to cart"
                                            className={program && program.type === 'points'
                                                ? classNames('btn btn-primary btn-sm btn-svg-icon', {
                                                    'btn-loading': loading,
                                                }) : classNames('btn btn-primary btn-sm', {
                                                    'btn-loading': loading,
                                                })}
                                        >
                                            {program && program.type === 'points' ? <Cart16Svg /> : <React.Fragment>Select</React.Fragment>}
                                        </button>
                                    )}
                                />
                            </span>
                        )}
                    </div>
                )}
            </li>
        );
    }));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">
                {list}
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => ({
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {
    cartAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suggestions);
