// react
import React from 'react';

import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import RawHTML from './RawHTML';
import ProductsCarousel from './ProductsCarousel';

function Sections(props) {
    const { sections } = props;
    const sectionComponents = [
    ];

    if (sections) {
        for (let i = 0; i < sections.length; i += 1) {
            const key = `home-${i}`;
            switch (sections[i].type) {
                default:
                    break;
                case 'html':
                    sectionComponents.push(
                        <RawHTML key={key} html={sections[i].html} />,
                    );
                    break;
                case 'slider':
                    sectionComponents.push(
                        <BlockSlideShow key={key} slides={sections[i].slides} speed={sections[i]} />,
                    );
                    break;
                case 'productsCarousel':
                    sectionComponents.push(
                        <ProductsCarousel key={key} section={sections[i]} />,
                    );
                    break;
                case 'productCarousel':
                    sectionComponents.push(
                        <ProductsCarousel key={key} section={sections[i]} />,
                    );
                    break;
            }
        }
    }

    return (
        <React.Fragment>
            {sectionComponents}
        </React.Fragment>
    );
}

export default Sections;
