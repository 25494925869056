// react
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

// application
import productApi from '../../server/real/endpoints/products';
import { useProductTabs } from '../../services/hooks';

// blocks
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

function ProductsCarousel(props) {
    const { section, region } = props;
    const {
        categories, title, layout, rows, source, limit, slideSpeed,
    } = section;
    const featuredProducts = useProductTabs(
        useMemo(() => categories, [region]), (tab) => productApi.getProductsList({
            limit,
            category: tab && tab.categorySlug,
            source,
            region,
        }, {}, [], false),
        region,
    );

    return (
        <React.Fragment>
            {featuredProducts && featuredProducts.data && featuredProducts.data.data && featuredProducts.data.data.items.length > 0 && (
                <BlockProductsCarousel
                    title={title}
                    layout={layout}
                    rows={rows}
                    slideSpeed={slideSpeed}
                    products={featuredProducts.data.data && featuredProducts.data.data.items}
                    loading={featuredProducts.isLoading}
                    groups={featuredProducts.tabs}
                    onGroupClick={featuredProducts.handleTabChange}
                />
            )}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    region: state.region,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCarousel);
