// import programData from '../database/program';
import getApi from './api';
import { GetAPIEndpoint } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`;
    return { Authorization };
}
const programApi = {
    getProgram: () => getApi().get(`${GetAPIEndpoint()}/api/programs`, { headers: getHeaders() }),
    getContent: (program, language) => {
        if (!program) return undefined;
        if (!program.content) return undefined;
        const filtered = program.content.filter((data) => data.language === language);
        if (filtered.length > 0) return filtered[0];
        return undefined;
    },
    getUserWithoutApi: (mail) => getApi().post(`${GetAPIEndpoint()}/api/getpoints`, {email: mail}, { headers: getHeaders() }),
};

export default programApi;
