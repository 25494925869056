// react
import React, { useContext } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import { FormattedMessage } from 'react-intl';
import AsyncAction from './AsyncAction';
import Points from './Points';
import { cartAddItem } from '../../store/cart';
import { Quickview16Svg } from '../../svg';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { getProductPrice, getProductDiscountPrice, selectedLanguage } from '../../server/utils';

// context
import ProgramContext from '../../contexts/ProgramContext';

function ProductCard(props) {
    const {
        product, layout, quickviewOpen, cartAddItem, region, locale,
    } = props;
    const { program } = useContext(ProgramContext);
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });
    let badges = [];
    let image;
    let price;
    let cartTextLang;

    function equalizeLang (langData) {
        if (langData === 'sp') {
            return 'es'
        }
        if (langData === 'ge') {
            return 'de'
        }
        return langData
    }

    if (program && program.type === 'points') {
        cartTextLang = (<FormattedMessage id="points_addToCart" defaultMessage="Add To Cart" />);
    } else {
        cartTextLang = (<FormattedMessage id="plateau_addToCart" defaultMessage="Select" />);
    }
    if (product.badges && product.badges.includes('discount')) {
        badges.push(
            <div key="discount" className="product-card__badge product-card__badge--sale">
                Discount
            </div>,
        );
    }
    if (product.badges && product.badges.includes('featured')) {
        badges.push(
            <div key="featured" className="product-card__badge product-card__badge--hot">
                Featured
            </div>,
        );
    }
    if (product.badges && product.badges.includes('new')) {
        badges.push(
            <div key="new" className="product-card__badge product-card__badge--new">
                New
            </div>,
        );
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.images) {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img className="product-image__img" src={product.images.imageLowMedium} alt="" />
                </Link>
            </div>
        );
    }

    if (program && program.type === 'points') {
        if (product.discountPrice) {
            price = (
                <div className="product-card__prices">
                    <span className="product-card__new-price">
                        <Points value={getProductPrice(product, [], region)} />
                    </span>
                    {' '}
                    <span className="product-card__old-price">
                        <Points value={getProductDiscountPrice(product, [], region)} />
                    </span>
                </div>
            );
        } else {
            price = (
                <div className="product-card__prices">
                    <Points value={getProductPrice(product, [], region)} />
                </div>
            );
        }
    }
    const features = product.features && product.features.slice(0, 3).map((feature, key) => (
        <li key={key} className="product-feature__list">{feature.featureName}</li>
));
    let optionComponent;
    if (product.options && product.options.length > 1) {
        optionComponent = (
            <React.Fragment>
                <AsyncAction
                    action={() => quickviewOpen(product.slug)}
                    render={({ run, loading }) => (
                        <button
                            type="button"
                            onClick={run}
                            className={classNames('btn btn-primary product-card__addtocart', {
                                'btn-loading': loading,
                            })}
                        >
                            {cartTextLang}
                        </button>
                    )}
                />
                <span style={{ marginTop: '5px', fontSize: '12px' }}><FormattedMessage id="category_hasoptions" defaultMessage="Has Options*" /></span>
            </React.Fragment>
        );
    } else {
        optionComponent = (
            <React.Fragment>
                <AsyncAction
                    action={() => cartAddItem(product, {}, 1, program)}
                    render={({ run, loading }) => (
                        <React.Fragment>
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-primary product-card__addtocart', {
                                    'btn-loading': loading,
                                })}
                            >
                                {cartTextLang}
                            </button>
                        </React.Fragment>
                    )}
                />
            </React.Fragment>
        );
    }

    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(product.slug)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__prices" style={{ marginBottom: '10px' }}>
                    {product.brandName}
                </div>
                <div className="product-card__name">
                    <Link to={url.product(product)}>{selectedLanguage(product, equalizeLang(locale), product.productName, 'name')}</Link>
                </div>
                <div className="product-card__features-list">
                    {features}
                </div>
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    Ships within
                    {': '}
                    <br />
                    <span className="text-success">{product.productDropShipDays}</span>
                    {' '}
                    day(s)
                </div>
                {price}
                <div className="product-card__buttons">
                    {program && program.permissions.allowPurchase && (optionComponent)}
                </div>
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = (state) => ({
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {
    cartAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
