// react
import React, { Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import { ArrowRoundedLeft6x9Svg } from '../../svg';
import { getCategoryParents, url } from '../../services/utils';
import CategoriesContext from '../../contexts/CategoriesContext';

function FilterCategory(props) {
    const { data } = props;
    const lang = JSON.parse(localStorage.getItem('state'))?.locale
    const { getCateogryLanguage } = useContext(CategoriesContext)

    const categoriesList = data.items.map((category) => {
        const itemClasses = classNames('filter-categories__item', {
            'filter-categories__item--current': data.value === category.slug,
        });

        return (
            <Fragment key={category.id}>
                {getCategoryParents(category).map((parent) => (
                    <li key={parent.id} className="filter-categories__item filter-categories__item--parent">
                        <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />
                        <Link to={url.category(parent)}>{getCateogryLanguage(lang, parent.name)}</Link>
                    </li>
                ))}
                <li className={itemClasses}>
                    <Link to={url.category(category)}>{getCateogryLanguage(lang ,category.name)}</Link>
                </li>
                {category.children && category.children.map((child) => (
                    <li key={child.id} className="filter-categories__item filter-categories__item--child">
                        <Link to={url.category(child)}>{getCateogryLanguage(lang ,child.name)}</Link>
                    </li>
                ))}
            </Fragment>
        );
    });

    if (data.value) {
        categoriesList.unshift(
            <li key="[shop]" className="filter-categories__item filter-categories__item--parent">
                <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />
                <Link to={url.catalog()}><FormattedMessage id="filter_categoryall" defaultMessage="All Products" /></Link>
            </li>,
        );
    }

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                {categoriesList}
            </ul>
        </div>
    );
}

FilterCategory.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
};

export default FilterCategory;
