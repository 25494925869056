// react
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import '../../scss/redirect.css';
// import { useHistory } from 'react-router-dom';

// third-party

// data stubs
// import UserContext from '../../contexts/UserContext';
// import ProgramContext from '../../contexts/ProgramContext';

export default function RedirectOidc() {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [oidcData, setOidcData] = useState();
     const history = useHistory();

     useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
          // When user isn't authenticated, forget any user info
          setUserInfo(null);
        } else {
          // You can also get user information from the `/userinfo` endpoint
            
          oktaAuth.getUser().then((info) => {
            setUserInfo({email: info.email, token: authState?.accessToken?.accessToken});
          });
        }
      }, [authState, oktaAuth]);

    useEffect(() => {
        if (userInfo !== null) {
            axios.post('/signin-oidc', userInfo).then(response => {
                if (response.data)
                setOidcData(response.data.access_token);
            })
        }
      }, [userInfo]);

      useEffect(() => {
        if (oidcData) {
            sessionStorage.setItem('access_token', oidcData);
            setTimeout(() => {
                window.location = '/'
            }, 2000)
        }
      }, [oidcData]);

return (
<div className="center-page-1">
    <br />
    <br />
    <br />
        <h1 style={{ color: '#4a8df8' }}>Just a moment...</h1>
        <div>
            <div className="slider-redirect">
                <div className="line-redirect" />
                <div className="break-redirect dot1-redirect" />
                <div className="break-redirect dot2-redirect" />
                <div className="break-redirect dot3-redirect" />
            </div>
        </div>
        <p style={{ color: '#4a8df8' }}>
            We&apos;re redirecting you to the site... Not working?&nbsp;
            <a href="/" style={{ color: 'purple'}}>
                <u>Click here.</u>
            </a>
        </p>
</div>
);
}
