// react
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// third-party

// data stubs
// import UserContext from '../../contexts/UserContext';
// import ProgramContext from '../../contexts/ProgramContext';

export default function CallbackOkta() {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [oidcData, setOidcData] = useState();
    const [counter, setCounter] = useState(0);
     const history = useHistory();

     useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
          // When user isn't authenticated, forget any user info
          setUserInfo(null);
        } else {
          // You can also get user information from the `/userinfo` endpoint
            
          oktaAuth.getUser().then((info) => {
            setUserInfo({email: info.email, token: authState?.accessToken?.accessToken});
          });
        }
      }, [authState, oktaAuth]);

      useEffect(() => {
        if (userInfo !== null) {
            axios.post('/signin-oidc', userInfo).then(response => {
                if (response.data)
                setOidcData(response.data.access_token);
            })
        }
      }, [userInfo]);

      useEffect(() => {

        if (oidcData && counter === 0) {
                setCounter(counter + 1)
                window.open(window.location.origin+'/mindtickle/redirect', '_blank')

        }
      }, [oidcData]);
      useEffect(() => {
        if (userInfo !== null && oidcData) {
          setTimeout(() => {
            history.push("/mindtickle/score" + "?emailID=" + userInfo?.email)
          }, 5000);
        }

      }, [userInfo, oidcData]);
        return (
        <div className="center-page">
            <br />
            <br />
            <br />
                <h1 style={{ color: '#4a8df8' }}>Just a moment...</h1>
                <div>
                    <div className="slider-redirect">
                        <div className="line-redirect" />
                        <div className="break-redirect dot1-redirect" />
                        <div className="break-redirect dot2-redirect" />
                        <div className="break-redirect dot3-redirect" />
                    </div>
                </div>
                <p style={{ color: '#4a8df8' }}>
                    We&apos;re redirecting you to the site... Not working?&nbsp;<br />
                    <a href={"/mindtickle/score" + "?emailID=" +userInfo?.email} style={{ color: 'purple'}}>
                        <u>Click here.</u>
                    </a>
                </p>
        </div>
        );
      }
