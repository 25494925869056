// react
import React, {
    useCallback, useState, useContext,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';
import {
    Filters16Svg,
    LayoutGrid16x16Svg,
    LayoutGridWithDetails16x16Svg,
    LayoutList16x16Svg,
} from '../../svg';
import { sidebarOpen } from '../../store/sidebar';
import ProgramContext from '../../contexts/ProgramContext';

function ProductsView(props) {
    const {
        isLoading,
        productsList,
        options,
        filters,
        dispatch,
        layout: propsLayout,
        grid,
        offcanvas,
        sidebarOpen,
        onOptionChanged,
        resetFilters,
    } = props;
    const [layout, setLayout] = useState(propsLayout);
    const history = useHistory();
    const lang = JSON.parse(localStorage.getItem('state'))?.locale
    const handleResetFilters = useCallback(() => {
        resetFilters();
        if (window.location.href.indexOf('/shop/catalog/') !== -1) {
            history.push('/shop/catalog');
        }
    }, [dispatch]);
    const toTop = () => {
        try {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } catch {
            window.scrollTo(0, 0);
        }
    };
    const toPageOne = (pageData) => {
        onOptionChanged('page', pageData);
        toTop();
    };
    const resetPage = (e) => {
        onOptionChanged('limit', e.target.value);
    };
    const filtersCount = Object.keys(filters).map((x) => filters[x]).filter((x) => x).length;
    const { program } = useContext(ProgramContext);
    const { theme } = program;
    let viewModes = [];
    if (program && program.permissions && program.permissions.allowUserGrid) {
        viewModes = [{ key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> },
        { key: 'list', title: 'List', icon: <LayoutList16x16Svg /> },
        { key: 'long', title: 'Grid With Features', icon: <LayoutGridWithDetails16x16Svg /> }];
    } else if (theme && theme.theme !== undefined) {
        if (theme.gridType === 'grid') {
            viewModes = [{ key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> }];
        } else if (theme.gridType === 'list') {
            viewModes = [{ key: 'list', title: 'List', icon: <LayoutList16x16Svg /> }];
        } else if (theme.gridType === 'long') {
            viewModes = [{ key: 'long', title: 'Grid With Features', icon: <LayoutGridWithDetails16x16Svg /> }];
        }
    }

    viewModes = viewModes.map((viewMode) => {
        const className = classNames('layout-switcher__button', {
            'layout-switcher__button--active': layout === viewMode.key,
        });

        return (
            <button
                key={viewMode.key}
                title={viewMode.title}
                type="button"
                className={className}
                onClick={() => setLayout(viewMode.key)}
            >
                {viewMode.icon}
            </button>
        );
    });
    const productsListItems = productsList.items.map((product) => (
        <div key={`product-list-item-${product.productID}`} className="products-list__item">
            <ProductCard product={product} />
        </div>
    ));

    const rootClasses = classNames('products-view', {
        'products-view--loading': isLoading,
    });

    const viewOptionsClasses = classNames('view-options', {
        'view-options--offcanvas--always': offcanvas === 'always',
        'view-options--offcanvas--mobile': offcanvas === 'mobile',
    });

    let divs;

    const findSortNames = (sortName) => {
        if (sortName === 'name_asc') {
            return 'Product (A-Z)'
        }
        if (sortName === 'name_desc') {
            return 'Product (Z-A)'
        }
        if (sortName === 'brand_asc') {
            return 'Brand (A-Z)'
        }
        if (sortName === 'brand_desc') {
            return 'Brand (Z-A)'
        }
        if (sortName === 'price_asc') {
            return 'Points Low to High'
        }
        if (sortName === 'price_desc') {
            return 'Points High to Low'
        }
    }

    const showingFunctoLang = () => {
        if (lang) {
            if (lang === 'sp') {
                return `Mostrar ${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} de ${productsList?.total} productos`
            }
            if (lang === 'fr') {
                return `Montrant ${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} des ${productsList?.total} produits`
            }
            if (lang === 'ge') {
                return `${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} von ${productsList?.total} Produkten zeigen`
            }
            if (lang === 'it') {
                return `Mostrare ${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} di ${productsList?.total} prodotti`
            }
            if (lang === 'nl') {
                return `Met ${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} van ${productsList?.total} producten`
            }
            if (lang === 'pt') {
                return `Mostrando ${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} de ${productsList?.total} produtos`
            }
            if (lang === 'en') {
                return `Showing ${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} of ${productsList?.total} products`
            }
        } else {
            return `Showing ${productsList?.from + 1}—${productsList?.to > productsList?.total ? productsList?.total : productsList?.to} of ${productsList?.total} products`
        }
    }
    showingFunctoLang()
    if (productsListItems.length > 0) {
        divs = (
            <div className="products-view__content">
                <div className="products-view__options">
                    <div className={viewOptionsClasses}>
                        {program && (program.permissions.showBrands || program.permissions.showCategories || program.permissions.showPriceSlider || program.permissions.showTags)
                        && (
                        <div className="view-options__filters-button">
                            <button type="button" className="filters-button" onClick={() => dispatch(sidebarOpen())}>
                                <Filters16Svg className="filters-button__icon" />
                                <span className="filters-button__title"><FormattedMessage id="sidebar_filters" defaultMessage="Filters" /></span>
                                {!!filtersCount && <span className="filters-button__counter">{filtersCount}</span>}
                            </button>
                        </div>
                        )}
                        <div className="view-options__layout">
                            <div className="layout-switcher">
                                <div className="layout-switcher__list">
                                    {viewModes}
                                </div>
                            </div>
                        </div>
                        <div className="view-options__legend">
                            {showingFunctoLang()}
                        </div>
                        <div className="view-options__divider" />
                        <div className="view-options__control">
                            <label htmlFor="view-options-sort">
                                <FormattedMessage id="category_sortby" defaultMessage="Sort By" />
                            </label>
                            <div>
                                <select
                                    id="view-options-sort"
                                    className="form-control form-control-sm"
                                    onChange={(e) => { onOptionChanged('sort', e.target.value); }}
                                >
                                    <option value="default" hidden>Brand (A-Z)</option>
                                    {program.permissions?.sortOrder?.length > 0 ? program.permissions?.sortOrder?.map((sort) => {
                                        return (
                                            <option value={sort}>{findSortNames(sort)}</option>
                                        )
                                    }) : <>
                                    <option value="name_asc">Product (A-Z)</option>
                                    <option value="name_desc">Product (Z-A)</option>
                                    <option value="brand_asc">Brand (A-Z)</option>
                                    <option value="brand_desc">Brand (Z-A)</option></>}
                                    {/* <option value="name_asc">Product (A-Z)</option>
                                    <option value="name_desc">Product (Z-A)</option>
                                    <option value="brand_asc">Brand (A-Z)</option>
                                    <option value="brand_desc">Brand (Z-A)</option> */}
                                    {program && program.permissions?.sortOrder?.length === 0 && program.type === 'points'
                                        && (
                                            <React.Fragment>
                                                <option value="price_asc">Points Low to High</option>
                                                <option value="price_desc">Points High to Low</option>
                                            </React.Fragment>
                                        )}
                                </select>
                            </div>
                        </div>
                        {program && program.permissions && program.permissions.productCounts.length > 0 && (
                        <div className="view-options__control">
                            <label htmlFor="view-options-limit"><FormattedMessage id="category_show" defaultMessage="Show" /></label>
                            <div>
                                <select
                                    id="view-options-limit"
                                    className="form-control form-control-sm"
                                    value={options.limit || productsList.limit}
                                    onChange={(e) => {
                                        resetPage(e);
                                        toPageOne(1);
                                    }}
                                >
                                    {program && program.permissions && program.permissions.productCounts.length > 0 ? program && program.permissions && program.permissions.productCounts.map((data, index) => <option key={index} value={data}>{data}</option>)
                                        : (
                                            <React.Fragment>
                                                <option value="100">100</option>
                                            </React.Fragment>
                                        )}
                                </select>
                            </div>
                        </div>
                        )}
                    </div>
                </div>

                <div
                    className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'long' ? 'true' : 'false'}
                >
                    <div className="products-list__body">
                        {productsListItems}
                    </div>
                </div>

                <div className="products-view__pagination">
                    <Pagination
                        current={productsList.page}
                        siblings={2}
                        total={productsList.pages}
                        onPageChange={(e) => { toPageOne(e); }}
                    />
                </div>
            </div>
        );
    } else {
        divs = (
            <div className="products-view__empty">
                <div className="products-view__empty-title"><FormattedMessage id="category_nomatch" defaultMessage="No matching items" /></div>
                <div className="products-view__empty-subtitle"><FormattedMessage id="category_tryreset" defaultMessage="Try resetting the filters" /></div>
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleResetFilters}
                >
                    <FormattedMessage id="category_resetfilters" defaultMessage="Reset Filters" />
                </button>
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />
            {divs}
        </div>
    );
}

ProductsView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
    productsList: PropTypes.object,
    /**
     * Products list options.
     */
    options: PropTypes.object,
    /**
     * Products list filters.
     */
    filters: PropTypes.object,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'long', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

ProductsView.defaultProps = {
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};

const mapStateToProps = (state) => ({
    sidebarOpen,
    locale: state.locale,
});

export default connect(mapStateToProps)(ProductsView);
