// react
import React from 'react';
import { connect } from 'react-redux';

// application
import FooterContacts from './FooterContacts';
import { FormattedMessage } from 'react-intl';
import ToTop from './ToTop';

// data stubs

function Footer() {
    return (
        <div className="site-footer">
            <div className="container">
                <div>
                    <FooterContacts />
                </div>
                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        <FormattedMessage id="footer_copyright" defaultMessage={`Copyright © ${new Date().getFullYear()} The DataDirect Group. All rights reserved.`} />
                    </div>
                    <div className="site-footer__payments" />
                </div>
            </div>
            <ToTop />
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Footer);
