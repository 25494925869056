/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
// react
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, withRouter } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Points from '../shared/Points';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, cartUpdateQuantities } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { url } from '../../services/utils';
import { getProductPrice, selectedLanguage } from '../../server/utils';
// data stubs
import ProgramContext from '../../contexts/ProgramContext';
import UserContext from '../../contexts/UserContext';

class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
        };
    }

    componentDidUpdate(prevState) {
        const { quantities } = this.state;
        const { cartUpdateQuantities } = this.props;
        if (prevState.quantities !== quantities) {
            cartUpdateQuantities(quantities);
        }
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (user, item, quantity, currentQuantity) => {
        const max = this.getProductMax(user, item.product, item.option, currentQuantity);

        if (quantity > max) return;

        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return quantities.filter((x) => {
            const item = cart.items.find((item) => item.id === x.itemId);

            return item && item.quantity !== x.value && x.value !== '';
        }).length > 0;
    }

    getProductMax(user, product, option, quantity) {
        const { region } = this.props;
        let { balance } = user;
        if (!balance) balance = 0;

        const cartTotal = this.getCartTotal(user);

        const unitPrice = getProductPrice(product, option, region);

        const remaining = ((balance - cartTotal) / unitPrice) + quantity;
        return Math.floor(remaining);
    }



    renderItems(user) {
        const { cart, cartRemoveItem } = this.props;
        const { region, locale } = this.props;
        function equalizeLang (langData) {
            if (langData === 'sp') {
                return 'es'
            }
            if (langData === 'ge') {
                return 'de'
            }
            return langData
        }
        return cart.items.map((item) => {
            const { product, quantity } = item;
            const { option } = item;
            const unitPrice = getProductPrice(product, option, region);
            const totalPrice = unitPrice * quantity;
            let image;
            if (item.product.images) {
                image = (
                    <div className="product-image">
                        <Link to={url.product(item.product)} className="product-image__body">
                            <img className="product-image__img" src={item.product.images.imageLowMedium} alt="" />
                        </Link>
                    </div>
                );
            }
            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.id)}
                    render={({ run, loading }) => {
                        const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                            'btn-loading': loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={url.product(item.product)} className="cart-table__product-name">
                            {selectedLanguage(item.product, equalizeLang(locale), item.product.productName, 'name')}
                        </Link>

                        <ul className="dropcart__product-options">
                            {item.option && item.option.optionColorValue && (<li>{`Color: ${item.option.optionColorValue}`}</li>)}
                            {item.option && item.option.optionSizeValue && (<li>{`Size: ${item.option.optionColorValue}`}</li>)}
                            {item.option && item.option.optionCustom1Value && (<li>{`${item.product.optionsCustom1Label}: ${item.option.optionCustom1Value}`}</li>)}
                            {item.option && item.option.optionCustom2Value && (<li>{`${item.product.optionsCustom2Label}: ${item.option.optionCustom2Value}`}</li>)}
                            {item.option && item.option.optionCustom3Value && (<li>{`${item.product.optionsCustom3Label}: ${item.option.optionCustom3Value}`}</li>)}
                        </ul>
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        <Points value={unitPrice} />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(user, item, quantity, this.getItemQuantity(item))}
                            value={this.getItemQuantity(item)}
                            enableUp={this.getProductMax(user, item.product, item.option, this.getItemQuantity(item)) !== this.getItemQuantity(item)}
                            enableDown
                            min={1}
                            max={this.getProductMax(user, item.product, item.option, this.getItemQuantity(item))}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Points value={totalPrice} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                        {removeButton}
                    </td>
                </tr>
            );
        });
    }

    getCartTotal(user) {
        const { region } = this.props;
        if (!user) return 0;
        let allTotal = 0;
        const { cart } = this.props;
        for (let i = 0; i < cart.items.length; i += 1) {
            const item = cart.items[i];
            const { product, quantity } = item;
            const { option } = item;

            const unitPrice = getProductPrice(product, option, region);
            const totalPrice = unitPrice * quantity;
            allTotal += totalPrice;
        }
        return allTotal;
    }

    checkoutDisabled(user) {
        if (this.getCartTotal(user) > user.balance) {
            return true;
        }
        return false;
    }

    renderCart(user, history) {
        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">
                                    <FormattedMessage id="cart_image" defaultMessage="Image" />
                                </th>
                                <th className="cart-table__column cart-table__column--product">
                                    <FormattedMessage id="cart_product" defaultMessage="Product" />
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    <FormattedMessage id="cart_price" defaultMessage="Price" />
                                </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    <FormattedMessage id="cart_quantity" defaultMessage="Quantity" />
                                </th>
                                <th className="cart-table__column cart-table__column--total">
                                    <FormattedMessage id="cart_total" defaultMessage="Total" />
                                </th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems(user)}
                        </tbody>
                    </table>
                    <div className="cart__actions">
                        <div className="cart__coupon-form" />
                        <div className="cart__buttons">
                            <Link to="/" className="btn btn-primary"><FormattedMessage id="cart_continueshopping" defaultMessage="Continue Shopping" /></Link>
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <table className="cart__totals">
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>
                                                    <FormattedMessage id="cart_total" defaultMessage="Continue Total" />
                                                </th>
                                                <td>
                                                    <Points value={this.getCartTotal(user)} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    {this.checkoutDisabled(user) && (<p style={{ color: 'red', fontSize: '14px' }}>Your cart total exceeds your available points balance.</p>)}
                                    <button type="button" className="btn btn-primary btn-xl btn-block cart__checkout-button" disabled={this.checkoutDisabled(user)} onClick={() => history.push('/shop/checkout')}>
                                        <FormattedMessage id="cart_proceedcheckout" defaultMessage="Proceed to checkout" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart, history } = this.props;
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Shopping Cart', url: '' },
        ];

        let content;

        return (
            <React.Fragment>
                <ProgramContext.Consumer>
                    {(context) => {
                        const { program } = context;
                        return (

                            <UserContext.Consumer>
                                {(userContext) => {
                                    const { user } = userContext;
                                    return (
                                        <React.Fragment>

                                            <Helmet>
                                                <title>{`Shopping Cart — ${program.fullName}`}</title>
                                            </Helmet>
                                            <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />
                                            {cart.quantity && this.renderCart(user, history)}
                                            {!cart.quantity && (
                                                <div className="block block-empty">
                                                    <div className="container">
                                                        <div className="block-empty__body">
                                                            <div className="block-empty__message"><FormattedMessage id="cart_emptycart" defaultMessage="Your shopping cart is empty!" /></div>
                                                            <div className="block-empty__actions">
                                                                <Link to="/" className="btn btn-primary btn-sm"><FormattedMessage id="cart_continueshopping" defaultMessage="Continue Shopping" /></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {content}
                                        </React.Fragment>
                                    );
                                }}
                            </UserContext.Consumer>
                        );
                    }}
                </ProgramContext.Consumer>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopPageCart));
