import getApi from './api';
import { GetAPIEndpoint } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`;
    return { Authorization };
}
const orderApi = {
    getOrder: (id) => {
        const headers = getHeaders();
        return getApi().get(`${GetAPIEndpoint()}/api/orders/${id}`, { headers });
    },
    getOrders: () => {
        const headers = getHeaders();
        return getApi().get(`${GetAPIEndpoint()}/api/orders`, { headers });
    },
    getTransactions: () => {
        const headers = getHeaders();
        return getApi().get(`${GetAPIEndpoint()}/api/transactions`, { headers });
    },
    placeOrder: (order) => fetch(`${GetAPIEndpoint()}/api/orders`, {
        method: 'POST',
        headers: new Headers(
            {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`,
            },
        ),
        body: JSON.stringify(order),
    }),
};

export default orderApi;
