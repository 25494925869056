import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Dropdown from './Dropdown';
import UserContext from '../../contexts/UserContext';
import ProgramContext from '../../contexts/ProgramContext';
import DropdownLanguage from './DropdownLanguage';
import { regionChange } from '../../store/region';
import { cartClear } from '../../store/cart';
import locations from '../Countries';

const GeoModal = (props) => {
    const {
        cartClear, className, locale,
    } = props;
    const { user } = useContext(UserContext);
    const { program } = useContext(ProgramContext);
    const [errorMessage, setErrorMessage] = useState(false);

    const getLocation = (locationName) => {
        const filtered = locations.filter((data) => data.code === locationName);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return undefined;
    };

    const getProgramLocations = () => {
        const programLocations = [];
        if (program.continents) {
            for (let i = 0; i < locations.length; i += 1) {
                if (program.continents.indexOf(locations[i].code) !== -1) {
                    programLocations.push(locations[i]);
                }
            }
        }
        return programLocations;
    };

    const getCountry = (locationName, countryName) => {
        const selectedLocation = getLocation(locationName);
        const filtered = selectedLocation.countries.filter((data) => data.code === countryName);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return undefined;
    };

    const { region, regionChange: changeRegion } = props;

    let defaultLocation = region.location;
    if (!defaultLocation || defaultLocation.length === 0) {
        defaultLocation = program.defaultContinent;
    }

    let defaultCountry = region.country;
    if (!defaultCountry || defaultCountry.length === 0) {
        defaultCountry = program.defaultCountry;
    }

    const [location, setLocation] = useState(getLocation(defaultLocation));
    const [country, setCountry] = useState(getCountry(defaultLocation, defaultCountry));
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const saveChanges = () => {
        if (country) {
        setModal(!modal);
        setErrorMessage(false);
        changeRegion({
            location: location.code,
            country: country.code,
        });
        } else {
            setErrorMessage(true);
        }
    };

    const centeredmodal = true;

    const countriesTitle = (
        <React.Fragment>
            Location
            {': '}
            {location === undefined ? <span className="topbar__item-value">{user.country}</span> : <span className="topbar__item-value">{location.title}</span>}
        </React.Fragment>
    );

    const countryTitle = (
        <React.Fragment>
            {<span style={{ color: 'red' }}>*</span>}
            {location && location.title === 'USA' ? 'Region' : 'Country'}
            {': '}
            {country && (<span className="topbar__item-value"><React.Fragment>{country.title}</React.Fragment></span>)}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <button type="button" className="topbar-dropdown__btn" onClick={toggle}>
                <FormattedMessage id="topbar_location" defaultMessage="Location" />
            </button>
            <Modal isOpen={modal} className={className} centered={centeredmodal}>
                <ModalHeader>Localization Preferences</ModalHeader>
                <div>
                    <form>
                        <ModalBody>
                            <div className="container">
                                <p className="col-12 align-items-center text-center">
                                    We have indetified your location and
                                    <br />
                                    <b>SUGGESTED LOCALIZATION PREFERENCES</b>
                                    <br />
                                    are as below:
                                </p>
                            </div>
                            {country && (
                                <div className="container">
                                    <div className="row justify-content-center text-center">
                                        <h6 className="col-4">
                                            Location
                                            {': '}
                                            {country.title}
                                        </h6>
                                        <h6 className="col-4">
                                            Language
                                            {': '}
                                            {locale}
                                        </h6>

                                    </div>
                                </div>
                            )}

                            <hr />
                            <div className="container">
                                <p className="col-12 align-items-center text-center">
                                    You can keep suggested preferences or you
                                    <br />
                                    can change
                                    <b> CHANGE MANUALLY </b>
                                    from below:

                                </p>
                            </div>
                            <div className="container" style={{ marginBottom: '40px' }}>
                                {
                                    locations.length > 1 && (
                                        <div className="col-12 d-flex justify-content-center mb-2">
                                            <Dropdown
                                                title={countriesTitle}
                                                items={getProgramLocations()}
                                                onClick={(item) => {
                                                    setLocation(item);
                                                    setCountry(undefined);
                                                    cartClear();
                                                }}
                                                value={location}
                                            />
                                        </div>
                                    )
                                }
                                {location && location.countries && location.countries.length > 1 && (
                                    <div className="col-12 d-flex justify-content-center mb-2">
                                        <Dropdown
                                            title={countryTitle}
                                            items={location.countries}
                                            onClick={(item) => {
                                                setCountry(item);
                                                cartClear();
                                                setErrorMessage(false);
                                            }}
                                            value={country}
                                        />
                                    </div>
                                )}
                                <div className="col-12 d-flex justify-content-center mb-2">
                                    <DropdownLanguage />
                                </div>

                            </div>
                            <div className="container">
                                <div className="row justify-content-around" style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <button type="button" style={{ marginBottom: '15px' }} className="btn btn-primary col-lg-5 col-12" onClick={toggle}>Keep Suggested</button>
                                    <button type="button" style={{ marginBottom: '15px' }} className="btn btn-primary col-lg-5 col-12" onClick={saveChanges}>Save My Changes</button>

                                </div>
                                {errorMessage && (<div style={{ textAlign: 'center', color: 'red' }}>Region/Country is required!</div>)}
                            </div>
                        </ModalBody>
                    </form>
                </div>
            </Modal>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    region: state.region,
});

const mapDispatchToProps = {
    cartClear,
    regionChange,
    };

export default connect(mapStateToProps, mapDispatchToProps)(GeoModal);
