// react
import React, { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import { Helmet } from 'react-helmet-async';
import ProgramContext from '../../contexts/ProgramContext';
import userApi from '../../server/real/endpoints/users';
import PasswordModal from './AccountResetPasswordModal';
import PasswordModalFail from './AccountPasswordModalFail';

export default function AccountPagePassword() {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [modal, setModal] = useState(false);
    const [modalFail, setModalFail] = useState(false);
    const { program } = useContext(ProgramContext);

    const [currentPassword, setCurrentPassword] = useState('');

    function onCurrentPasswordChange(e) {
        setCurrentPassword(e.target.value);
    }

    function onPasswordChange(e) {
        setPassword(e.target.value);
    }
    function onValidationPasswordChange(e) {
        setPassword2(e.target.value);
    }

    function validationMessages() {
        const errorMessageList = [];
        if (password.length > 0) {
            if (password.search(/[a-z]/) < 0) {
                errorMessageList.push(<span><FormattedMessage id="account_passerrorletter" defaultMessage="Your password must contain at least one letter." /></span>);
            }
            if (password.search(/[A-Z]/) < 0) {
                errorMessageList.push(<span><FormattedMessage id="account_passerrorcapitalletter" defaultMessage="Your password must contain at least one capital letter." /></span>);
            }
            if (password.search(/[.^#!'[!-#%-*,-/:;?@[-\]_{}<>"&/%*+?^${}()|[\]\\]/g, '\\$&') < 0) {
                errorMessageList.push(<span><FormattedMessage id="account_passerrorsymbol" defaultMessage="Your password must contain at least one special character." /></span>);
            }
            if (password.search(/[0-9]/) < 0) {
                errorMessageList.push(<span><FormattedMessage id="account_passerrordigit" defaultMessage="Your password must contain at least one digit." /></span>);
            }
            if (password.length < 8) {
                errorMessageList.push(<span><FormattedMessage id="account_passerrorminchar" defaultMessage="Your password must be at least 8 characters." /></span>);
            }
            if (password.length > 16) {
                errorMessageList.push(<span><FormattedMessage id="account_passerrormaxchar" defaultMessage="Your password must be 16 or lower characters" /></span>);
            }
            if (password === currentPassword) {
                errorMessageList.push(<span><FormattedMessage id="account_passcompareerrornotmatch" defaultMessage="Current and New password can not match" /></span>);
            }
        }

        // setGreen('form-control is-invalid');
        return errorMessageList;
    }

    function password2Valid() {
        if (password === password2 && password.length > 0) {
            return true;
        }
        return false;
    }

    function confirmValidationComponent() {
        const valid = password2Valid();
        if (valid) {
            return (<span />);
        }
        return (<span><FormattedMessage id="account_passerrornotmatch" defaultMessage="Paswords does not match" /></span>);
    }

    function formClassName() {
        const messages = validationMessages();
        if (password.length > 0) {
            if (messages.length > 0) {
                return 'form-control is-invalid';
            }

            return 'form-control is-valid';
        }
        return 'form-control';
    }

    function validationFormClassName() {
        const isPassword2Valid = password2Valid();
        if (password2.length > 0) {
            if (!isPassword2Valid) {
                return 'form-control is-invalid';
            }
            return 'form-control is-valid';
        }
        return 'form-control';
    }

    function passwordValid() {
        if (password === password2 && validationFormClassName() === formClassName() && password.length > 7) {
            return false;
        }
        return true;
    }

    const changePassword = () => {
        userApi.changePassword(currentPassword, password).then((response) => {
            if (response.data.success) {
                setModal(true);
            } else {
                setModalFail(true);
            setCurrentPassword('');
            setPassword('');
            setPassword2('');
}
        });
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Change Password — ${program.fullName}`}</title>
            </Helmet>

            <div className="card-header">
                <h5><FormattedMessage id="account_passchange" defaultMessage="Change Password" /></h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="password-current">
                                <FormattedMessage id="account_passcurrent" defaultMessage="Current Password" />
                            </label>
                            <input
                                type="password"
                                className={formClassName()}
                                id="password-current"
                                placeholder="Current Password"
                                onChange={onCurrentPasswordChange}
                                value={currentPassword}
                                name="currentPassword"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password-new">
                                <FormattedMessage id="account_passnew" defaultMessage="New Password" />
                            </label>

                            <input
                                type="password"
                                className={formClassName()}
                                id="password-new"
                                placeholder="New Password"
                                onChange={onPasswordChange}
                                value={password}
                            />
                            {validationMessages().map((message) => (<div className="invalid-feedback">{message}</div>))}

                            <div className="valid-feedback"><FormattedMessage id="account_passerrorgood" defaultMessage="Password is good." /></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-confirm">
                                <FormattedMessage id="account_passreenter" defaultMessage="Reenter New Password" />
                            </label>
                            <input
                                type="password"
                                className={validationFormClassName()}
                                id="password-confirm"
                                placeholder="Reenter New Password"
                                onChange={onValidationPasswordChange}
                                value={password2}
                            />

                            <div className="invalid-feedback">{confirmValidationComponent()}</div>

                            <div className="valid-feedback"><FormattedMessage id="account_passmatchsuccess" defaultMessage="Passwords are match!" /></div>
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="button" disabled={passwordValid()} className="btn btn-primary" onClick={changePassword}>
                                <FormattedMessage id="account_passchangebutton" defaultMessage="Change" />
                            </button>
                            <PasswordModal modal={modal} />
                            <PasswordModalFail modalClose={(data) => { setModalFail(data); }} modalFail={modalFail} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
