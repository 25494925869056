import { toast } from 'react-toastify';
import {
    CART_ADD_ITEM, CART_REMOVE_ALL, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES,
} from './cartActionTypes';
import { quickviewClose } from '../quickview';

export function cartAddItemSuccess(product, option = {}, quantity = 1) {
    toast.success(`Product "${product.productName}" added to cart!`);

    return {
        type: CART_ADD_ITEM,
        product,
        option,
        quantity,
    };
}
export function cartRemoveAllSuccess() {
    return {
        type: CART_REMOVE_ALL,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, option, quantity = 1, program = {}) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            const { type } = program;
            if (type === 'plateau' || type === 'claim') {
                dispatch(quickviewClose());
                dispatch(cartRemoveAllSuccess());
                dispatch(cartAddItemSuccess(product, option, quantity));
                resolve();
                window.location.href = '/shop/checkout';
            } else {
                dispatch(quickviewClose());
                dispatch(cartAddItemSuccess(product, option, quantity));
                resolve();
            }
        })
    );
}
export function cartClear() {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveAllSuccess());
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        })
    );
}
