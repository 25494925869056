import getApi from './api';
import { GetAPIEndpoint } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`;
    return { Authorization };
}
const productApi = {
    getProductBySlug: (slug) => getApi().get(`${GetAPIEndpoint()}/api/products/${slug}`, { headers: getHeaders() }),

    getProductsList: (options = {}, filterValues = {}, filters = [], includeFilters = true) => getApi().get(`${GetAPIEndpoint()}/api/products?includeFilters=${includeFilters}&o=${encodeURIComponent(JSON.stringify({
        options, filterValues, filters,
    }))}`, { headers: getHeaders() }),

    getSuggestions: (query, options) => getApi().get(`${GetAPIEndpoint()}/api/products/suggestions?o=${JSON.stringify({ options })}&q=${query}`, { headers: getHeaders() }),
};
export default productApi;
