/* eslint-disable no-unused-vars */
import getApi from './api';
import { GetAPIEndpoint } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token') ?? sessionStorage.getItem('access_token')}`;
    return { Authorization };
}
const categoryApi = {
    getLangCategories: () => getApi().get(`${GetAPIEndpoint()}/api/categories`, { headers: getHeaders() }).then((data) => {
        return data.data
    }),
    getCategories: (options = {}) => getApi().get(`${GetAPIEndpoint()}/api/categories`, { headers: getHeaders() }).then((data) => {
        const [categoriesTreeData, categoriesListData] = categoryApi.walkTree(data.data);
        return Promise.resolve(
            categoriesTreeData.map((x) => categoryApi.prepareCategory(x, options.depth)),
        );
    }),
    getCategoryBySlug: (slug, options = {}) => getApi().get(`${GetAPIEndpoint()}/api/categories`, { headers: getHeaders() }).then((data) => {
        const [categoriesTreeData, categoriesListData] = categoryApi.walkTree(data.data);
        const category = categoriesListData.find((x) => x.slug === slug);
        return category ? Promise.resolve(categoryApi.prepareCategory(category, options.depth)) : Promise.reject();
    }),

    prepareCategory(category, depth) {
        let children;

        if (depth && depth > 0) {
            children = category.children.map((x) => categoryApi.prepareCategory(x, depth - 1));
        }

        return JSON.parse(JSON.stringify({
            ...category,
            parent: category.parent ? categoryApi.prepareCategory(category.parent) : null,
            children,
        }));
    },
    walkTree(defs, parent = null) {
        let list = [];
        const tree = defs.map((def) => {
            const category = {
                id: def.id,
                name: def.name,
                slug: def.slug,
                image: def.image || null,
                items: def.items || 0,
                customFields: {},
                parent,
                children: [],
            };

            const [childrenTree, childrenList] = categoryApi.walkTree(def.children || [], category);

            category.children = childrenTree;
            list = [...list, category, ...childrenList];

            return category;
        });

        return [tree, list];
    },
};

export default categoryApi;
