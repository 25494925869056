import React from 'react';
import {
    Modal, ModalHeader, ModalBody, Button, ModalFooter,
} from 'reactstrap';

const PasswordModalFail = (props) => {
    const {
        className, modalFail, modalClose,
    } = props;

    const centeredmodal = true;

    return (
        <React.Fragment>
            <Modal isOpen={modalFail} className={className} centered={centeredmodal}>
                <ModalHeader>Fail</ModalHeader>
                <ModalBody>
                    Password change failed. Please try again.
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => modalClose(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};
export default PasswordModalFail;
