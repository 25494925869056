// react
import React, { useContext } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// application
import AsyncAction from '../shared/AsyncAction';
import Points from '../shared/Points';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem } from '../../store/cart';
import { url } from '../../services/utils';
import { getProductPrice, selectedLanguage } from '../../server/utils';

import UserContext from '../../contexts/UserContext';
import ProgramContext from '../../contexts/ProgramContext';

function IndicatorCart(props) {
    const { cart, cartRemoveItem } = props;
    const { region, locale } = props;
    const history = useHistory();
    let dropdown;
    let totals;
    const { user } = useContext(UserContext);
    const { program } = useContext(ProgramContext);
    let grandTotal = 0;
    const isDisabled = () => {
        if (grandTotal > user.balance) {
            return true;
        }
        return false;
    };

    const handleCheckout = () => {
        history.push('/shop/checkout');
    };

    function equalizeLang (langData) {
        if (langData === 'sp') {
            return 'es'
        }
        if (langData === 'ge') {
            return 'de'
        }
        return langData
    }

    const items = cart.items.map((item) => {
        const { product, quantity } = item;
        const { option } = item;
        const unitPrice = getProductPrice(product, option, region);
        const totalPrice = unitPrice * quantity;
        grandTotal += totalPrice;

        let image;
        if (item.product.images) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item.product)} className="product-image__body">
                        <img className="product-image__img" src={item.product.images.imageLowMedium} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item.product)}>{`${selectedLanguage(item.product, equalizeLang(locale), item.product.productName, 'name')}`}</Link>
                    </div>
                    <ul className="dropcart__product-options">
                        {item.option && item.option.optionColorValue && (<li>{`Color: ${item.option.optionColorValue}`}</li>)}
                        {item.option && item.option.optionSizeValue && (<li>{`Size: ${item.option.optionSizeValue}`}</li>)}
                        {item.option && item.option.optionCustom1Value && (<li>{`${item.product.optionsCustom1Label}: ${item.option.optionCustom1Value}`}</li>)}
                        {item.option && item.option.optionCustom2Value && (<li>{`${item.product.optionsCustom2Label}: ${item.option.optionCustom2Value}`}</li>)}
                        {item.option && item.option.optionCustom3Value && (<li>{`${item.product.optionsCustom3Label}: ${item.option.optionCustom3Value}`}</li>)}
                    </ul>
                    {program && program.type === 'points' && (
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Points value={unitPrice} /></span>
                    </div>
)}
                </div>
                {program.type === 'points' && (removeButton)}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {items}
                </div>

                {program && program.type === 'points' && (
                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th>
                                    <FormattedMessage id="cart_total" defaultMessage="Total" />
                                </th>
                                <td aria-label="points"><Points value={grandTotal} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
)}

                <div className="dropcart__buttons">
                    {program && program.type === 'points' && (<Link className="btn btn-secondary" to="/shop/cart"><FormattedMessage id="cart_viewcart" defaultMessage="View Cart" /></Link>)}
                    <button className="btn btn-primary" type="button" to="/shop/checkout" disabled={program && program.type === 'points' ? isDisabled() : false} onClick={handleCheckout}>
                        <FormattedMessage id="cart_checkout" defaultMessage="Checkout" />
                    </button>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    <FormattedMessage id="cart_emptycart" defaultMessage="Your shopping cart is empty!" />
                </div>
            </div>
        );
    }

    return (
        <Indicator url="/shop/cart" dropdown={dropdown} value={cart.quantity} program={program.type} icon={<Cart20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
