/* eslint-disable jsx-a11y/label-has-associated-control */
// react
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useContext,
} from 'react';

// third-party
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Check9x7Svg } from '../../svg';

// application
import Points from '../shared/Points';
import UserContext from '../../contexts/UserContext';

function getFirstValidValue(...values) {
    return values.reduce((acc, value) => (
        acc === null && (value || value === 0)
            ? value
            : acc
    ), null);
}

function FilterRange(props) {
    const {
        data,
        value,
        onChangeValue,
        checkbox,
        getCheckBox,
    } = props;
    const direction = 'ltr';
    const [propsFrom, propsTo] = value || [];
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState([propsFrom, propsTo]);
    const [stateFrom, stateTo] = state;
    const { user } = useContext(UserContext);
    let { min, max } = data;
    let from = Math.max(getFirstValidValue(stateFrom, propsFrom, min), min);
    let to = Math.min(getFirstValidValue(stateTo, propsTo, max), max);
    let fromLabel = from;
    let toLabel = to;
    // since react-input-range does not support RTL direction,
    // we just need to invert and swipe values
    if (direction === 'rtl') {
        [from, to] = [to * -1, from * -1];
        [min, max] = [max * -1, min * -1];
        [fromLabel, toLabel] = [from * -1, to * -1];
    }
    // Update state from props.

    useEffect(() => {
        setState([propsFrom, propsTo]);
    }, [propsFrom, propsTo]);

    // Clear previous timer.
    useEffect(() => () => {
        clearTimeout(timer);
    }, [timer]);

    const limitMax = () => {
        getCheckBox(!checkbox);
    };

    const handleChange = useCallback((newValue) => {
        let { min: newFrom, max: newTo } = newValue;

        // This is needed to fix a bug in react-input-range.
        [newFrom, newTo] = [Math.max(newFrom, min), Math.min(newTo, max)];

        // since react-input-range does not support RTL direction,
        // we just need to invert and swipe values
        if (direction === 'rtl') {
            [newFrom, newTo] = [newTo * -1, newFrom * -1];
        }

        setState([newFrom, newTo]);

        if (onChangeValue) {
            setTimer(setTimeout(() => {
                onChangeValue({ filter: data, value: [newFrom, newTo] });
            }, 250));
        }
    }, [min, max, data, onChangeValue, direction, setTimer, setState, checkbox]);

    useEffect(() => {
        if (checkbox) {
            handleChange({ min: from, max: user.balance });
        }
    }, [checkbox]);

    return useMemo(() => (
        <div className="filter-price">
            <div className="filter-price__slider" dir="ltr">
                <InputRange
                    minValue={min}
                    maxValue={checkbox ? user.balance : max}
                    value={{ min: from, max: checkbox ? user.balance : to }}
                    step={1}
                    onChange={handleChange}
                />
            </div>
            <div className="row justify-content-between" style={{ marginTop: '10px' }}>
                <input
                    className="col-5"
                    type="text"
                    style={{
 marginLeft: '15px', paddingLeft: '4px', paddingRight: '4px', maxWidth: '35.666667%',
}}
                    value={from}
                    onChange={(e) => { handleChange({ min: e.target.value, max: to }); }}
                />
                <input
                    className="col-5"
                    type="text"
                    style={{
 marginRight: '15px', paddingLeft: '4px', paddingRight: '4px', maxWidth: '35.666667%',
}}
                    value={checkbox ? user.balance : to}
                    onChange={(e) => { handleChange({ min: from, max: checkbox ? user.balance : e.target.value }); }}
                />
            </div>
            <label key="{item.slug}" className="filter-list__item" style={{ marginTop: '15px' }}>
                Show for my points
                &nbsp;
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input
                            className="input-check__input"
                            type="checkbox"
                            onChange={limitMax}
                            checked={checkbox}
                            // onClick={() => { handleChange({ min: from, max: checkbox ? user.balance : to }); }}
                        />
                        <span className="input-check__box" style={{ marginTop: '4px' }} />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
            </label>
            <div className="filter-price__title">
                Value:
                {' '}
                <span className="filter-price__min-value"><Points value={fromLabel} /></span>
                {' – '}
                <span className="filter-price__max-value"><Points value={checkbox ? user.balance : to} /></span>
            </div>
        </div>
    ), [min, max, from, to, fromLabel, toLabel, handleChange]);
}

FilterRange.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
    /**
     * Value.
     */
    value: PropTypes.arrayOf(PropTypes.number),
    /**
     * Change value callback.
     */
    onChangeValue: PropTypes.func,
    /**
     * Current locale.
     */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FilterRange);
