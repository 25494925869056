import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import programApi from '../../server/real/endpoints/programs';
import { useSearchParams } from 'react-router-dom';

export default withOktaAuth(class Home extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.state = {
      user: undefined,
    }
  }

  
  async logout() {
    await this.props.oktaAuth.signOut();
  }

  async login() {
      await this.props.oktaAuth.signInWithRedirect();
  }
  componentDidMount(e) {
    const params = new URLSearchParams(window.location.search)
    const emailAddresses = ["miles.graham@spglobal.com","carolina.onetto@spglobal.com","hugh.pleasants@spglobal.com","edward.nico.alves@spglobal.com","rehan.tayyar@spglobal.com","sobiya.rahat@spglobal.com", "zekeriya@webservicespros.com", "test.leaderspromo.user@spglobal.com", "gabriel.gumabon@spglobal.com"]
     if (params.get('emailID')?.toLowerCase() && !params.get('redirect')) {
     programApi.getUserWithoutApi(params.get('emailID')).then((userData) => {
        this.setState({
         user: userData.data
       });
    });      
    } else if(params.get('redirect')) {
      this.login()
    }
}


  render() {
    const value = (100000).toLocaleString(
      undefined, // leave undefined to use the visitor's browser 
                 // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 2 }
    );
    const params = new URLSearchParams(window.location.search)
    const emailAddresses = ["miles.graham@spglobal.com","carolina.onetto@spglobal.com","hugh.pleasants@spglobal.com","edward.nico.alves@spglobal.com","rehan.tayyar@spglobal.com","sobiya.rahat@spglobal.com", "zekeriya@webservicespros.com", "test.leaderspromo.user@spglobal.com", "gabriel.gumabon@spglobal.com"]
    let d = new Date(this.state.user?.lastUpdateDate);
    var datestring = (d.getMonth()+1)  + "/" + d.getDate() + "/" + d.getFullYear()
    if (!emailAddresses.includes(params.get('emailID')?.toLowerCase())) {
      return (
        <div className="container" style={{ width: '280px', height: '280px',textAlign: '-webkit-center', padding: "0px"}}>
          <img src="/images/avatars/280x280_alt.png" width={275} />
        {/* <div style={{ height: '18px', color: 'black', fontWeight: '900' }} />
        <img style={{ width: '100%' }} src="/images/avatars/cup.jpg" />
        <div style={{ fontFamily: 'Open Sans', fontSize: '15px', color: 'black', marginTop: '5px', fontWeight: '500' }}>Exciting News!</div>
        <div style={{ fontFamily: 'Open Sans', fontSize: '22px', fontWeight: '900', lineHeight: '34px', border: '2px solid #dddddd', padding: '5px', borderRadius: '13px', width: "192px" }}><span style={{ color: 'black', fontFamily: 'Open Sans' }}>Reward Points <br />Coming Soon</span></div>*/}
      </div> 
      )
    }
    if (params.get('redirect')) {
      return (
<div className="center-page-1">
    <br />
    <br />
    <br />
        <h1 style={{ color: '#4a8df8' }}>Just a moment...</h1>
        <div>
            <div className="slider-redirect">
                <div className="line-redirect" />
                <div className="break-redirect dot1-redirect" />
                <div className="break-redirect dot2-redirect" />
                <div className="break-redirect dot3-redirect" />
            </div>
        </div>
        <p style={{ color: '#4a8df8' }}>
            We&apos;re redirecting you to the site... &nbsp;
        </p>
</div>
      )
    }
    if (!this.state.user) return null

    if (this.state.user && emailAddresses.includes(params.get('emailID')?.toLowerCase())) {
      return (
        <div className="container" style={{ width: '280px', height: '280px', marginTop: '10px', textAlign: 'center'}}>
          {this.state?.user?.balance !== 0 && <div style={{ fontFamily: 'Open Sans', fontSize: '18px', color: 'black', fontWeight: '900' }}>CONGRATULATIONS!</div>}
          <img style={{ width: '100%' }} src="/images/avatars/cup.jpg" />
          <div style={{ fontFamily: 'Open Sans', fontSize: '15px', color: 'black', marginTop: '5px', fontWeight: '500' }}>As of {datestring} You have</div>
          <div style={{ fontFamily: 'Open Sans', fontSize: '37px', fontWeight: '900', lineHeight: '34px' }}><span style={{ color: '#DB2D3B', fontFamily: 'Open Sans' }}>{this.state?.user?.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> <span style={{ color: 'black', fontFamily: 'Open Sans' }}>Points</span></div>
          <div style={{ textAlign: '-webkit-center'}}><a style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center', borderRadius: '8px', width: '230px', color: 'white', backgroundColor: '#507BBF', border: '0px', height: '36px', fontSize: '13px' }} type="button" 
          href={this.state?.user?.balance !== 0 ? `${window.location.origin}/mindtickle/score?emailID=${params.get('emailID')?.toLowerCase()}&redirect=true` : "https://spglobal.mindtickle.com/login"} target="_blank" className="btn btn-primary btn-l btn-block"><span style={{ fontFamily: 'Open Sans'}}><b>{this.state?.user?.balance !== 0 ? 'Redeem' : 'Start Learning Now'}</b></span></a></div>
        </div>

    );
    
    }
  }
});
