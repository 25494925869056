// react
import React from 'react';
import { connect } from 'react-redux';
import { selectedLanguage } from '../../server/utils';

function ProductTabDescription(props) {
    const { product, locale } = props;
    function equalizeLang (langData) {
        if (langData === 'sp') {
            return 'es'
        }
        if (langData === 'ge') {
            return 'de'
        }
        return langData
    }
    return (
        <div className="typography">
            <h3>{product !== null && (selectedLanguage(product, equalizeLang(locale), product && product.productName, 'name'))}</h3>
            <p>
                {product !== null && (selectedLanguage(product, equalizeLang(locale), product && product.productSalesCopy, 'salesCopy'))}
            </p>
        </div>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});
export default connect(mapStateToProps)(ProductTabDescription);
